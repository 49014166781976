import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ArrowRightIcon, CheckCircleIcon, ChevronRightIcon, MinusCircleIcon, StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import ModalOpcoes from './opcoes-modal';
import { useDispatch } from 'react-redux';
import { setUsuario } from '../../../features/usuario/usuarioAtual';
import { toast } from 'react-toastify';

const Trilha = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [trilha, setTrilha] = useState(null)
    const [proximosPassos, setProximosPassos] = useState([])
    const onboarding = usuarioAtual?.onboarding || {};
    const dispatch = useDispatch();

    const [videoBoasVindas, setVideoBoasVindas] = useState("");

    const [showOpcoesModal, setShowOpcoesModal] = useState(false);
    const token = localStorage.getItem("token");

    useEffect(() => {

        if (!videoBoasVindas) {
            const getVideoBoasVindas = async () => {
                try {
                    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/config`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setVideoBoasVindas(data?.boas_vindas?.url || "");
                } catch (err) {
                    console.error("Erro ao obter o vídeo de boas-vindas:", err);
                }
            };
            getVideoBoasVindas();
        }
    }, [videoBoasVindas]);


    const openOpcoesModal = () => {
        setShowOpcoesModal(true);
    }

    const closeOpcoesModal = () => {
        setShowOpcoesModal(false);
    }

    const mapearAcoes = (divisoes) => {
        // Pega uma lista de divisões com ações e retorna todas as ações em uma lista linear única
        return divisoes?.reduce((acc, divisao) => {
            // Pega as ações da divisão atual e adiciona ao acumulador
            return acc.concat(divisao.acoes);
        }, []);
    };

    const findProximosPassos = (tr) => {
        if (usuarioAtual.tipo === 'guest' || !tr) return;

        const acoesTrilha = mapearAcoes(tr?.divisoes);
        if (!acoesTrilha) return;

        // Cria um Set de chaves das ações concluídas para pesquisa rápida
        let acoesTrilhaRestantes = [...acoesTrilha] || []
        let acoesConcluidasRestantes = []
        if (usuarioAtual?.acoesConcluidas) {
            acoesConcluidasRestantes = [...usuarioAtual?.acoesConcluidas];
        }


        // Cria um Set para armazenar as chaves das ações concluídas
        let idsConcluidos = acoesConcluidasRestantes.map(acao => `${acao.tipo}-${acao.dado || ''}`);

        // Cria uma nova trilha com status atualizado sem modificar diretamente o estado
        const novaTrilha = {
            ...tr,
            divisoes: tr.divisoes.map((divisao) => ({
                ...divisao,
                acoes: divisao.acoes.map(acao => {
                    const chave = `${acao.tipo}-${acao.dado?._id || ''}`; // Corrigido para lidar com _id ou null
                    const indexAcao = idsConcluidos.indexOf(chave);

                    // Se a ação da trilha foi concluída, remove a ação concluída da lista para não comparar novamente
                    if (indexAcao > -1) {
                        idsConcluidos.splice(indexAcao, 1) // Remove a chave do Set de IDs concluídos
                        acoesTrilhaRestantes = acoesTrilhaRestantes.filter(el => el._id !== acao._id)

                        return {
                            ...acao,
                            concluida: true,
                        };
                    }

                    return {
                        ...acao,
                        concluida: false,
                    };
                }),
            })),
        };

        // Atualiza a trilha com o novo estado
        setTrilha(novaTrilha);

        // Retorna as 5 primeiras ações restantes da trilha
        setProximosPassos(acoesTrilhaRestantes.slice(0, 5));
    };



    useEffect(() => {
        if (usuarioAtual?.trilha) {
            //setTrilha(usuarioAtual.trilha)
            findProximosPassos(usuarioAtual.trilha)
        }
    }, [usuarioAtual])


    const renderAcao = (acao, index) => {
        const hasCodigo = acao.dado && acao.dado.codigo;
        const links = {
            'consultoria': `/consultorias/${acao.dado?._id}`,
            'pagina-master': hasCodigo ? `/master/${acao.dado.codigo}` : '#',
            'curso-master': hasCodigo ? `/master/curso/${acao.dado.codigo}` : '#',
            'aula-master': hasCodigo ? `/master/aula/${acao.dado.codigo}` : '#',
            'aula-trilha': `/trilha/${acao.dado?._id}`,
            'short': `/shorts/${acao.dado?._id}`,
            'comunidade': process.env.REACT_APP_COMUNIDADE_URL,
            'produto-catalogado': acao.dado?.link || '#',
            'marketplace': acao.dado?.nome ? acao.dado.link : `/marketplace/servico/marketplace/${acao.dado?.codigo || ''}`,
        };

        const link_target = (acao.tipo === 'comunidade' || (acao.tipo === 'produto-catalogado') || (acao.tipo === 'marketplace' && acao.dado?.nome)) ? '_blank' : '_self';

        return (
            <li key={`${acao._id}-${index}`} className="text-neutral-300 text-xs hover:bg-neutral-900">
                <a
                    href={links[acao.tipo]}
                    target={link_target}
                    className="w-full p-3 flex flex-row items-center align-middle gap-2"
                    onClick={() => {
                        if (acao.tipo === 'produto-catalogado' || acao.tipo === 'marketplace' || acao.tipo === 'comunidade') {
                            handleAcao(acao.tipo, acao.dado?._id);
                        }
                    }}
                >
                    {acao.concluida ?
                        <SolidStarIcon className="w-5 h-5 flex-shrink-0 text-yellow-500" />
                        :
                        <OutlineStarIcon className="w-5 h-5 flex-shrink-0" />
                    }
                    <span className="flex-shrink-0">{acao.titulo}</span>
                    <span className="text-yellow-500 truncate min-w-0 max-w-fit w-full">
                        {acao.dado?.titulo || acao.dado?.nome}
                    </span>
                    {acao.tipo in links && (
                        <span className="text-xs hover:text-orange-500 flex-shrink-0" aria-label={acao.titulo}>
                            <ArrowRightIcon className="w-4 h-4" />
                        </span>
                    )}
                </a>
            </li>
        );

    }

    const handleAcao = async (tipo, dadoId) => {
        const acao = {
            tipo: tipo,
            dado: dadoId,
        }
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha/validar-acao/`, { acao }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (status === 200) {
                console.log("Ação enviada com sucesso:", data);
                dispatch(setUsuario(data.usuario))
                if (data.nova) toast.success("Ação da trilha concluída")
            } else {
                console.error("Erro ao enviar ação:", data);
            }
        } catch (error) {
            console.error("Erro ao enviar ação:", error);
        }
    };


    const handleVideoEnded = async () => {
        const boasVindasAcao = {
            tipo: 'boas-vindas',
            dado: null,
        }
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha/validar-acao/`, { acao: boasVindasAcao }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (status === 200) {
                console.log("Ação de boas-vindas enviada com sucesso:", data);
                dispatch(setUsuario(data.usuario))
                if (data.nova) toast.success("Ação da trilha concluída")
            } else {
                console.error("Erro ao enviar ação de boas-vindas:", data);
            }
        } catch (error) {
            console.error("Erro ao enviar ação de boas-vindas:", error);
        }
    };


    return (
        <>
            {showOpcoesModal && <ModalOpcoes closeModal={closeOpcoesModal} />}
            <div className="w-11/12 mx-auto space-y-5 text-white mb-10">
                <div className='bg-neutral-800 rounded-lg overflow-hidden grid grid-cols-1 lg:grid-cols-2 divide-y lg:divide-y-0 lg:divide-x divide-neutral-900 '>
                    <div className='flex flex-col items-center justify-center align-middle overflow-hidden'>
                        <video
                            src={videoBoasVindas ? `${videoBoasVindas}` : ""}
                            className='w-full h-fit my-auto aspect-video'
                            controls
                            autoPlay
                            muted
                            controlsList="nodownload"
                            onContextMenu={() => false}
                            loading="lazy"
                            onEnded={handleVideoEnded} // Aciona a função quando o vídeo termina
                        />

                    </div>
                    <div className='p-5 md:px-10 bg-cover bg-center bg-no-repeat' style={{ backgroundImage: "url('/trilha-background-light.svg')" }}>
                        {usuarioAtual.tipo === 'guest' ?
                            <div className='w-11/12 md:w-3/4 mx-auto h-full flex flex-col gap-5 items-center justify-center align-middle text-white text-center'>
                                <h1 className='font-extrabold text-xl md:text-2xl font-poppins'>Defina sua meta</h1>
                                <p className='text-sm italic'>Descubra qual trilha melhor se encaixa no meu momento</p>
                                <button className='flex flex-col items-center cursor-pointer print:hidden'>
                                    <div className="w-fit h-fit py-2 px-3 rounded-full bg-orange-500 animate-ping">Começar</div>
                                    <div
                                        className="w-fit h-fit py-2 px-3 rounded-full bg-orange-500 text-center flex items-center justify-center -mt-10">
                                        Começar
                                    </div>
                                </button>
                            </div>
                            :
                            <>
                                {trilha ?
                                    <div className='space-y-5'>
                                        <p className='text-xs font-sans font-bold text-center md:text-left text-neutral-300'>Próximos Passos</p>
                                        <ul className='divide-y divide-neutral-700'>
                                            {proximosPassos.length ? proximosPassos.map(renderAcao) :
                                                <div className='w-fit mx-auto text-center space-y-5 my-5'>
                                                    <SolidStarIcon className='w-10 h-10 md:w-20 md:h-20 mx-auto text-yellow-500' />
                                                    <li className='text-sm md:text-md my-5 font-extrabold'>Parabéns! Você concluiu todos os passos da sua trilha!</li>
                                                </div>
                                            }
                                        </ul>
                                        <div className='flex justify-between'>
                                            <button className='text-neutral-400 hover:text-neutral-50 text-xs' onClick={openOpcoesModal}>Trocar trilha</button>
                                            <a href='/trilha' className='text-yellow-500 hover:text-yellow-600 text-xs flex gap-1 items-center'>Trilha Completa
                                                <span><ChevronRightIcon className='w-3 h-3' /></span>
                                            </a>
                                        </div>
                                    </div>
                                    :
                                    <div className='w-full mx-auto h-full flex flex-col gap-5 items-center justify-center align-middle text-white text-center'>
                                        <h1 className='font-extrabold text-lg md:text-xl lg:text-2xl font-poppins'>Estamos construindo sua trilha</h1>
                                        <p className='text-xs md:text-md italic'>No momento, não há trilha atribuída às opções que você selecionou no cadastro, mas já estamos trabalhando nisso e logo você terá acesso à sua trilha personalizada.</p>
                                        <div className='flex justify-between'>
                                            <button className='text-neutral-400 hover:text-neutral-50 text-xs' onClick={openOpcoesModal}>Trocar trilha</button>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

            {trilha?.divisoes?.length &&
                <div className="w-11/12 mx-auto space-y-5 text-white mb-10">
                    <div className='flex items-center justify-between'>
                        <p className='text-lg'>Conteúdos da sua Trilha</p>
                        <a href='/trilha' className='flex items-center justify-center align-middle text-sm text-yellow-500 animate-pulse hover:text-orange-500' rel="prefetch">
                            <span className=''>Ver mais</span>
                            <ChevronRightIcon className='w-4 h-4' />
                        </a>

                    </div>
                    <div className='bg-neutral-800 rounded-lg overflow-hidden md:divide-y divide-neutral-900'>
                        <div className='p-5 space-y-2'>
                            <p className='text-sm text-neutral-200 font-sans font-bold'>{trilha.titulo}</p>
                            <p className='text-xs text-neutral-400'>{trilha.descricao || ''}</p>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 md:divide-x divide-neutral-900'>
                            <div className='p-5'>
                                <p className='text-sm text-neutral-200'>{trilha.divisoes[0].titulo || "Divisão 01"}</p>
                                <ul className='divide-y divide-neutral-700'>
                                    {trilha.divisoes[0].acoes.map(renderAcao)}
                                </ul>
                            </div>
                            {trilha.divisoes.length > 1 &&
                                <div className='hidden md:block p-5'>
                                    <p className='text-sm text-neutral-200'>{trilha.divisoes[1].titulo || "Divisão 02"}</p>
                                    <ul className='divide-y divide-neutral-700'>
                                        {trilha.divisoes[1].acoes.map(renderAcao)}
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className='text-neutral-200 hover:text-yellow-500 hover:bg-neutral-950'>
                            <a href='/trilha' className=''>
                                <div className='w-full p-2 text-center text-xs'>
                                    Ver trilha completa
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Trilha;
