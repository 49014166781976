import React, { useState } from 'react'
import RichTextEditor from '../richtext'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import './add_curso.css'
const PopupAddCurso = (props) => {
  const navigate = useNavigate()
  const [nome, setNome] = useState(props.itemPopup && props.itemPopup.nome ? props.itemPopup.nome : '')
  const [descricao, setDescricao] = useState(props.itemPopup && props.itemPopup.descricao ? props.itemPopup.descricao : '')
  const [capa, setCapa] = useState(props.itemPopup && props.itemPopup.capa ? props.itemPopup.capa : '')
  const [fundo, setFundo] = useState(props.itemPopup && props.itemPopup.fundo ? props.itemPopup.fundo : '')
  const [urlCompartilhamento, setUrlCompartilhamento] = useState(props.itemPopup && props.itemPopup.lp ? props.itemPopup.lp : '')
  const [trilha, setTrilha] = useState(props.itemPopup && props.itemPopup.hasOwnProperty("trilha") ? props.itemPopup.trilha : false)
  const [ativo, setAtivo] = useState(props.itemPopup && props.itemPopup.hasOwnProperty("ativo") ? props.itemPopup.ativo : false)
  const [posicao, setPosicao] = useState(props.itemPopup && props.itemPopup.hasOwnProperty("posicao") ? props.itemPopup.posicao : 0);

  const uploadFile = async (event, type) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const config = {
        headers: {
          'Content-Type': selectedFile.type,
          'AccessKey': process.env.REACT_APP_BUNNY_TOKEN
        },
        transformRequest: [(data) => {
          return data;
        }]
      };

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BUNNY_STORAGE_ENDPOINT}${selectedFile.name}`,
          selectedFile,
          config
        );

        if (type === 'capa') {
          setCapa(`${process.env.REACT_APP_BUNNY_FILEHOST}${selectedFile.name}`)
        } else if (type === 'fundo') {
          setFundo(`${process.env.REACT_APP_BUNNY_FILEHOST}${selectedFile.name}`)
        }

      } catch (err) {
      }
    }
  };

  const salvarCurso = async () => {
    let body = {
      nome,
      capa,
      fundo,
      lp: urlCompartilhamento,
      trilha,
      descricao,
      ativo,
      posicao
    }

    if (props.itemPopup && props.itemPopup._id) body._id = props.itemPopup._id

    try {
      const salvarCurso = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/cursos`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (salvarCurso.data && salvarCurso.data.curso && salvarCurso.data.curso.slug) {
        navigate(`/admin/cursos/${salvarCurso.data.curso._id}`)
        props.buscarCursos()
        props.sucesso()
      }

    } catch (err) {
    }


    props.fecharPopup()
  };

  return (
    <div className="popup-curso-container">
      <div className="popup-curso-container1">
        <div className="popup-curso-head">
          <span className="popup-curso-text">{props.itemPopup ? 'Editar' : 'Adicionar'} Curso</span>
          <div className="popup-curso-fechar-popup" onClick={props.fecharPopup}>
            <svg
              viewBox="0 0 804.5714285714286 1024"
              className="popup-curso-icon"
            >
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          </div>
        </div>
        <div className="popup-curso-form">
          <div className="popup-curso-container2">
            <span className="popup-curso-label">Nome</span>
            <input value={nome} type="text" className="popup-curso-textinput" onChange={(e) => setNome(e.target.value)} />
          </div>
          <div style={{ display: 'flex', gap: '25px' }}>
            <div className="popup-curso-container3" onClick={() => document.getElementById('file-input').click()}>
              <span className="popup-curso-label1">Capa</span>
              <div className="popup-curso-container4">
                <img
                  src={capa ? capa : '/default-img.svg'}
                  alt="image"
                  className="popup-curso-image"
                />
                <div className="popup-curso-fechar-popup1" id="upload-file">
                  <label>
                    <svg
                      viewBox="0 0 804.5714285714286 1024"
                      className="popup-curso-icon2"
                    >
                      <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                    </svg>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/jpeg, image/png, image/gif"
                    onChange={(e) => { uploadFile(e, 'capa') }}
                  />
                </div>
              </div>
            </div>
            <div className="popup-curso-container3" onClick={() => document.getElementById('file-input-fundo').click()}>
              <span className="popup-curso-label1">Fundo</span>
              <div className="popup-curso-container4">
                <img
                  src={fundo ? fundo : '/default-img.svg'}
                  alt="image"
                  className="popup-curso-image"
                />
                <div className="popup-curso-fechar-popup1" id="upload-file2">
                  <label>
                    <svg
                      viewBox="0 0 804.5714285714286 1024"
                      className="popup-curso-icon2"
                    >
                      <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                    </svg>
                  </label>
                  <input
                    id="file-input-fundo"
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/jpeg, image/png, image/gif"
                    onChange={(e) => { uploadFile(e, 'fundo') }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="popup-curso-container2">
            <span className="popup-curso-label">Posição</span>
            <input value={posicao} type="number" className="popup-curso-textinput" onChange={(e) => setPosicao(e.target.value)} />
          </div>
          <div className="popup-curso-container2">
            <span className="popup-curso-label">Descrição</span>
            <RichTextEditor value={descricao} onChange={(newValue) => setDescricao(newValue)} />
          </div>
          <div className="popup-curso-container2">
            <span className="popup-curso-label">URL Compartilhamento</span>
            <input value={urlCompartilhamento} type="text" className="popup-curso-textinput" onChange={(e) => setUrlCompartilhamento(e.target.value)} />
          </div>
          <div className="popup-curso-container5">
            <div className="popup-curso-container6">
              <span className="popup-curso-label2">Trilha</span>
              <input type="checkbox" checked={trilha} className="popup-curso-checkbox" onChange={(e) => setTrilha(e.target.checked)} />
            </div>
            <div className="popup-curso-container7">
              <span className="popup-curso-label3">Ativo</span>
              <input type="checkbox" checked={ativo} className="popup-curso-checkbox1" onChange={(e) => setAtivo(e.target.checked)} />
            </div>
          </div>
        </div>
        <div className="popup-curso-container8">
          <button type="button" className="popup-curso-button" onClick={props.fecharPopup}>
            Cancelar
          </button>
          <button type="button" className="popup-curso-button1" disabled={nome === '' || capa === '' || fundo === ''} onClick={salvarCurso}>
            Salvar Curso
          </button>
        </div>
      </div>
    </div>
  )
}

export default PopupAddCurso
