import React, { useState } from 'react';

const ConsultoriaAcaoMenuTrilha = ({ processando, lista, setarAcao }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Filtra a lista com base no termo de busca
    const filteredLista = lista.filter(option =>
        option.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className=''>
            {/* Campo de pesquisa */}
            <input
                type="text"
                placeholder="Filtar lista..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={processando}
                className="w-full p-2 mb-4 text-xs rounded-md border border-neutral-400 bg-neutral-800 text-neutral-100"
            />

            {/* Grid para exibir os itens filtrados */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 select-none">
                {filteredLista.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => !processando && setarAcao(item._id)}
                        className={`rounded overflow-hidden shadow-lg bg-neutral-900 hover:border-2 cursor-pointer ${processando ? 'pointer-events-none opacity-50' : ''}`}
                    >
                        <div
                            className="h-36 bg-neutral-800 bg-cover bg-center aspect-auto"
                            style={{ backgroundImage: `url('${item.foto_perfil}')` }}
                        ></div>
                        <div className="p-4">
                            <p className="text-sm text-neutral-200 font-semibold">
                                {item.nome.length > 20
                                    ? `${item.nome.slice(0, 20)}...`
                                    : item.nome}
                            </p>
                            <p className="text-xs text-neutral-400 mt-1">
                                {item.email.length > 50
                                    ? `${item.email.slice(0, 50)}...`
                                    : item.email}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default ConsultoriaAcaoMenuTrilha;