import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setUsuario } from '../../features/usuario/usuarioAtual';

import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';
//import './add_consultoria.css';
import '../../react-calendar.css'

import axios from 'axios'
import { toast } from 'react-toastify';

import ModalAgendamentos from '../../views/home-official/consultoria/modal-agendamentos';
import { CalendarDaysIcon, PencilIcon } from '@heroicons/react/24/outline';

const PopupAgendarConsultoria = (props) => {
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();
  const [stepAgendamento, setStepAgendamento] = useState(1);
  const [tituloAgendamento, setTituloAgendamento] = useState('');
  const [descricaoAgendamento, setDescricaoAgendamento] = useState('');
  const [professores, setProfessores] = useState([]);
  const [selectedProfessor, setSelectedProfessor] = useState('');
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [horarioSelecionado, setHorarioSelecionado] = useState('');
  const [agendamentoLoading, setAgendamentoLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pacotesVisible, setPacotesVisible] = useState(false);
  const [consultoriaLista, setConsultoriaLista] = useState([])
  const [mostrarConsultorias, setMostrarConsultorias] = useState(false);

  const [consultores, setConsultores] = useState([]);

  const { consultorias } = usuarioAtual;
  const cota = consultorias?.cota || 0;
  const expiracao = new Date(consultorias?.expiracao);

  const isConsultoriaValida = cota > -1 && expiracao > new Date();
  const [loading, setLoading] = useState(false)
  const [changed, setChanged] = useState(0)

  const mixPanelAgendamento = () => {
    if (!mixpanel) return;

    if (!usuarioAtual || !usuarioAtual._id) {
      console.error('usuarioAtual ou usuarioAtual._id ausente');
      return;
    }
    mixpanel.track("Agendamento | Consultoria (Página)", {
      distinct_id: usuarioAtual._id,
    });
  }

  const fetchProfessores = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/professores?consultores=true`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setProfessores(response.data);
      // setConsultores(response.data.filter(professor => professor.agendamentos.length && professor.agendamentos.filter(agendamento => agendamento.aluno._id === usuarioAtual._id)))
    } catch (error) {
      console.error("Erro ao buscar consultores:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchProfessores();
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (professores) {
      setConsultores(professores.filter(professor => professor.agendamentos.length && professor.agendamentos.filter(agendamento => agendamento.aluno?._id === usuarioAtual?._id)))
    }
  }, [professores])

  const handleProfessorChange = async (professorId) => {
    setSelectedProfessor(professorId || '');
    setHorariosDisponiveis([]);
    if (professorId) {
      await getHorariosDisponiveis(selectedDate, professorId);
      setStepAgendamento(stepAgendamento + 1)
    }
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    if (selectedProfessor) {
      await getHorariosDisponiveis(date, selectedProfessor);
    }
  };

  const getHorariosDisponiveis = async (date, professorId) => {
    setLoading(true);
    const [dia, mes, ano] = [date.getDate(), date.getMonth() + 1, date.getFullYear()];

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/horarios-disponiveis`,
        {
          params: { dia, mes, ano, professorId },
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        }
      );

      console.log("HORARIOS DISPONIVEIS: ", response.data);
      const horarios = response.data.filter(h => h !== "");
      setHorariosDisponiveis(horarios);
    } catch (err) {
      console.error("Erro ao buscar horários disponíveis:", err);
    } finally {
      setLoading(false);
    }
  };

  const professorTemHorarioDisponivel = (date) => {
    if (!selectedProfessor) return false;
    const professor = professores.find(p => p._id === selectedProfessor);
    if (!professor || !professor.horariosDisponiveis) return false;

    // Extrai a data sem a hora para comparação, verificando se `date` é válido
    const dataFormatada = date instanceof Date && !isNaN(date) ? date.toISOString().split('T')[0] : null;
    if (!dataFormatada) return false;

    // Filtra os agendamentos do professor para a data específica
    const agendamentosNaData = professor.agendamentos?.filter(agendamento => {
      const agendamentoData = new Date(agendamento.data);
      return agendamentoData instanceof Date && !isNaN(agendamentoData) &&
        agendamentoData.toISOString().split('T')[0] === dataFormatada;
    }) || [];

    // Define o dia da semana
    const dayOfWeek = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'][date.getDay()];
    const disponibilidade = professor.horariosDisponiveis.find(h => h.diaSemana === dayOfWeek);

    // Verifica se há algum horário disponível no dia que não esteja nos agendamentos
    return disponibilidade?.horarios?.some(horario => {
      const horarioDate = new Date(`${dataFormatada}T${horario}`);

      // Certifica-se de que `horarioDate` é válido
      if (!(horarioDate instanceof Date) || isNaN(horarioDate)) return false;

      // Confirma se o horário é no futuro
      if (horarioDate.getTime() <= Date.now() + 24 * 60 * 60 * 1000) return false;

      // Verifica se o horário específico já está agendado
      return !agendamentosNaData.some(agendamento => {
        const agendamentoHora = new Date(agendamento.data).toISOString().split('T')[1];
        return agendamentoHora === horarioDate.toISOString().split('T')[1];
      });
    });
  };


  const agendarConsultoria = async () => {
    try {
      const [dia, mes, ano] = [selectedDate.getDate(), selectedDate.getMonth(), selectedDate.getFullYear()];
      const [hora, minuto] = horarioSelecionado.split(":");
      const agendamentoData = new Date(ano, mes, dia, hora, minuto).toISOString();

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/agendamentos`, {
        data: agendamentoData,
        professorId: selectedProfessor,
        titulo: "Consultoria",
        descricao: "Descrição do agendamento"
      }, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });

      if (response.status === 201) {
        // Atualiza a cota de consultorias do usuário
        await handleAcao('consultoria', selectedProfessor);
        dispatch(setUsuario({ ...usuarioAtual, consultorias: { ...usuarioAtual.consultorias, cota: usuarioAtual.consultorias.cota - 1 } }));
        // Funções adicionais como MixPanel tracking
        mixPanelAgendamento();

        // Atualiza a lista de professores
        await fetchProfessores();

        // Notificação de sucesso
        toast.success("Consultoria agendada com sucesso!");
        clearVars(); // Função para limpar as variáveis de estado, se necessário
      } else {
        console.error("Erro ao agendar consultoria:", response);
        toast.error("Erro ao agendar consultoria.");
      }
    } catch (err) {
      console.error("Erro ao agendar consultoria:", err);
      toast.error("Erro ao agendar consultoria.");
    }
  };


  const clearVars = () => {
    setTituloAgendamento('');
    setDescricaoAgendamento('');
    setSelectedProfessor('');
    setHorarioSelecionado('');
    setHorariosDisponiveis([]);
    setSelectedDate(new Date());
    setStepAgendamento(1);
  };

  const handleHorarioChange = (newHorario) => {
    if (newHorario !== '') {
      setHorarioSelecionado(newHorario);
      setStepAgendamento(3);
    }
  };

  const formatSelectedDate = (date) => {
    const daysOfWeek = [
      'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira',
      'Quinta-feira', 'Sexta-feira', 'Sábado'
    ];
    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const day = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    return `${day}, ${dayOfMonth} de ${month}`;
  };

  const formattedDate = formatSelectedDate(selectedDate);

  const handleBack = () => {
    if (stepAgendamento === 2) {
      clearVars();
      return;
    }
    setStepAgendamento(stepAgendamento - 1);
  }

  const onboardingConsultoria = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/onboarding`, {
        etapa: 'agendar-consultoria'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.data && response.data.usuario) {
        dispatch(setUsuario(response.data.usuario));
      }
    } catch (err) {
      console.error("Erro ao atualizar onboarding:", err);
    }
  };


  const handleAcao = async (tipo, dadoId) => {
    const acao = {
      tipo: tipo,
      dado: dadoId,
    }
    try {
      const { status, data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha/validar-acao/`, { acao }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (status === 200) {
        console.log("Ação enviada com sucesso:", data);
        dispatch(setUsuario(data.usuario))
        if (data.nova) toast.success("Ação da trilha concluída")
      } else {
        console.error("Erro ao enviar ação:", data);
      }
    } catch (error) {
      console.error("Erro ao enviar ação:", error);
    }
  };

  return (
    <div className='w-screen h-screen fixed bg-neutral-950 bg-opacity-70 top-0 z-40' onClick={() => {
      clearVars();
      props.fecharPopup();
    }}>
      <div className="w-screen mt-5 mx-auto" onClick={(e) => e.stopPropagation()}>
        {mostrarConsultorias && <ModalAgendamentos usuarioAtualId={usuarioAtual._id} consultores={consultores} setConsultores={setConsultores} setMostrarConsultorias={setMostrarConsultorias} professores={professores} setProfessores={() => setProfessores()} fetchProfessores={fetchProfessores} />}
        {/*{showModalPagamento && <ModalCompra usuarioAtual={usuarioAtual} plano={planoSelecionado} closeModal={() => setShowModalPagamento(false)} />}*/}
        <section className='w-11/12 mx-auto'>
          {isConsultoriaValida && !pacotesVisible ? (
            <div id="agendamento" className="w-full h-full flex flex-col gap-3 bg-neutral-950 rounded-lg overflow-hidden">

              <div className='p-5'>
                {stepAgendamento === 1 && (
                  <div className='w-full bg-neutral-950 rounded-lg flex flex-col space-y-3'>
                    <h3 className="text-neutral-300 text-center"><span className='font-bold'>Agende sua consultoria:</span> Acelere seu crescimento e conte com apoio especializado!</h3>
                    <div className={`${props.usuarioAtual.consultorias.cota <= 1 ? 'text-red-500' : 'text-neutral-500'} text-center`}>
                      Você possui {props.usuarioAtual.consultorias.cota} cota(s) para consultorias.
                      <button className='p-0 m-0 animate-pulse text-neutral-400 hover:text-orange-500 active:text-orange-600'>&nbsp;Comprar mais cotas!</button>
                    </div>
                    <div className='overflow-auto'>
                      {/*<div className='grid grid-cols-2 gap-3 mb-5'>*/}
                      <div className='flex flex-row gap-3 mb-5 overflow-hidden overflow-x-auto pb-5'>
                        {professores.length > 0 ? (
                          professores.slice(0, 4).map((professor, index) => (
                            <div
                              key={index}
                              className={`min-w-[50vw] h-fit aspect-auto rounded-lg overflow-hidden relative cursor-pointer border-2 bg-cover bg-center bg-no-repeat border-neutral-950 ${usuarioAtual.consultorias.cota > 0 ? 'hover:border-orange-500' : 'opacity-50 cursor-not-allowed'}`}
                              /*style={{
                                backgroundImage: `url(${professor.foto_perfil ? `${professor.foto_perfil}?timestamp=${new Date().getTime()}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'})`
                              }}*/
                              title={props.usuarioAtual.consultorias.cota > 0 ? '' : 'Você está sem cotas!'}
                              onClick={() => {
                                props.usuarioAtual.consultorias.cota > 0
                                  ? handleProfessorChange(professor._id)
                                  : console.log("Você está sem cotas!")
                              }}
                            >
                              <img
                                src={`${professor.foto_perfil ? `${professor.foto_perfil}?timestamp=${new Date().getTime()}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}`}
                                className='w-full h-auto object-cover'
                                alt="Background"
                              />
                              <div className='w-full bg-neutral-950 opacity-95 text-neutral-300 px-5 py-1 absolute bottom-0'>
                                <p className=''>{professor.nome}</p>
                                {/* <p className='text-neutral-500'>{professor.area}</p> */}
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className='text-gray-500'>Não há consultores cadastrados no momento!</p>
                        )}

                        {professores.length > 4 && (
                          <div
                            className='min-w-[50vw] h-auto aspect-auto rounded-lg overflow-hidden relative cursor-pointer border-2 p-3 hover:border-orange-500 border-neutral-900 flex items-center justify-center'
                            onClick={() => window.location.href = '/consultorias'}
                          >
                            <p className='text-neutral-300 text-center font-poppins font-bold'>Ver mais consultores</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {stepAgendamento === 2 && (
                  <div className=" flex flex-col gap-5">
                    <h3 className="text-neutral-300 text-center">Escolha a melhor Data e Horário</h3>
                    <div className="w-fit mx-auto">
                      <Calendar
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        maxDate={new Date(usuarioAtual?.consultorias?.expiracao || null)}
                        tileClassName={({ date, view }) => {
                          if (view === 'month' && professorTemHorarioDisponivel(date)) {
                            return 'day-with-availability';
                          }
                        }}
                      />
                      <div className="space-y-3 text-neutral-400 text-xs my-5 text-center">
                        <span className="home-text88">{formattedDate}</span>
                        <div className="home-horrios">
                          {!loading && horariosDisponiveis.length ?
                            <div className='w-fit mx-auto'>
                              <h3 className='text-neutral-300'>Escolha um horário dentre os disponíveis</h3>
                              <div className='flex flex-row w-full gap-2 my-3'>
                                {horariosDisponiveis.map((h, index) => (
                                  <span
                                    key={index}
                                    className={`mx-auto border border-neutral-300 text-neutral-300 rounded-lg w-fit py-1 px-2 text-center cursor-pointer hover:bg-orange-600 active:bg-orange-700 ${horarioSelecionado === h ? 'bg-orange-800' : ''}`}
                                    onClick={() => handleHorarioChange(h)}
                                  > {h}
                                  </span>
                                ))}
                              </div>
                            </div>
                            : (
                              selectedProfessor ? <div>
                                {loading ?
                                  <span className='animate-bounce'>Buscando horários disponíveis...</span>
                                  :
                                  <span className="text-less">Nenhum horário disponível para a data selecionada</span>
                                }
                              </div> : (
                                <span className="text-less">Selecione um professor para ver os horários</span>
                              )
                            )}
                          {loading &&
                            <span className='animate-bounce text-orange-500'>Carregando...</span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {stepAgendamento === 3 && (
                  <div className='w-full'>
                    <div className="space-y-5 rounded-lg h-full">
                      <div className="w-full flex flex-row items-center text-neutral-300 p-2 align-middle justify-center text-center gap-5 rounded-lg bg-neutral-900 ">
                        <CalendarDaysIcon className='w-5 h-5' />
                        <div className="data-selecionada">
                          <span>{selectedDate.toLocaleDateString('pt-br')}</span>
                          <span>às {horarioSelecionado}h</span>
                        </div>
                        <PencilIcon className='w-5 h-5' />
                      </div>

                      <div className="space-y-3">
                        <h3 className="text-neutral-500">Descreva o que você está precisando</h3>
                        <input
                          type="text"
                          className="w-full p-2.5 text-base rounded-md bg-neutral-700 text-white focus:outline-none focus:border-orange-500 focus:ring-0 disabled:opacity-50"
                          value={tituloAgendamento}
                          placeholder="Resuma sua dúvida, pedido ou necessidade em 1 frase"
                          onChange={(e) => setTituloAgendamento(e.target.value)}
                        />
                        <textarea
                          className="w-full p-2.5 text-base rounded-md bg-neutral-700 text-white focus:outline-none focus:border-orange-500 focus:ring-0 disabled:opacity-50"
                          value={descricaoAgendamento}
                          placeholder="Descreva detalhadamente o que você precisa"
                          onChange={(e) => setDescricaoAgendamento(e.target.value)}
                        ></textarea>
                      </div>


                      {selectedDate && selectedProfessor && horarioSelecionado && (
                        <button className={`w-full bg-orange-500 text-white hover:bg-orange-700 active:bg-orange-900 p-2 rounded-lg ${agendamentoLoading ? 'loading' : ''}`} onClick={() => agendarConsultoria()}>{agendamentoLoading ? 'Aguarde...' : 'Concluir Agendamento'}</button>
                      )}
                    </div>
                  </div>
                )}
                <div className='space-y-4 mt-5'>
                  {stepAgendamento > 1 &&
                    <div className='w-full text-center text-white hover:text-orange-500 active:text-orange:800 cursor-pointer' onClick={() => handleBack()}>Voltar à etapa anterior</div>
                  }
                  {consultores.length ?
                    <button className='w-full p-3 rounded-lg bg-none text-orange-500 hover:text-white active:text-neutral-400' onClick={() => setMostrarConsultorias(!mostrarConsultorias)}>Mostrar meus agendamentos</button>
                    :
                    <p className='text-xs text-white text-center'>Você ainda não possui nenhuma consultoria</p>
                  }
                </div>
              </div>
              <button className='w-full p-1 bg-neutral-800 text-neutral-400 hoveR:bg-red-500 hover:text-red-50 active:bg-red-600 active:text-whute' onClick={() => {
                clearVars();
                props.fecharPopup();
              }}>Fechar</button>
            </div>
          ) : (<div style={{ display: 'none' }}></div>)}
        </section>
      </div>
    </div >
  )
}

export default PopupAgendarConsultoria
