import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import { setCurso } from '../features/curso/cursoAtual';

import axios from 'axios';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';

import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import Aside from '../components/aside'
import { CheckCircleIcon } from '@heroicons/react/24/outline';


const LandingPageAutomacao = (props) => {
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const navigate = useNavigate()
    const location = useLocation()

    const [bunnyUrlPublicoAula, setBunnyUrlPublicoAula] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/cursos/previas/`);

    const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

    const { paginaCodigo, cursoCodigo } = useParams();
    const token = localStorage.getItem("token");
    const header_geral = { Authorization: `Bearer ${token}` }
    const [dadosCurso, setDadosCurso] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/curso/codigo/${cursoCodigo}`, { headers: header_geral });
                if (isMounted && response.status === 200) {
                    setDadosCurso(response.data);
                    handleAcao('marketplace', response.data._id);
                } else {
                    console.log(response)
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (cursoCodigo) fetchData();
        // window.scrollTo(0, 0);

        return () => { isMounted = false };
    }, [paginaCodigo, cursoCodigo]);

    const getFormat = (videoUrl) => {
        // Divide a URL pelo ponto e retorna o último item da lista
        const parts = videoUrl.split('.');
        return parts[parts.length - 1];
    }

    const handleAcao = async (tipo, dadoId) => {
        const acao = {
            tipo: tipo,
            dado: dadoId,
        }
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha/validar-acao/`, { acao }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (status === 200) {
                console.log("Ação enviada com sucesso:", data);
                // dispatch(setUsuario(data.usuario))
                if (data.nova) toast.success("Ação da trilha concluída")
            } else {
                console.error("Erro ao enviar ação:", data);
            }
        } catch (error) {
            console.error("Erro ao enviar ação:", error);
        }
    };

    return (
        <div className='text-red-500'>

            <header className="fixed w-full py-3 bg-neutral-950 z-40">
                <div className="w-11/12 m-auto flex items-center justify-between">
                    <a href='/'>
                        <img className="max-w-auto max-h-7 mx-0" src='/main_logo.png' />
                    </a>
                    <span className='flex text-center gap-2 items-center'>
                        {usuarioAtual?.tipo === 'admin' &&
                            <span>
                                <a href={`/admin/master-curso/${dadosCurso?._id}`} target='_blank' className="hidden sm:block text-yellow-500 text-sm float-right p-2 no-underline hover:text-orange-500 hover:border-orange-800">Editar Automação</a>
                                <a href={`/admin/master-curso/${dadosCurso?._id}`} target='_blank' className="block sm:hidden text-yellow-500 text-sm float-right p-2 no-underline hover:text-orange-500 hover:border-orange-800" title="Editar Automação">Editar</a>
                            </span>
                        }
                        <a href={`/master/${paginaCodigo}`} className="hidden sm:block text-gray-200 text-sm float-right border-2 border-gray-200 rounded-lg p-2 no-underline hover:text-orange-500 hover:border-orange-500">Ir para a disciplina</a>
                        <a href={`/master/${paginaCodigo}`} className="block sm:hidden text-gray-200 text-sm float-right border-2 border-gray-200 rounded-lg p-2 no-underline hover:text-orange-500 hover:border-orange-500" title="Ir para a disciplina">Voltar</a>
                    </span>
                </div>
            </header>

            <main>
                <div className='w-full h-auto min-h-screen pb-5 flex items-center bg-orange-500 bg-cover bg-bottom bg-no-repeat bg-simbium'>
                    <div className='w-full h-full md:w-10/12 m-auto pt-20 md:py-auto lg:my-auto flex flex-col gap-10 lg:flex-row align-middle items-center text-white'>
                        <div className='w-11/12 lg:w-9/12 flex flex-col gap-5 items-center align-middle'>
                            <h1 className='text-2xl md:text-3xl font-bold text-left'>{dadosCurso?.titulo}</h1>
                            <p className='text:sm md:text-md xl:text-lg text-justify whitespace-pre-wrap'>{dadosCurso?.descricao}</p>
                            {usuarioAtual.ref.plano && usuarioAtual.ref.plano === process.env.REACT_APP_PLANO_AVIAO ?
                                <p>Automações só podem ser adquiridas ou acessadas nos planos anuais!</p>
                                :
                                <div className='w-full'>
                                    {usuarioAtual.ref.plano && usuarioAtual.ref.plano !== process.env.REACT_APP_PLANO_FOGUETE && !usuarioAtual.automacoes_compradas?.includes(dadosCurso._id) ?
                                        <div className='flex flex-col items-center space-y-5'>
                                            <a href={`${(dadosCurso?.linkCompra || dadosCurso?.materiais[0])}`} className='animate-bounce p-5 mt-5 font-lg text-neutral-900 bg-neutral-100 hover:bg-yellow-500 hover:text-white active:bg-yellow-300 rounded-lg'>
                                                Quero automatizar meu negócio
                                            </a>
                                            <p className='text-xl'>{dadosCurso?.preco?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                            <p className='text-xs text-neutral-300'>Automação entregue em até 10 dias após confirmação do pagamento.</p>
                                        </div>
                                        :
                                        <div className='flex flex-col items-center space-y-5'>
                                            <a href={`${dadosCurso?.materiais}`} download className='p-2 md:p-5 mt-5 font-lg text-neutral-900 text-center bg-neutral-100 hover:bg-yellow-500 hover:text-white active:bg-yellow-300 rounded-lg'>
                                                Acessar
                                            </a>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className='w-11/12 mx-auto md:w-full my-auto'>
                            {dadosCurso?.videoPrevia && (
                                <video
                                    src={`${dadosCurso.videoPrevia}?timestamp=${new Date().getTime()}`}
                                    controls autoPlay muted controlsList="nodownload"
                                    onContextMenu={(e) => e.preventDefault()}
                                    className="w-full h-full object-contain transition-opacity duration-300 ease-in-out rounded-xl"

                                >
                                    Seu navegador não suporta a reprodução de vídeos.
                                </video>
                            )}
                        </div>
                    </div>
                </div>
                <div className='w-full h-auto min-h-screen bg-neutral-100 p-10 md:p-14 xl:p-20 bg-cover bg-top bg-no-repeat bg-simbium-gray flex flex-col gap-10 items-center justify-center align-middle'>
                    <h2 className='text-orange-500 text-3xl md:text-5xl text-center font-poppins font-semibold'>Funcionalidades</h2>
                    <div className='w-fit max-w-7xl h-fit grid grid-cols-1 md:grid-cols-2 gap-10'>
                        {dadosCurso?.funcionalidades?.map((funcionalidade, index) => {
                            return (
                                <div key={index} className='p-5 space-y-5 border-2 border-transparent hover:border-2 hover:border-neutral-200 rounded-xl'>
                                    <span className='flex gap-3'>
                                        <CheckCircleIcon className='w-7 h-7 text-neutral-500' />
                                        <h3 className='text-orange-600 text-xl'>{funcionalidade.titulo}</h3>
                                    </span>
                                    <p className='text-neutral-500 text-md'>{funcionalidade.descricao}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/*<div className='w-full min-h-screen bg-neutral-100 py-10 bg-cover bg-top bg-no-repeat bg-simbium-gray'>
                    <div className='w-11/12 lg:w-10/12 h-full m-auto py-20 md:py-auto flex flex-col gap-10 align-middle items-center text-white'>
                        <div className='w-full flex flex-col gap-5 items-center align-middle'>
                            <h2 className='text-orange-500 text-3xl md:text-5xl text-center'>Funcionalidades</h2>
                            <div className='grid grid-cols-1 gap-10 mt-8 md:grid-cols-2 4xl:grid-cols-3 w-full md:w-10/12 m-auto'>
                                {dadosCurso?.funcionalidades?.map((funcionalidade, index) => {
                                    return (
                                        <div key={index} className='p-5 border-2 border-transparent hover:border-2 hover:border-neutral-200 rounded-xl'>
                                            <span className='flex gap-3'>
                                                <CheckCircleIcon className='w-7 h-7 text-neutral-500' />
                                                <h3 className='text-orange-600 text-xl'>{funcionalidade.titulo}</h3>
                                            </span>
                                            <p className='text-neutral-500 text-md'>{funcionalidade.descricao}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>*/}
                {usuarioAtual.ref.plano === process.env.REACT_APP_PLANO_AVIAO ?
                    <p className='text-center my-5 text-lg text-white'>Automações só podem ser adquiridas ou acessadas nos planos anuais!</p>
                    :
                    <div className='w-full'>
                        {usuarioAtual.ref.plano && usuarioAtual.ref.plano !== process.env.REACT_APP_PLANO_FOGUETE && !usuarioAtual.automacoes_compradas?.includes(dadosCurso._id) ?
                            <div className='w-full h-fit flex items-center justify-center'>
                                <a href={`${dadosCurso?.linkCompra}`} className='w-11/12 xl:w-7/12 p-5 my-10 animate-pulse font-lg text-neutral-100 bg-orange-500 hover:animate-none hover:bg-yellow-500 hover:text-white active:bg-yellow-300 rounded-lg text-center text-xl md:text-3xl'>Quero automatizar meu negócio</a>
                            </div>
                            :
                            <div className='w-full h-fit flex items-center justify-center'>
                                <a href={`${dadosCurso?.materiais}`} download className='w-11/12 xl:w-7/12 p-5 my-10 font-lg text-orange-500 bg-neutral-100 hover:animate-none hover:bg-orange-500 hover:text-white active:bg-orange-800 rounded-lg text-center text-xl md:text-3xl'>Acessar</a>
                            </div>
                        }
                    </div>
                }

            </main>
            <Footer></Footer>
        </div>
    )
}

export default LandingPageAutomacao