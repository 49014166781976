import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { setUsuario, resetUsuario } from '../features/usuario/usuarioAtual';
import { AtSymbolIcon, BellIcon, BoltIcon, KeyIcon, UserCircleIcon, UserGroupIcon } from '@heroicons/react/24/solid';

const Header = ({ showMenu, setShowMenu }) => {
    const navigate = useNavigate();
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }
    const [showSearchPanel, setShowSearchPanel] = useState(false)
    const [dataSearchPanel, setDataSearchPanel] = useState([]);
    const [searching, setSearching] = useState(false)
    const [notificacoes, setNotificacoes] = useState([])
    const [qtdNotificacoes, setQtdNotificacoes] = useState(0)
    const [notificacaoVisible, setNotificacaoVisible] = useState(false)
    const [usuarioTipo, setUsuarioTipo] = useState(usuarioAtual?.tipo ? usuarioAtual.tipo : 'guest')
    const isSuper = ['admin', 'consultor'].includes(usuarioTipo);
    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState('');
    let cancelToken;

    useEffect(() => {
        if (usuarioAtual) {
            const isAccessExpired = new Date(usuarioAtual.acesso) < new Date();
            const isTrialUser = usuarioAtual.trial;
            const isPaid = usuarioAtual.ref.pago;
            console.log("EXPIRADO: ", isAccessExpired)
            console.log("TRIAL: ", isTrialUser)
            console.log("PAGO: ", isPaid)
            if (isAccessExpired || (!isTrialUser && !isPaid)) {
                //localStorage.removeItem("token");
                //dispatch(resetUsuario());
                // navigate("/assine-simbium");
            }
        }
    }, [usuarioAtual]);

    // Função debounced para evitar chamadas excessivas
    const debounce = (func, delay) => {
        let debounceTimer;
        return (...args) => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const searchAula = useCallback(debounce(async (query) => {
        if (query.length > 2) {
            setSearching(true);

            if (cancelToken) {
                cancelToken.cancel(); // Cancelar a requisição anterior, se houver
            }
            cancelToken = axios.CancelToken.source();

            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/search/aulas/${query}`, {
                    headers: header_geral,
                    cancelToken: cancelToken.token
                });

                if (response.status === 200) {
                    setDataSearchPanel(response.data);
                    setShowSearchPanel(true);
                } else {
                    toast.error("Erro na obtenção do conteúdo");
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    toast.error("Erro na obtenção do conteúdo");
                }
            } finally {
                setSearching(false);
            }
        } else {
            setShowSearchPanel(false);
        }
    }, 300), []); // 300ms de atraso

    const handleSearchInput = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        searchAula(value);
    };

    const scrollIndicacao = () => {
        navigate('/');
        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }, 500);
    };


    const formatDateDistance = (date) => {
        const currentDate = new Date();
        const creationDate = new Date(date);
        const timeDiff = currentDate - creationDate;

        const seconds = Math.floor(timeDiff / 1000);
        if (seconds < 60) {
            return `há ${seconds} segundos atrás`;
        }

        const minutes = Math.floor(timeDiff / (1000 * 60));
        if (minutes === 1) {
            return 'há 1 minuto atrás';
        } else if (minutes < 60) {
            return `há ${minutes} minutos atrás`;
        }

        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        if (hours === 1) {
            return 'há 1 hora atrás';
        } else if (hours < 24) {
            return `há ${hours} horas atrás`;
        }

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        if (days === 1) {
            return 'há 1 dia atrás';
        } else if (days < 7) {
            return `há ${days} dias atrás`;
        }

        const weeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7));
        if (weeks === 1) {
            return 'há 1 semana atrás';
        } else if (weeks < 4) {
            return `há ${weeks} semanas atrás`;
        }

        const months = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30.44));
        if (months === 1) {
            return 'há 1 mês atrás';
        } else if (months < 12) {
            return `há ${months} meses atrás`;
        }

        const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25));
        if (years === 1) {
            return 'há 1 ano atrás';
        } else {
            return `há ${years} anos atrás`;
        }
    }

    const lerNotificacao = async (id, link) => {
        try {
            // Faz a requisição para marcar a notificação como lida
            await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/notificacoes/ler`,
                { id },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );

            // Abre o link em uma nova aba
            window.open(link, '_blank');

        } catch (err) {
            console.error('Erro ao marcar a notificação como lida:', err);
        }
    };


    const toggleNotificacaoVisible = () => {
        setNotificacaoVisible(!notificacaoVisible)
    }

    useEffect(() => {
        let isMounted = true;

        // Rolar para o topo da página ao carregar
        window.scrollTo(0, 0);

        const fetchUsuarioAtual = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/usuario_atual`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                if (isMounted && res.data && res.data.acesso !== usuarioAtual.acesso) {
                    dispatch(setUsuario(res.data));
                }
            } catch (err) {
                console.error("Erro ao buscar usuário atual", err);
            }
        };

        const fetchNotificacoes = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/notificacoes`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                if (isMounted) {
                    setNotificacoes(res.data);
                    setQtdNotificacoes(res.data.filter(n => !n.lida).length);
                }
            } catch (err) {
                console.error("Erro ao buscar notificações", err);
            }
        };

        const verificarToken = () => {
            if (!localStorage.getItem("token") || new Date(usuarioAtual.acesso) < new Date()) {
                if (usuarioAtual.trial && localStorage.getItem("token")) {
                    navigate("/assine-simbium");
                } else {
                    localStorage.removeItem("token");
                    dispatch(resetUsuario());
                }
            }
        };

        // Executa as funções necessárias
        fetchUsuarioAtual();
        verificarToken();
        fetchNotificacoes();

        return () => {
            isMounted = false;
        };
    }, [navigate, dispatch, usuarioAtual.acesso, usuarioAtual.trial]);


    return (
        <>
            <header className="fixed w-screen bg-neutral-900 z-10">
                <section className='w-full rounded-b-3xl md:rounded-b-none bg-neutral-800 text-gray-100 py-2'>
                    <div className='w-11/12 md:w-5/6 mx-auto flex justify-between gap-3'>
                        <div className='min-w-[30px] m-auto flex flex-col items-center align-middle space-y-1.5 group cursor-pointer' onClick={() => setShowMenu(!showMenu)}>
                            <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                            <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                            <div className='w-full h-1 rounded-xl bg-neutral-500 group-hover:bg-neutral-100 group-active:bg-neutral-200'></div>
                        </div>
                        <div className='w-full ml-2 flex justify-between items-center gap-2'>
                            <div className=''>
                                <div className='w-full flex justify-between items-center align-middle gap-5'>
                                    <button
                                        className='flex flex-row items-center align-middle justify-center space-x-1'
                                        onClick={() => { window.location.href = '/' }}>
                                        <img src="/image9-200h.png" className='h-4 float-left' />
                                        <p className='font-bold text-md font-poppins'>Simbium</p>
                                    </button>
                                    <div className='w-fit flex items-center gap-2'>
                                        {isSuper &&
                                            <div className='w-full'>
                                                <a href={`/${usuarioTipo}`}
                                                    className='hidden md:block text-yellow-500 text-center text-xs hover:text-yellow-600 active:text-yellow-700'
                                                >
                                                    {{ admin: "Administrador", consultor: "Consultor" }[usuarioTipo]}
                                                </a>


                                                <a href={`/${usuarioTipo}`} className='block md:hidden text-center text-xs'>
                                                    {usuarioTipo === 'admin' ?
                                                        <KeyIcon className='w-4 h-4 fill-yellow-500 hover:fill-yellow-600 active:fill-yellow-700' />
                                                        :
                                                        <UserCircleIcon className='w-4 h-4 fill-yellow-500 hover:fill-yellow-600 active:fill-yellow-700' />
                                                    }
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <h2 className='text-slate-300 text-xs truncate max-w-40 sm:max-w-none'>Bem vindo(a) <span className='font-bold'>{usuarioAtual.nome}</span></h2>
                            </div>
                            <div className="sm:relative" onClick={usuarioAtual.tipo !== 'guest' ? toggleNotificacaoVisible : null}>
                                <BellIcon className='w-5 h-5 text-neutral-200' />
                                {notificacaoVisible && (
                                    <div>
                                        {/* Fundo que cobre a tela toda */}
                                        <div
                                            className="fixed inset-0 bg-black opacity-50 z-20"
                                            onClick={() => setNotificacaoVisible(false)} // Fecha ao clicar no fundo
                                        />

                                        {/* Contêiner das notificações */}
                                        <div className="absolute z-30 bg-neutral-900 shadow-lg w-full sm:top-full sm:w-[50vw] md:w-[40vw] lg:w-[30vw] xl:w-[20vw] top-20 left-0 rounded-lg p-2">
                                            {notificacoes.length > 0 ? (
                                                notificacoes.map((notificacao, index) => (
                                                    <div
                                                        onClick={() => { lerNotificacao(notificacao._id, notificacao.link) }}
                                                        key={index}
                                                        className={`flex items-center p-4 mb-4 bg-neutral-700 rounded-lg cursor-pointer transition-all duration-200 ease-in-out hover:bg-neutral-600 ${notificacao.lida ? 'opacity-70' : ''}`}
                                                    >
                                                        <div className="flex-shrink-0 mr-4">
                                                            <img
                                                                alt="Imagem de notificação"
                                                                src={`${notificacao.foto}?timestamp=${new Date().getTime()}`}
                                                                className="w-12 h-12 rounded-full object-cover"
                                                            />
                                                        </div>
                                                        <div className="flex-grow">
                                                            <div className="flex items-center">
                                                                {/* Ícone desativado para este exemplo */}
                                                                {/* <div className="text-orange-500 mr-2" dangerouslySetInnerHTML={{ __html: notificacao.icone }} /> */}
                                                                <span className="font-semibold text-white">{notificacao.conteudo}</span>
                                                            </div>
                                                            <span className="text-neutral-50 text-sm">{formatDateDistance(notificacao.data_criacao)}</span>
                                                        </div>
                                                        {!notificacao.lida && (
                                                            <div className="ml-4 bg-orange-500 w-3 h-3 rounded-full" />
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="text-center text-gray-50">Nenhuma notificação no momento.</div>
                                            )}
                                        </div>
                                    </div>
                                )}


                                {qtdNotificacoes > 0 && (
                                    <div className="relative w-4 h-4 p-1 -top-4 left-3 flex items-center justify-center rounded-full bg-orange-500 select-none">
                                        <span className="text-white text-xs">{qtdNotificacoes}</span>
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className=' flex flex-row gap-5 justify-between'>
                            <div className='hidden lg:flex items-center min-w-[24vw]'>
                                <input type='text' placeholder='Pesquisar conteúdos (03 caracteres no mínimo)' className='w-full text-xs rounded-lg bg-neutral-800 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' onChange={handleSearchInput} />
                            </div>
                            <div className='w-fit whitespace-nowrap flex items-center'>
                                {usuarioAtual.tipo !== 'guest' && !usuarioAtual.emailConfirmado ?
                                    <>
                                        <a href={`/confirmacao-cadastro`} className='text-sm hidden sm:flex items-center text-orange-500 animate-pulse'>Confirme seu E-mail</a>
                                        <a href={`/confirmacao-cadastro`} className='text-sm flex sm:hidden items-center text-orange-500 animate-pulse'>
                                            <AtSymbolIcon className='w-5 h-5' />
                                        </a>
                                    </>
                                    :
                                    <>
                                        {usuarioAtual.tipo !== 'guest' && !usuarioAtual.ref.pago && (usuarioAtual.status && usuarioAtual.status !== 'pagamento-pendente') &&
                                            <>
                                                <a href={`/assine-simbium`} className='text-sm hidden sm:flex items-center text-yellow-500 animate-pulse'>Assine a Simbium</a>
                                                <a href={`/assine-simbium`} className='text-sm flex sm:hidden items-center text-yellow-500 animate-pulse'>
                                                    <BoltIcon className='w-5 h-5' />
                                                </a>
                                            </>
                                        }

                                        {usuarioAtual.tipo !== 'guest' && usuarioAtual.ref.pago &&
                                            <>
                                                <a href={`${process.env.REACT_APP_COMUNIDADE_URL}`} target="_blank" className='text-sm hidden sm:flex items-center text-neutral-300'>Acesse a Comunidade</a>
                                                <a href={`${process.env.REACT_APP_COMUNIDADE_URL}`} target="_blank" className='text-sm flex sm:hidden items-center text-neutral-300'>
                                                    <UserGroupIcon className='w-5 h-5' />
                                                </a>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <a href="/meu-perfil" className="rounded-full bg-neutral-950 min-w-[40px] h-[40px] my-auto border border-orange-500 overflow-hidden flex items-center justify-center">
                                <img src={usuarioAtual.foto_perfil ? usuarioAtual.foto_perfil + `?timestamp=${new Date().getTime()}` : '/sem-foto.png'} className='object-cover w-full h-full hover:scale-105 active:scale-95' alt="Foto do perfil"></img>
                            </a>
                        </div>
                    </div>
                </section>

                {showSearchPanel &&
                    <section className="fixed z-40 w-screen h-screen flex justify-center">
                        <div className="bg-neutral-950 opacity-90 z-10 w-full h-full" onClick={() => setShowSearchPanel(false)}></div>
                        <div className="absolute top-0  mt-5 p-5 z-20 rounded-xl w-11/12 bg-neutral-700">
                            <div className='text-neutral-50'>
                                {searching ?
                                    <div>Buscando dados...</div>
                                    :
                                    <div>
                                        {dataSearchPanel.length === 0 ?
                                            <p>Nenhum conteúdo encontrado...</p>
                                            :
                                            <div className='flex flex-col divide-y divide-neutral-500'>
                                                {dataSearchPanel?.map((aula, index) => {
                                                    return (
                                                        <a key={index} className='w-full py-5 hover:bg-neutral-500' href={`/master/aula/${aula.paginaCodigo}/${aula.cursoCodigo}/${aula.codigo}`}>{aula.titulo}</a>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                }

            </header>
            <div className="w-screen h-16 pb-2 bg-neutral-900"></div>
        </>
    )
}

export default Header