import { ArrowDownTrayIcon, ChevronDownIcon, ChevronUpIcon, FilmIcon } from "@heroicons/react/24/outline";
import AulaMenu from "./aula-menu";
import { useEffect, useState } from "react";

const CourseListCourseMenu = ({ paginaCodigo, cursoSelecionado, cursos, aulaCodigo, listarAulas, handleListClasses }) => {
    const [selectedCourse, setSelectedCourse] = useState(null);

    // Atualiza o curso selecionado sempre que `cursoSelecionado` mudar
    useEffect(() => {
        const curso = cursos.find((el) => el._id === cursoSelecionado);
        setSelectedCourse(curso || null);
    }, [cursoSelecionado, cursos]);

    return (
        <ul className="list-none max-h-[600px] divide-y divide-neutral-900 min-w-[250px] min-h-[35vw] w-full">
            {cursos?.map((curso) => (
                <li
                    key={curso._id}
                    className={`w-full text-white text-sm ${cursoSelecionado === curso._id ? "bg-neutral-900" : "bg-neutral-800 hover:bg-neutral-700"
                        }`}
                >
                    <button
                        type="button"
                        className="w-full p-5 text-md font-bold space-y-1"
                        onClick={() => handleListClasses(curso._id)}
                    >
                        <div className="w-full flex flex-row justify-between items-center">
                            <p className="text-left">{curso.titulo}</p>
                            {cursoSelecionado === curso._id && curso.aulas?.length && listarAulas ? (
                                <ChevronUpIcon className="w-4 h-4" />
                            ) : (
                                <ChevronDownIcon className="w-4 h-4" />
                            )}
                        </div>
                        <div className="flex flex-row items-center align-middle gap-2 font-normal text-xs text-neutral-400">
                            <FilmIcon className="w-3 h-3" />
                            <span className="flex items-center align-middle gap-3">
                                <p>{curso.aulas?.length || 0} aula(s)</p>
                                {curso.materiais?.length && curso.materiais[0] ? (
                                    <span className="flex items-center align-middle gap-1 text-xs px-1 rounded-sm border border-neutral-400 hover:bg-neutral-600">
                                        <a
                                            href={`${curso.materiais[0]}`}
                                            target="_blank"
                                            onClick={(e) => e.stopPropagation()} // Impede a propagação do evento
                                            rel="noopener noreferrer"
                                        >
                                            Materiais
                                        </a>
                                        <ArrowDownTrayIcon className="w-2 h-2" />
                                    </span>
                                ) : (
                                    <span></span>
                                )}
                            </span>
                        </div>
                    </button>

                    {/* Renderizar as aulas apenas para o curso selecionado */}
                    {cursoSelecionado === curso._id && listarAulas && (
                        <>
                            {selectedCourse?.aulas?.length ? (
                                <AulaMenu
                                    paginaCodigo={paginaCodigo}
                                    curso={selectedCourse}
                                    aulas={selectedCourse.aulas} // Passa as aulas específicas do curso selecionado
                                    aulaCodigo={aulaCodigo}
                                />
                            ) : (
                                <p className="text-xs text-neutral-400 text-center p-3">
                                    Aulas deste módulo serão disponibilizadas em breve
                                </p>
                            )}
                        </>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default CourseListCourseMenu;
