import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon, TrashIcon, PlusCircleIcon, SparklesIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import PaginaMasterAcaoMenuTrilha from './acao_menu_opcoes/pagina_master';
import CursoMasterAcaoMenuTrilha from './acao_menu_opcoes/curso_master';
import AulaMasterAcaoMenuTrilha from './acao_menu_opcoes/aula_master';
import AulaTrilhaAdmin from './acao_menu_opcoes/aula_trilha';
import ConsultoriaAcaoMenuTrilha from './acao_menu_opcoes/consultoria';
import ShortsAcaoMenuTrilha from './acao_menu_opcoes/shorts';
import ProdutosCatalogadosAcaoMenuTrilha from './acao_menu_opcoes/produto_catalogado';
import MarketplaceAcaoMenuTrilha from './acao_menu_opcoes/marketplace';

const AcaoMenu = ({ processando, setProcessando, divisor, trilhaSalvar, setTrilhaSalva, fetchTrilha, setShowAcaoMenu }) => {
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const token = localStorage.getItem("token");
    const axios_header = { headers: { Authorization: `Bearer ${token}` } };

    const opcao_url = {
        'boas-vindas': '',
        'pagina-master': 'master/pagina',
        'curso-master': 'master/cursos',
        'aula-master': 'master/aulas',
        'aula-trilha': 'aula-trilha',
        'consultoria': 'usuarios/professores?consultores=true',
        'short': 'aulas/shorts',
        'comunidade': '',
        'produto-catalogado': 'produtos-catalogados',
        'marketplace': 'marketplace',
    };

    const opcao_modelo = {
        'boas-vindas': '',
        'pagina-master': 'Pagina',
        'curso-master': 'CursoMaster',
        'aula-master': 'AulaMaster',
        'aula-trilha': 'AulaTrilha',
        'consultoria': 'Usuario',
        'short': 'Aula',
        'comunidade': '',
        'produto-catalogado': 'Produto',
        'marketplace': 'Marketplace',
    };

    const opcoes_acoes = [
        { titulo: 'Assistir vídeo de Boas-Vindas', tipo: 'boas-vindas' },
        { titulo: 'Acessar disciplina', tipo: 'pagina-master' },
        { titulo: 'Acessar módulo', tipo: 'curso-master' },
        { titulo: 'Acessar aula', tipo: 'aula-master' },
        { titulo: 'Assistir aula da trilha', tipo: 'aula-trilha' },
        { titulo: 'Agendar Consultoria', tipo: 'consultoria' },
        { titulo: 'Assistir vídeo do Shorts Simbium', tipo: 'short' },
        { titulo: 'Acessar a Comunidade', tipo: 'comunidade' },
        { titulo: 'Acessar Produto Catalogado', tipo: 'produto-catalogado' },
        { titulo: 'Acessar Marketplace', tipo: 'marketplace' },
    ];

    const [opcaoAcao, setOpcaoAcao] = useState(null);
    const [lista, setLista] = useState(null);

    const [acaoTrilha, setAcaoTrilha] = useState(null);

    const handleAdicionarOpcaoAcao = (opcao) => {
        if (['boas-vindas', 'comunidade'].includes(opcao.tipo)) {
            setAcaoTrilha({
                titulo: opcao.titulo,
                tipo: opcao.tipo,
                dado: null,
                modelo: null,
            });
        } else {
            setOpcaoAcao(opcao);
        }
    };

    const handleCarregarDadosOpcaoAcao = async () => {
        if (!opcaoAcao) {
            setLista(null);
            console.error("Nenhuma opção selecionada");
            return;
        }
        setProcessando(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/${opcao_url[opcaoAcao.tipo]}`,
                axios_header,
                { params: { shorts: true } }
            );
            if (response.status === 200 || response.status === 201) {
                console.log("DADOS DA LISTA: ", response.data)
                setLista(response.data);
            } else {
                console.error("Erro ao buscar lista: ", response.data);
                toast.error("Erro ao buscar dados");
            }
        } catch (error) {
            console.error("Erro ao buscar lista: ", error);
            toast.error("Erro ao buscar dados");
        } finally {
            setProcessando(false);
        }
    };

    useEffect(() => {
        if (opcaoAcao) handleCarregarDadosOpcaoAcao();
    }, [opcaoAcao]);

    const voltarAoMenu = () => {
        setOpcaoAcao(null)
        setLista(null)
    }

    const setarAcao = (item_id, fonte = null) => {
        const { titulo, tipo } = opcaoAcao; // Desestruturando opcaoAcao para evitar repetição
        const modelo = fonte
            ? (fonte === 'marketplace' ? 'Marketplace' : 'CursoMaster')
            : opcao_modelo[tipo]; // Condicional simplificada para atribuição do modelo

        setAcaoTrilha({
            titulo,
            tipo,
            dado: item_id,
            modelo
        });
    };


    useEffect(() => {
        if (acaoTrilha) {
            criarAcaoTrilha()
        }
    }, [acaoTrilha])

    const criarAcaoTrilha = async () => {
        setProcessando(true);
        try {
            const { status, data } = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha`,
                {
                    titulo: acaoTrilha.titulo,
                    tipo: acaoTrilha.tipo,
                    modelo: acaoTrilha.modelo,
                    dado: acaoTrilha.dado,
                    divisorId: divisor._id,
                },
                axios_header
            );

            if (status === 201) {
                await fetchTrilha(); // Atualiza a lista de acaoes
                toast.success("Ação do divisor cadastrado com sucesso!");
                // resetAcaoMenu()
            } else {
                console.error("Erro ao cadastrar ação do divisor:", status);
                toast.error("Erro ao cadastrar um novo ação do divisor.");
            }
        } catch (error) {
            console.error("Erro ao cadastrar ação do divisor:", error.response?.data || error.message);
            toast.error("Erro ao cadastrar um novo ação do divisor.");
        } finally {
            setProcessando(false);
        }
    };

    const resetAcaoMenu = () => {
        setOpcaoAcao(null)
        setLista(null)
        setShowAcaoMenu(null)
    }

    const renderOpcaoAcao = () => {
        switch (opcaoAcao?.tipo) {
            case 'pagina-master':
                return <PaginaMasterAcaoMenuTrilha processando={processando} lista={lista} setarAcao={setarAcao} />;
            case 'curso-master':
                return <CursoMasterAcaoMenuTrilha processando={processando} lista={lista} setarAcao={setarAcao} />;
            case 'aula-master':
                return <AulaMasterAcaoMenuTrilha processando={processando} lista={lista} setarAcao={setarAcao} />;
            case 'aula-trilha':
                return <AulaTrilhaAdmin processando={processando} setProcessando={setProcessando} lista={lista} setLista={setLista} setarAcao={setarAcao} />;
            case 'consultoria':
                return <ConsultoriaAcaoMenuTrilha processando={processando} lista={lista} setarAcao={setarAcao} />;
            case 'short':
                return <ShortsAcaoMenuTrilha processando={processando} lista={lista} setarAcao={setarAcao} />;
            case 'produto-catalogado':
                return <ProdutosCatalogadosAcaoMenuTrilha processando={processando} lista={lista} setarAcao={setarAcao} />;
            case 'marketplace':
                return <MarketplaceAcaoMenuTrilha processando={processando} marketplaces={lista.marketplaces} cursos={lista.cursos} lista={lista} setarAcao={setarAcao} />;
            default:
                return null;
        }
    };

    return (
        <div className='w-1/2 mx-auto p-3 rounded-lg bg-neutral-900 border-neutral-500 border'>
            <div className='w-full flex justify-between items-center mb-5'>
                <p className="text-xs text-neutral-500 text-center whitespace-nowrap">
                    Adicionar ação em "<span className="text-neutral-200">{divisor.titulo}</span>"
                </p>
                {lista ?
                    <button className='w-full text-orange-500 text-xs text-right' onClick={() => voltarAoMenu()}>
                        Voltar
                    </button>
                    :
                    <button className='w-full text-red-500 text-xs text-right' onClick={() => resetAcaoMenu()}>
                        Fechar
                    </button>
                }
            </div>
            {!lista ? (
                <ul className="flex flex-col p-5 divide-y divide-neutral-700 text-md text-white">
                    {opcoes_acoes.map((opcao, index) => (
                        <li key={index} className="">
                            <button
                                className="hover:scale-105 flex items-center gap-3 p-3 w-full hover:bg-neutral-700 transition-transform"
                                onClick={() => handleAdicionarOpcaoAcao(opcao)}
                            >
                                <PlusCircleIcon className="w-5 h-5" />
                                {opcao.titulo}
                            </button>
                        </li>
                    ))}
                </ul>
            ) :
                <>{renderOpcaoAcao()}</>
            }
        </div>
    );
};

export default AcaoMenu;
