import React, { useState } from 'react';

const AulaMasterAcaoMenuTrilha = ({ processando, lista, setarAcao }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Filtra a lista com base no termo de busca
    const filteredLista = lista.filter(option =>
        option.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.descricao.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.curso.titulo.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className=''>
            {/* Campo de pesquisa */}
            <input
                type="text"
                placeholder="Filtar lista..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={processando}
                className="w-full p-2 mb-4 text-xs rounded-md border border-neutral-400 bg-neutral-800 text-neutral-100"
            />

            {/* Grid para exibir os itens filtrados */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 select-none">
                {filteredLista.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => !processando && setarAcao(item._id)}
                        className={`rounded overflow-hidden shadow-lg bg-neutral-900 hover:border-2 cursor-pointer ${processando ? 'pointer-events-none opacity-50' : ''}`}
                    >
                        <video
                            src={`${item.video}`}  // Query string para forçar atualização se necessário
                            className="w-full h-auto aspect-video bg-neutral-500 rounded-t-md"  // Define altura fixa e arredondamento no topo
                            controls  // Exibe controles do vídeo (play, pause, etc.)
                            preload="metadata"  // Carrega apenas metadados para não forçar o download do vídeo completo
                        />
                        <div className="p-4">
                            <p className="text-sm text-neutral-200 font-semibold">
                                {item.titulo.length > 100
                                    ? `${item.titulo.slice(0, 20)}...`
                                    : item.titulo}
                            </p>
                            <p className="text-xs text-neutral-400 mt-1">
                                {item.descricao.length > 100
                                    ? `${item.descricao.slice(0, 50)}...`
                                    : item.descricao}
                            </p>
                            <p className='italic text-xs text-orange-500'>{item.curso?.titulo ?? ''}</p>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default AulaMasterAcaoMenuTrilha;