import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Header from '../../components/header';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid'
import { toast } from 'react-toastify';
import { RectangleGroupIcon } from '@heroicons/react/24/solid'
import mixpanel from 'mixpanel-browser';

const Marketplace = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(false);
    const [marketplaces, setMarketplaces] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [processing, setProcessing] = useState(false);
    const [newTags, setNewTags] = useState('');
    const [disableModal, setDisableModal] = useState(true);
    const [uploadFile, setUploadFile] = useState(null);
    const [contadorMudancas, setContadorMudancas] = useState(0);

    const [marketplace, setMarketplace] = useState({
        capa: '',
        nome: '',
        descricao: '',
        tags: [],
        link: '',
    });

    const [marketplaceErros, setMarketplaceErros] = useState({
        capa: '',
        nome: '',
        descricao: '',
        tags: '',
        link: '',
    });

    // Função para verificar erros
    const validateMarketplace = () => {
        const erros = {
            capa: marketplace.capa ? '' : 'A capa deve ser incluída',
            nome: marketplace.nome ? '' : 'O nome deve ser um texto não vazio',
            descricao: marketplace.descricao ? '' : 'A descrição deve ser um texto não vazio',
            tags: marketplace.tags.length ? '' : 'Você deve incluir pelo menos 1 tag',
            link: marketplace.link ? '' : 'O link deve ser um texto não vazio',
        };

        setMarketplaceErros(erros);
        setDisableModal(Object.values(erros).some(err => err));
    };

    useEffect(() => {
        validateMarketplace();
    }, [marketplace]);

    // Função para buscar a lista de marketplaces
    useEffect(() => {
        const fetchMarketplaces = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/marketplace`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                console.log("RESULT: ", response.data)
                if (response.data) {
                    setMarketplaces(response.data.marketplaces);
                    setCursos(response.data.cursos);
                } else {
                    console.error('Erro ao buscar marketplaces:', response);
                    toast.error("Erro ao capturar os marketplaces. Recarregue a página.");
                }
            } catch (error) {
                console.error('Erro ao buscar marketplaces:', error);
                toast.error("Erro ao capturar os marketplaces. Recarregue a página.");
            }
        };

        fetchMarketplaces();
    }, []);

    const closeModal = () => {
        setMarketplace({
            capa: '',
            nome: '',
            descricao: '',
            tags: [],
            link: '',
        });
        setModalMode('create');
        setShowModal(false);
        setContadorMudancas(contadorMudancas + 1);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMarketplace(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const addTags = () => {
        if (newTags.trim()) {
            setMarketplace(prev => ({
                ...prev,
                tags: [...prev.tags, newTags]
            }));
            setNewTags('');
        }
    };

    const removeTags = (index) => {
        setMarketplace(prev => ({
            ...prev,
            tags: prev.tags.filter((_, i) => i !== index)
        }));
    };

    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        const maxSizeMB = 500; // Limite de 500MB
        const maxSizeBytes = maxSizeMB * 1024 * 1024;

        // Verificar se o arquivo excede o limite de tamanho
        if (selectedFile.size > maxSizeBytes) {
            toast.error(`Arquivo maior que ${maxSizeMB}MB, por favor escolha um arquivo menor.`);
            return;
        }

        // Revogar a URL do objeto anterior para evitar vazamento de memória
        if (marketplace.capa) {
            URL.revokeObjectURL(marketplace.capa);
        }

        // Atualizar o estado do arquivo e do marketplace com a URL do objeto
        setUploadFile(selectedFile);
        setMarketplace(prev => ({
            ...prev,
            capa: URL.createObjectURL(selectedFile), // Atribuir a URL temporária da imagem
        }));
    };


    const getPostUrl = async (marketplaceId) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/marketplace/upload-url`,
                {
                    fileType: uploadFile.type,
                    marketplaceId: marketplaceId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                throw new Error("Erro ao tentar gerar url de upload");
            }
        } catch (error) {
            console.error("Erro ao tentar gerar url de upload: ", error);
            toast.error(`Erro ao tentar gerar url de upload`);
            return null;
        }
    };

    const uploadFileOnStorage = async (uploadUrl) => {
        try {
            const response = await axios.put(uploadUrl, uploadFile, {
                headers: {
                    'Content-Type': uploadFile.type,
                }
            });
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            console.log("Erro ao fazer upload do arquivo: ", error);
            toast.error("Erro ao fazer upload do arquivo")
            return false
        }
    }

    const updateMarketplaceCapaUrl = async (marketplace_id, public_url) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/marketplace/file-url/${marketplace_id}`, { public_url }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.data) {
                const updatedMarketplace = response.data;
                console.log("RESUL: ", response)
                // Atualize a lista de marketplaces
                setMarketplaces(prevMarketplaces =>
                    prevMarketplaces.map(f =>
                        f.id === updatedMarketplace.id ? updatedMarketplace : f
                    )
                );
                return true;
            } else {
                console.log(response)
                toast.error("Erro ao atualizar a url da imagem de capa");
                return false;
            }
        } catch (error) {
            console.error('Erro ao atualizar a url da imagem de capa: ', error);
            toast.error("Erro ao atualizar a url da imagem de capa");
            return false;
        }
    }

    const saveMarketplace = async (method, url, payload) => {
        setProcessing(true);
        try {
            const response = await axios[method](url, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.data) {
                let publicUrl = response.data.capa; // Inicializa a variável publicUrl
                let uploadSuccess = true; // Assume sucesso, mudamos se necessário
                let updateSuccess = true; // Assume sucesso, mudamos se necessário

                // Verificar se o arquivo precisa ser enviado
                if (uploadFile) {
                    // Obter a URL de upload para a imagem de capa
                    const { fileName, uploadUrl } = await getPostUrl(response.data._id);
                    publicUrl = `https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_BUCKET_NAME}/${fileName}`;

                    // Fazer upload da nova imagem de capa
                    uploadSuccess = await uploadFileOnStorage(uploadUrl);
                }

                // Se o upload foi bem-sucedido, atualiza a URL da capa
                if (uploadSuccess) {
                    if (uploadFile) {
                        updateSuccess = await updateMarketplaceCapaUrl(response.data._id, publicUrl);
                    }

                    // Atualiza o marketplace com a nova URL da capa
                    setMarketplace(prev => ({
                        ...prev,
                        capa: publicUrl,
                    }));

                    const novoMarketplace = { ...response.data, capa: publicUrl }

                    setMarketplaces(prev => {
                        if (method === 'post') {
                            return [...marketplaces, novoMarketplace].sort((a, b) => a.nome.localeCompare(b.nome));
                        } else {
                            return marketplaces.map(marketplace =>
                                marketplace._id === response.data._id ? response.data : marketplace
                            );
                        }
                    });

                    toast.success(`Marketplace ${method === 'post' ? 'cadastrado' : 'atualizado'} com sucesso!`);
                    closeModal();
                } else {
                    toast.error(`Erro ao ${method === 'post' ? 'cadastrar' : 'atualizar'} marketplace. Tente novamente.`);
                }
            } else {
                toast.error(`Erro ao ${method === 'post' ? 'cadastrar' : 'atualizar'} marketplace. Tente novamente.`);
            }
        } catch (error) {
            console.error(`Erro ao ${method === 'post' ? 'cadastrar' : 'atualizar'} marketplace:`, error);
            toast.error(`Erro ao ${method === 'post' ? 'cadastrar' : 'atualizar'} marketplace. Tente novamente.`);
        } finally {
            setProcessing(false);
        }
    };

    const handleSave = (marketplace_id = null) => {
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/marketplace${marketplace_id ? `/${marketplace_id}` : ''}`;
        const method = modalMode === 'create' ? 'post' : 'put';
        saveMarketplace(method, url, marketplace);
    };

    const filteredMarketplaces = marketplaces.filter(m =>
        m.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        m.descricao.toLowerCase().includes(searchTerm.toLowerCase()) ||
        m.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const filteredCursos = cursos.filter(m =>
        m.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        m.descricao.toLowerCase().includes(searchTerm.toLowerCase()) ||
        m.funcionalidades.some(funcionalidade => funcionalidade.titulo.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const deletarMarketplace = async (marketplace_id, marketplace_nome) => {
        if (!confirm(`Você tem certeza que deseja excluir o marketplace ${marketplace_nome}?`)) return;
        setProcessing(true);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/marketplace/${marketplace_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.data) {
                setMarketplaces(prev => prev.filter(el => el._id !== marketplace_id));
                toast.success("Marketplace deletado com sucesso!");
            } else {
                toast.error("Erro ao deletar marketplace. Tente novamente.");
            }
        } catch (error) {
            console.error('Erro ao deletar marketplace:', error);
            toast.error("Erro ao deletar marketplace. Tente novamente.");
        } finally {
            setProcessing(false);
        }
    };

    const editarMarketplace = (marketplace_id) => {
        const selectedMarketplace = marketplaces.find(el => el._id === marketplace_id);
        if (selectedMarketplace) {
            setMarketplace(selectedMarketplace);
            setModalMode('update');
            setShowModal(true);
        }
    };

    const excluirCurso = async (curso_id, curso_titulo) => {
        setProcessing(true)
        if (!confirm("Você tem certeza que deseja deletar a automação '" + curso_titulo + "'? Esta ação não pode ser desfeita!")) {
            setProcessing(false)
            return
        }
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/curso/${curso_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.status === 200) {
                console.log(response.data.message);
                setCursos(prevCursos => prevCursos.filter(curso => curso._id !== curso_id));
                toast.success("Automação deletada com sucesso!")
            } else {
                console.log(response)
                toast.error("Erro ao deletar a automação")
            }
        } catch (err) {
            console.error(err);
            if (err.response && err.response.data) {
                console.error(err.response.data.error);
                toast.error("Erro ao deletar a automação")
            } else {
                console.log(err)
                toast.error("Erro ao deletar a automação")
            }
        } finally {
            setProcessing(false)
        }
    };

    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Marketplaces | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    };

    useEffect(() => {
        if (mixpanel) mixPanelAcesso();
    }, [mixpanel]);


    return (
        <div className='w-full'>
            {showModal && (
                <div className="fixed top-0 left-0 w-screen min-h-screen flex justify-center align-middle items-center z-40">
                    <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={closeModal}></div>
                    <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                        <div className="border-b border-neutral-700 py-5 space-y-2 flex justify-between items-center">
                            <img src='/main_logo.png' className='h-7' alt="Logo" />
                            <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={closeModal}>&times;</button>
                        </div>
                        <div className="py-5 flex flex-col gap-5 w-2/3 m-auto">
                            <div className="flex flex-col w-full gap-2">
                                <label
                                    htmlFor="capa"
                                    className="text-xs md:text-sm cursor-pointer p-2 bg-orange-500 text-white rounded-md text-center hover:bg-orange-600 transition"
                                >
                                    Clique para selecionar a Imagem de Capa
                                </label>
                                <input
                                    type="file"
                                    id="capa"
                                    name="capa"
                                    onChange={handleFileUpload}
                                    accept="image/png, image/jpeg"
                                    className="hidden"
                                />
                                {marketplaceErros.capa && (
                                    <p className="text-red-500 text-xs md:text-sm pt-1 pb-2">{marketplaceErros.capa}</p>
                                )}
                                {marketplace.capa && (
                                    <img
                                        src={`${marketplace.capa.includes('blob') ? marketplace.capa : marketplace.capa + '?timestamp=' + new Date().getTime()}`}
                                        alt="Capa do Marketplace"
                                        className="w-1/2 mx-auto rounded-lg aspect-auto"
                                    />
                                )}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="nome" className='text-xs md:text-sm'>Nome</label>
                                <input
                                    type="text"
                                    id="nome"
                                    name="nome"
                                    value={marketplace.nome}
                                    onChange={handleInputChange}
                                    required
                                    placeholder="Indique o nome do marketplace"
                                    autoFocus
                                    className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                                />
                                {marketplaceErros.nome && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{marketplaceErros.nome}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="descricao" className='text-xs md:text-sm'>Descrição</label>
                                <input
                                    type="text"
                                    id="descricao"
                                    name="descricao"
                                    value={marketplace.descricao}
                                    onChange={handleInputChange}
                                    required
                                    placeholder="Indique a descrição do marketplace"
                                    className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                                />
                                {marketplaceErros.descricao && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{marketplaceErros.descricao}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label className='text-xs md:text-sm'>Tags</label>
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        value={newTags}
                                        onChange={(e) => setNewTags(e.target.value)}
                                        placeholder="Adicione uma tag"
                                        className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                                    />
                                    <button
                                        type="button"
                                        onClick={addTags}
                                        className='py-2 px-3 rounded-md text-xs bg-orange-600 hover:bg-orange-700 active:bg-orange-500'
                                    >
                                        Adicionar
                                    </button>
                                </div>
                                {marketplaceErros.tags && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{marketplaceErros.tags}</p>}
                                <ul>
                                    {marketplace.tags.map((tag, index) => (
                                        <li key={index} className='flex justify-between items-center'>
                                            {tag}
                                            <button
                                                type="button"
                                                onClick={() => removeTags(index)}
                                                className='text-red-500 hover:text-red-700 text-xs'
                                            >
                                                Remover
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="link" className='text-xs md:text-sm'>Link {'('}Endereço Url{')'}</label>
                                <input
                                    type="text"
                                    id="link"
                                    name="link"
                                    value={marketplace.link}
                                    onChange={handleInputChange}
                                    required
                                    placeholder="https://www.exemplo.com.br"
                                    className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500'
                                />
                                {marketplaceErros.link && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{marketplaceErros.link}</p>}
                            </div>
                        </div>
                        <div className="border-t border-neutral-700 py-5 flex flex-row justify-end items-center gap-x-3 select-none">
                            <button
                                className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-neutral-600 hover:bg-neutral-700 active:bg-neutral-800"
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                            <button
                                className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs font-semibold md:text-sm bg-orange-600 hover:bg-orange-700 active:bg-orange-500"
                                onClick={() => handleSave(marketplace._id)}
                                disabled={disableModal || processing}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='w-full'>
                <Helmet>
                    <title>Simbium</title>
                    <meta property="og:title" content="Simbium" />
                </Helmet>
                <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                <div className='bg-neutral-900 flex flex-col font-poppins'>
                    <div className='w-full flex flex-row md:px-5 gap-10'>
                        {showMenu &&
                            <>
                                <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                                <div className='hidden w-0 lg:block lg:w-3/12'></div>
                            </>
                        }
                        <div className={`${showMenu ? 'w-full lg:w-9/12' : 'sm:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
                            <div className="flex flex-col w-full bg-neutral-700 rounded-lg text-white overflow-hidden">
                                <div className="bg-opacity-50 bg-center bg-no-repeat bg-cover relative" style={{ backgroundImage: "url('/quadros.jpg')" }}>
                                    <div className='w-full flex flex-col gap-7 p-5 sm:p-10 md:p-14 lg:p-20 items-center bg-orange-950 bg-opacity-50'>
                                        <h1 className="text-4xl md:text-7xl text-center font-poppins font-semibold">Marketplace de Serviços</h1>
                                        <p className='text-xs md:text-lg text-center text-white'>Contrate com nossos parceiros homologados, os mais diversos serviços para acelerar seu negócio. Seja mentoria, estratégias avançadas, implementação de ferramentas, automações, imagens, videos, e demais serviços para acelerar suas vendas!</p>
                                        <div className='flex flex-row gap-2 w-full sm:w-2/3 mx-auto items-center align-middle justify-center'>
                                            <input
                                                type="text"
                                                placeholder="Pesquisar marketplaces..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                className="w-full p-2 md:p-5 rounded-md text-orange-700 focus:border-orange-400 focus:ring-orange-400"
                                            />
                                            {usuarioAtual.tipo && usuarioAtual.tipo === 'admin' &&
                                                <button className='w-14 h-14 bg-white text-orange-500 text-center hover:bg-orange-50 active:bg-orange-100 p-3 rounded-lg flex items-center justify-center' title={"Adicionar favorecido"} onClick={() => setShowModal(true)}>
                                                    <PlusIcon className='w-5 h-5 p-0 m-0' />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='w-11/12 mx-auto my-10'>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
                                        {filteredMarketplaces?.map((marketplace, index) => (
                                            <div key={index} className="bg-neutral-800 rounded-lg overflow-hidden space-y-5">
                                                <div className='h-60 flex flex-col justify-end bg-center bg-cover' style={{ backgroundImage: `url(${marketplace.capa}?contador-mudancas=${contadorMudancas}&time=${(new Date).getMinutes()}` }}>
                                                    <h2 className="text-md lg:text-xl font-medium p-4 bg-gradient-to-t from-neutral-800 to-transparent">{marketplace.nome}</h2>
                                                </div>
                                                <div className='w-full p-4 pt-0 flex flex-col gap-5'>
                                                    <p className='text-wrap text-sm text-neutral-300'>{marketplace.descricao}</p>
                                                    <div className='text-xs'>
                                                        {marketplace.tags?.length && marketplace.tags.map((tag, index) => (
                                                            <div key={index} className='w-fit float-left mr-2 my-1 text-white px-2 py-0.5 rounded-full bg-neutral-500'>{tag}</div>
                                                        ))}
                                                    </div>
                                                    <div className='w-full mx-auto'>
                                                        <a href={marketplace.link || '/'} target='_blank' className="w-full text-white text-center text-sm inline-block bg-orange-600 hover:bg-white hover:text-orange-500 active:bg-neutral-50 active:text-orante-600 px-3 py-1 rounded-full">
                                                            Ver Detalhes
                                                        </a>
                                                    </div>
                                                    <div className='w-full flex justify-between'>
                                                        {usuarioAtual.tipo && usuarioAtual.tipo === 'admin' && (
                                                            <>
                                                                <button className='w-full text-xs text-yellow-500 text-center' onClick={() => editarMarketplace(marketplace._id, marketplace.nome)}>Editar</button>
                                                                <button className='w-full text-xs text-red-500 text-center' onClick={() => deletarMarketplace(marketplace._id, marketplace.nome)}>Excluir</button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {filteredCursos?.map((curso, index) => (
                                            <div key={index} className="bg-neutral-800 rounded-lg overflow-hidden space-y-5">
                                                <div className='h-60 flex flex-col justify-end bg-center bg-cover bg-no-repeat' style={{ backgroundImage: `url(${curso.imagem}?contador-mudancas=${contadorMudancas}&time=${(new Date).getMinutes()}` }}>
                                                    <h2 className="text-md lg:text-xl font-medium p-4 bg-gradient-to-t from-neutral-800 to-transparent">{curso.titulo}</h2>
                                                </div>
                                                <div className='w-full p-4 pt-0 flex flex-col gap-5'>
                                                    <p className='text-wrap text-sm text-neutral-300'>{curso.descricao}</p>
                                                    <div className='text-xs'>
                                                        {curso.funcionalidades?.length && curso.funcionalidades.map((funcionalidade, index) => (
                                                            <div key={index} className='w-fit float-left mr-2 my-1 text-white px-2 py-0.5 rounded-full bg-neutral-500'>{funcionalidade.titulo}</div>
                                                        ))}
                                                    </div>
                                                    <div className='w-full mx-auto'>
                                                        <a href={`/marketplace/servico/${curso.secao.pagina.codigo}/${curso.codigo}`} target='_blank' className="w-full text-white text-center text-sm inline-block bg-orange-600 hover:bg-white hover:text-orange-500 active:bg-neutral-50 active:text-orante-600 px-3 py-1 rounded-full">
                                                            Ver Detalhes
                                                        </a>
                                                    </div>
                                                    <div className='w-full flex justify-between'>
                                                        {usuarioAtual.tipo && usuarioAtual.tipo === 'admin' && (
                                                            <>
                                                                <a onClick={localStorage.setItem('master-pagina', curso.secao.pagina.codigo)} href={`/admin/master-curso/${curso._id}`} target='_blank' className='w-full text-xs text-yellow-500 text-center'>Editar</a>
                                                                <button className='w-full text-xs text-red-500 text-center' onClick={() => excluirCurso(curso._id, curso.titulo)}>Excluir</button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );

};

export default Marketplace;
