import React, { useState, useEffect } from 'react';
import { PlusIcon, ChevronUpIcon, ChevronDownIcon, CheckBadgeIcon, PencilIcon, XMarkIcon, HomeIcon, MinusIcon, ArrowPathIcon, TrashIcon, LinkIcon, LinkSlashIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Provider, useSelector, useDispatch, useStore } from 'react-redux';
import ModalAulaTrilhaAdmin from '../modal_aula_trilha'

const AulaTrilhaAdmin = ({ processando, setProcessando, lista, setLista, setarAcao }) => {
    const dispatch = useDispatch();

    const token = localStorage.getItem("token");
    const axios_header = { headers: { Authorization: `Bearer ${token}` } }
    // const [processando, setProcessando] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [videoAula, setVideoAula] = useState(null);
    const [atualizarVideo, setAtualizarVideo] = useState(Math.floor(Math.random() * 1000));
    const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de pesquisa
    const [uploadingVideo, setUploadingVideo] = useState(false);

    const [aulaTrilha, setAulaTrilha] = useState({
        titulo: "",
        descricao: "",
        video: "",
        video_tipo: "",
    });

    const [aulaTrilhaErrors, setAulaTrilhaErrors] = useState({
        titulo: "",
        descricao: "",
        video: "",
        video_tipo: "",
    });

    const [aulasTrilha, setAulasTrilha] = useState(lista)

    /*const fetchAulaTrilha = async () => {
        setProcessando(true);
        try {
            //console.log("HEADER: ", axios_header);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/aula-trilha/`,
                axios_header
            );
            // console.log("RESPONSE: ", response);
            if (response.status === 200) {
                setAulasTrilha(response.data);
            } else {
                if (response.status !== 404) {
                    console.log("Erro ao carregar a aulas de trilhas: ", response);
                    toast.error("Erro ao carregar a aulas de trilhas");
                }
                setAulasTrilha([]);
            }
        } catch (error) {
            if (error.status !== 404) {
                console.error("Erro ao carregar a aulas de trilhas: ", error);
                toast.error("Erro ao carregar a aulas de trilhas");
            }
            setAulasTrilha([]);
        } finally {
            setProcessando(false);
        }
    };*/

    /*useEffect(() => {
         fetchAulaTrilha();
     }, []);*/

    // Filtrando as aulas pelo título ou descrição
    const filteredAulas = aulasTrilha.filter(aula =>
        aula.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        aula.descricao.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const openModal = (aula_id = null) => {
        if (aula_id) setAulaTrilha(aulasTrilha.find(aula => aula._id === aula_id));
        setShowModal(true);
    }

    const uploadAula = async (uploadUrl) => {
        setUploadingVideo(true)
        try {
            const uploadResponse = await axios.put(uploadUrl, videoAula, {
                headers: {
                    'Content-Type': videoAula.type,
                },
            });
            console.log("RESULTADO DO UPLOAD: ", uploadResponse);
            return uploadResponse.status === 200;
        } catch (error) {
            console.error("Erro ao fazer upload do vídeo:", error);
            return false;
        } finally {
            setUploadingVideo(false)
        }
    };

    const createAula = async () => {
        setProcessando(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/aula-trilha`,
                {
                    titulo: aulaTrilha.titulo,
                    descricao: aulaTrilha.descricao,
                    video_tipo: videoAula?.type ?? null,
                },
                axios_header
            );

            if (response.status === 201) {
                const { novaAula, uploadUrl } = response.data;
                setAulaTrilha(novaAula);
                toast.success("Aula cadastrada com sucesso!");

                if (videoAula && uploadUrl) {
                    toast.info("Fazendo upload do vídeo no Google Storage...");
                    const uploadStatus = await uploadAula(uploadUrl);
                    if (uploadStatus) {
                        toast.success("Upload realizado com sucesso");
                    } else {
                        toast.error("Erro ao fazer upload do vídeo");
                    }
                }

                setAulasTrilha(prevAulasTrilha => [...prevAulasTrilha, { ...novaAula }]);

                closeModal();
            } else {
                toast.error("Erro ao cadastrar uma nova aula");
                console.error("Erro ao cadastrar aula:", response.status);
            }
        } catch (error) {
            toast.error("Erro ao cadastrar uma nova aula");
            console.error("Erro ao cadastrar aula:", error);
        } finally {
            setProcessando(false);
        }
    };

    const updateAula = async () => {
        setProcessando(true);
        try {
            console.log("AULA: ", aulaTrilha);
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/aula-trilha/${aulaTrilha._id}`,
                {
                    titulo: aulaTrilha.titulo,
                    descricao: aulaTrilha.descricao,
                    video_tipo: videoAula?.type ?? null,
                },
                axios_header
            );

            if (response.status === 200) {
                const { aulaAtualizada, uploadUrl } = response.data;
                setAulaTrilha(aulaAtualizada);
                toast.success("Aula atualizada com sucesso!");

                console.log("VIDEO aula: ", videoAula)
                console.log("UPLOAD url: ", uploadUrl)
                if (videoAula && uploadUrl) {
                    toast.info("Fazendo upload do vídeo no Google Storage...");
                    const uploadStatus = await uploadAula(uploadUrl);
                    if (uploadStatus) {
                        toast.success("Upload realizado com sucesso");
                    } else {
                        toast.error("Erro ao fazer upload do vídeo");
                    }
                }

                setAulasTrilha(() =>
                    aulasTrilha.map(aula =>
                        aula._id === aulaAtualizada._id ? { ...aulaAtualizada } : aula
                    )
                );

                closeModal();
            } else {
                toast.error("Erro ao cadastrar uma nova aula");
                console.error("Erro ao cadastrar aula:", response.status);
            }
        } catch (error) {
            toast.error("Erro ao atualizar a aula");
            console.error("Erro ao atualizar aula:", error);
        } finally {
            setProcessando(false);
        }
    };


    const closeModal = () => {
        setShowModal(false);
        setAulaTrilha({
            titulo: "",
            descricao: "",
            video: "",
            video_tipo: "",
        })
        setAulaTrilhaErrors({
            titulo: "",
            descricao: "",
            video: "",
            video_tipo: "",
        })
        setVideoAula(null);
        setAtualizarVideo(atualizarVideo + 1)
    }

    const deletarAulaTrilha = async (aula_id, aula_titulo) => {
        if (!confirm(`Você tem certexa que deseja excluir a aula "${aula_titulo}"? Esta ação é IRREVERSÍVEL!`)) {
            return;
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/aula-trilha/${aula_id}`,
                axios_header
            );

            if (response.status === 200) {
                setLista(lista.filter(item => item._id !== aula_id))
                toast.success(response.data.message)
            } else {
                toast.error("Erro ao deletar a aula");
                console.error("Erro ao deletar aula:", response);
            }
        } catch (error) {
            toast.error("Erro ao deletar a aula");
            console.error("Erro ao deletar aula:", error);
        } finally {
            setProcessando(false);
        }
    }

    return (
        <div>
            {showModal && <ModalAulaTrilhaAdmin
                aula={aulaTrilha}
                setAula={setAulaTrilha}
                aulaErrors={aulaTrilhaErrors}
                setAulaErrors={setAulaTrilhaErrors}
                createAula={createAula}
                updateAula={updateAula}
                closeModal={closeModal}
                processing={processando}
                videoAula={videoAula}
                setVideoAula={setVideoAula}
                uploadingVideo={uploadingVideo}
            />}
            <div className="w-full bg-neutral-900 rounded-lg p-3 space-y-5">
                <h2 className='w-full text-center text-lg text-neutral-100'>Lista de Aulas para Trilhas
                    <button className='flex items-center gap-1 float-right text-orange-500 hover:text-orange-600 active:text-orange-700'
                        disabled={processando}
                        onClick={() => openModal()}>
                        <span><PlusIcon className='w-4 h-4' /></span>
                        <span className='text-xs'>Cadastrar aula</span>
                    </button>
                </h2>

                {/* Campo de pesquisa */}
                <input
                    type="text"
                    placeholder="Pesquisar por título ou descrição..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    disabled={processando}
                    className="w-full p-2 rounded-md border border-neutral-400 bg-neutral-800 text-neutral-100"
                />

                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 overflow-hidden'>
                    {filteredAulas.length > 0 && filteredAulas.map((aula) => (
                        <div
                            key={aula._id}  // Use o ID único da aula como chave, ao invés do índice
                            className="w-full h-full group transition-all  rounded-md border border-neutral-400 bg-neutral-800 flex flex-col gap-3"
                        >
                            {/* Vídeo otimizado */}
                            <video
                                src={`${aula.video}?atualizar=${atualizarVideo}`}  // Query string para forçar atualização se necessário
                                className="w-full h-auto aspect-video bg-neutral-500 rounded-t-md"  // Define altura fixa e arredondamento no topo
                                controls  // Exibe controles do vídeo (play, pause, etc.)
                                preload="metadata"  // Carrega apenas metadados para não forçar o download do vídeo completo
                            />
                            <div className="flex flex-col gap-1 p-5">
                                <p className="text-lg text-neutral-200">{aula.titulo}</p>
                                <p className="text-xs text-neutral-400">{aula.descricao}</p>
                                <div className='w-full hidden group-hover:block mt-5'>
                                    <div className='w-fit mx-auto flex gap-5'>
                                        <button className='text-yellow-500' disabled={processando} onClick={() => openModal(aula._id)} title={`Editar aula '${aula.titulo}'`}>
                                            <PencilIcon className='w-5 h-5' />
                                        </button>

                                        <button className='text-blue-500' disabled={processando} onClick={() => setarAcao(aula._id)} title={`Linkar aula '${aula.titulo}'`}>
                                            <LinkIcon className='w-5 h-5' />
                                        </button>

                                        <button className='text-red-500' disabled={processando} onClick={() => deletarAulaTrilha(aula._id, aula.titulo)} title={`Deletar aula '${aula.titulo}'`}>
                                            <TrashIcon className='w-5 h-5' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <button className='w-full h-full  p-3 text-xs md:text-sm rounded-md border border-orange-400 bg-orange-800 hover:bg-orange-700 active:bg-orange-600 flex items-center justify-center gap-1'
                        disabled={processando} onClick={() => openModal()}
                    >
                        <span><PlusIcon className='w-4 h-4' /></span>
                        <span>Cadastrar aula</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AulaTrilhaAdmin;
