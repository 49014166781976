import React, { useState } from 'react'
import axios from 'axios'
import styles from './add_usuario.module.css'

const PopupAddUser = (props) => {
  const [fotoPerfil, setFotoPerfil] = useState(props.itemPopup?.foto_perfil || '')
  const [tempFotoPerfil, setTempFotoPerfil] = useState('')
  const [nome, setNome] = useState(props.itemPopup?.nome || '')
  const [senha, setSenha] = useState('')
  const [acesso, setAcesso] = useState(new Date(props.itemPopup?.acesso) || '')
  const [email, setEmail] = useState(props.itemPopup?.email || '')
  const [telefone, setTelefone] = useState(props.itemPopup?.telefone || '')
  const [creditos, setCreditos] = useState(props.itemPopup?.creditos || 0)
  const [area, setArea] = useState(props.itemPopup?.area || '')
  const [tipo, setTipo] = useState(props.itemPopup?.tipo || '')
  const diasSemana = ['Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado', 'Domingo'];
  const [horariosDisponiveis, setHorariosDisponiveis] = useState(
    props.itemPopup?.horariosDisponiveis.length > 0 ? props.itemPopup.horariosDisponiveis : diasSemana.map(dia => (
      { diaSemana: dia, horarios: [] }
    ))
  );

  const uploadFile = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      try {
        const userId = props.itemPopup?._id;
        // Request an upload URL from your backend
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/upload`,
          { userId: userId, fileType: selectedFile.type },
          { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );
        const { uploadUrl, publicUrl } = response.data;

        // Validate response
        if (!uploadUrl || !publicUrl) {
          throw new Error('Invalid response from upload endpoint');
        }

        // Upload the file to the provided URL
        await axios.put(uploadUrl, selectedFile, {
          headers: {
            'Content-Type': selectedFile.type
          }
        });

        // Update the URL of the profile picture
        setFotoPerfil(publicUrl);
      } catch (err) {
        // Handle errors and avoid circular structures
        console.error('Erro ao fazer upload da foto:', err.message || err);

        if (err.response) {
          console.error('Resposta do servidor:', err.response.data);
        }
      }
    }
  };



  const salvarUsuario = async () => {
    try {
      let userData = {}
      if (nome !== '') userData.nome = nome;
      if (telefone !== '') userData.telefone = telefone;
      if (fotoPerfil !== '') userData.foto_perfil = fotoPerfil;
      if (senha !== '') userData.senha = senha;
      if (acesso !== '') {
        let date = new Date(acesso)
        date.setHours(date.getHours() + 3)
        userData.acesso = date;
      }
      if (email !== '') userData.email = email;
      if (creditos !== '' && !isNaN(Number(creditos))) userData.creditos = Number(creditos)
      if (tipo !== '') userData.tipo = tipo;
      if (area !== '') userData.area = area;
      if (horariosDisponiveis.some(h => h.horarios.length > 0)) userData.horariosDisponiveis = horariosDisponiveis
      if (props.itemPopup?._id) userData._id = props.itemPopup._id

      if (tempFotoPerfil) {
        // Upload the image and get the final URL
        const uploadedUrl = await uploadFile(tempFotoPerfil);
        if (uploadedUrl) {
          userData.foto_perfil = uploadedUrl;
        } else {
          throw new Error('Erro ao fazer upload da foto.');
        }
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios`, userData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.status >= 200 && response.status < 300) {
        props.getUsuarios(); // Atualiza a lista de usuários
        props.sucesso();     // Executa a função de sucesso
        props.fecharPopup(); // Fecha o popup
      } else {
        props.erro(response.data.error || 'Erro desconhecido');
      }

    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Erro desconhecido';
      console.log("ERRO: ", err);
      props.erro(errorMessage);
      props.fecharPopup();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Set temporary URL for preview
      const objectUrl = URL.createObjectURL(file);
      setTempFotoPerfil(file);
      setFotoPerfil(objectUrl);
    }
  };

  const roundToNearestHalfHour = (time) => {
    const parts = time.split(':');
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);

    const roundedMinutes = Math.ceil(minutes / 30) * 30;

    if (roundedMinutes === 60) {
      if (hours === 23) {
        return '00:00';
      }
      return `${(hours + 1).toString().padStart(2, '0')}:00`;
    }

    return `${hours.toString().padStart(2, '0')}:${roundedMinutes.toString().padStart(2, '0')}`;
  }

  const handleHorarioChange = (diaIndex, horIndex, value) => {
    setHorariosDisponiveis(prevState => {
      const newState = [...prevState];
      newState[diaIndex].horarios[horIndex] = value;
      return newState;
    });
  };

  const handleAddHorario = (diaIndex) => {
    setHorariosDisponiveis(prevState => {
      const newState = [...prevState];
      newState[diaIndex].horarios.push('');
      return newState;
    });
  };

  const handleRemoveHorario = (diaIndex, horIndex) => {
    setHorariosDisponiveis(prevState => {
      const newState = [...prevState];
      newState[diaIndex].horarios.splice(horIndex, 1);
      return newState;
    });
  };


  return (
    <div className={styles['container']}>
      <div className={styles['container1']}>
        <div className={styles['head']}>
          <span className={styles['text']}>Adicionar Usuário</span>
          <div className={styles['fechar-popup']} onClick={props.fecharPopup}>
            <svg
              viewBox="0 0 804.5714285714286 1024"
              className={styles['icon']}
            >
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          </div>
        </div>
        <div className={styles['opcoes']}>
          <div className={styles['form-input-grp']}>
            <div className={styles['container3']} onClick={() => document.getElementById("file-input").click()}>
              <span className={styles['label1']}>Foto</span>
              <div className={styles['container4']}>
                <img
                  alt="image"
                  src={fotoPerfil ? fotoPerfil + `?timestamp=${new Date().getTime()}` : '../default-img.svg'}
                  className={styles['image']}
                />
                <div className={styles['fechar-popup1']} id="upload-file">
                  <label>
                    <svg
                      viewBox="0 0 804.5714285714286 1024"
                      className={styles['icon2']}
                    >
                      <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                    </svg>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/jpeg, image/png, image/gif"
                    onChange={(e) => uploadFile(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles['form-input-grp1']}>
            <span className={styles['text02']}>Nome</span>
            <input
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              className={styles['input-dark']}
            />
          </div>
          <div className={styles['form-input-grp2']}>
            <span className={styles['text03']}>E-mail</span>
            <input
              type="text"
              className={styles['input-dark']}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles['form-input-grp2']}>
            <span className={styles['text03']}>Telefone</span>
            <input
              type="text"
              className={styles['input-dark']}
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            />
          </div>
          <div className={styles['form-input-grp3']}>
            <span className={styles['text04']}>Senha</span>
            <input
              type="password"
              onChange={(e) => setSenha(e.target.value)}
              value={senha}
              className={styles['input-dark']}
            />
          </div>
          <div className={styles['form-input-grp4']}>
            <span className={styles['text05']}>Acesso</span>
            <input
              type="date"
              onChange={(e) => setAcesso(e.target.value)}
              value={acesso}
              className={styles['input-dark']}
            />
          </div>
          <div className={styles['form-input-grp5']}>
            <span className={styles['text06']}>Créditos</span>
            <input
              type="text"
              value={creditos}
              onChange={(e) => setCreditos(e.target.value)}
              className={styles['input-dark']}
            />
          </div>
          <div className={styles['form-input-grp6']}>
            <span className={styles['text07']}>Tipo</span>
            <select
              onChange={(e) => setTipo(e.target.value)}
              className={styles['dropdown']}
              value={tipo}
            >
              <option value="usuario">
                Usuário
              </option>
              <option value="professor">Professor</option>
              <option value="consultor">Consultor</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          {(tipo === 'professor' || tipo === 'consultor') && (
            <div className={styles['form-input-grp5']}>
              <span className={styles['text06']}>Área</span>
              <input
                type="text"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                className={styles['input-dark']}
              />
            </div>
          )}
          {tipo === 'consultor' && (
            <div className={styles['form-input-grp7']}>
              <span className={styles['text08']}>Horários Disponíveis</span>
              <div className="horariosDisponiveis">{
                horariosDisponiveis.map((dia, diaIndex) => (
                  <div key={dia.diaSemana}>
                    <h3>{dia.diaSemana}</h3>
                    {
                      dia.horarios.map((horario, horIndex) => (
                        <div key={`${dia.diaSemana}-${horIndex}`}>
                          <input
                            type="time"
                            value={horario}
                            onChange={(e) => handleHorarioChange(diaIndex, horIndex, roundToNearestHalfHour(e.target.value))}
                            className={styles['input-dark']}
                          />
                          <button className="remove-horaario" onClick={() => handleRemoveHorario(diaIndex, horIndex)}>
                            <svg viewBox="0 0 804.5714285714286 1024" className="QoglVag_qTMk6JV0ANB4"><path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path></svg>
                          </button>
                        </div>
                      ))
                    }
                    <button onClick={() => handleAddHorario(diaIndex)}>Adicionar horário</button>
                  </div>
                ))
              }</div>
            </div>
          )}
        </div>
        <div className={styles['head1']}>
          <div className={styles['b-salvar']} onClick={props.fecharPopup}>
            <span className={styles['text09']}>Cancelar</span>
          </div>
          <button className={styles['b-salvar1']} disabled={nome === '' || email === '' || (senha === '' && !props.itemPopup?._id)} onClick={salvarUsuario}>
            <span className={styles['text10']}>Salvar Usuário</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default PopupAddUser
