import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';

const ModalCompraCotas = ({ setMostrarModalCompraCotas }) => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [disableAll, setDisableAll] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [loading, setLoading] = useState(false);

    const packages = [
        { code: 1, description: "Pacote com 4 Cotas", amount: 32000, quantity: 1 },
        { code: 2, description: "Pacote com 8 Cotas", amount: 56000, quantity: 1 },
        { code: 3, description: "Pacote com 10 Cotas", amount: 60000, quantity: 1 }
    ];

    const irParaCheckout = async () => {
        if (!selectedPackage) return toast.error("Selecione um pacote para continuar.");
        setLoading(true);

        try {
            const installments = [{ number: 1, total: selectedPackage.amount }]; // Configuração de parcelas básicas

            const { status, data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/pagarme/cotas/checkout`,
                { userId: usuarioAtual?._id, item: selectedPackage, installments },
                { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
            );

            if (status === 200 && data.url) {
                window.location.replace(data.url);
            } else {
                throw new Error(data.message || "Erro ao acessar o checkout");
            }
        } catch (error) {
            toast.error(error.response?.data?.error || "Erro desconhecido ao acessar o checkout.");
        } finally {
            setSelectedPackage(null);
            setLoading(false);
        }
    };


    const formatMoney = (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value / 100);

    return (
        <div className="fixed z-50 top-0 left-0 w-screen h-screen flex items-center justify-center select-none">
            <div className="absolute top-0 left-0 w-screen h-screen bg-neutral-950 opacity-95" onClick={() => setMostrarModalCompraCotas(false)} />
            <div className="absolute w-11/12 md:w-2/3 xl:w-1/2 max-w-7xl mx-auto rounded shadow-lg">
                <div className="space-y p-5 w-full flex relative flex-col pb-8 justify-start bg-[#212121] rounded-xl overflow-hidden max-h-[90vh] overflow-y-auto">
                    <p className="text-2xl text-neutral-50 font-bold border-neutral-500 pb-5 border-b flex justify-between items-center">
                        Cotas para Consultorias
                        <button className="text-neutral-300 hover:text-neutral-50 active:text-orange-500" onClick={() => setMostrarModalCompraCotas(false)}>
                            <XMarkIcon className="w-5 h-5" />
                        </button>
                    </p>

                    <div className="flex flex-row gap-5 items-center justify-center m-5">
                        {packages.map((pkg) => (
                            <div key={pkg.code} className="w-full text-center rounded-md overflow-hidden border border-neutral-700 bg-neutral-900">
                                <div className="text-neutral-200 text-xs space-y-2 p-3">
                                    <p className="text-md font-bold text-neutral-300">{pkg.description}</p>
                                    <p className="text-lg">{formatMoney(pkg.amount)}</p>
                                </div>
                                <button
                                    className={`w-full ${selectedPackage?.code === pkg.code ? 'bg-white text-yellow-500 font-bold' : 'bg-neutral-950'} 
                                    hover:bg-orange-500 hover:text-white active:bg-white active:text-yellow-500 py-2 px-3 text-xs`}
                                    onClick={() => setSelectedPackage(pkg)}
                                >
                                    {selectedPackage?.code === pkg.code ? 'Selecionado' : 'Selecionar'}
                                </button>
                            </div>
                        ))}
                    </div>

                    <div className="border-neutral-500 pt-5 space-x-5 border-t text-end">
                        <button className="text-sm text-neutral-50 hover:bg-neutral-500 active:bg-orange-500 p-2 rounded-md" onClick={() => setMostrarModalCompraCotas(false)}>Fechar</button>
                        <button className="text-sm text-neutral-50 bg-neutral-700 hover:bg-orange-500 active:bg-white active:text-yellow-500 p-2 rounded-md" onClick={irParaCheckout} disabled={!selectedPackage || disableAll || loading}>Ir para o checkout</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCompraCotas;
