import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ConteudosHome = (props) => {
    const navigate = useNavigate();
    const plano_aviao = process.env.REACT_APP_PLANO_AVIAO;
    const plano_jato = process.env.REACT_APP_PLANO_JATO;
    const plano_foguete = process.env.REACT_APP_PLANO_FOGUETE;

    const [emBrevePath, setEmBrevePath] = useState(null);
    const [desativarConteudos, setDesativarConteudos] = useState(
        props.usuarioAtual.tipo && props.usuarioAtual.tipo === 'guest'
            ? false
            : (props.usuarioAtual?.ref?.plano === plano_aviao || props.usuarioAtual?.ref?.plano === plano_jato)
    );

    const menu = [
        {
            titulo: 'Painel BI',
            codigo: 'painel-bi',
            img_url: '/home-clean/clean-backgrounds/painel-bi.png',
            link: '/master/painel-bi',
            url_tracker: '/painel-bi',
            description: 'Dados integrados para acompanhar o seu crescimento!',
            em_breve: true,
        },
        {
            titulo: 'Conteúdo de Especialistas',
            codigo: 'conteudo-de-especialista',
            img_url: '/home-clean/clean-backgrounds/conteudo-especialistas.png',
            link: '/master/conteudos-especialistas',
            url_tracker: '/conteudos-especialistas',
            description: 'Em breve você terá acesso a conteúdos dos principais nomes do mercado direto para você. Dicas, aulas especiais e muito conteúdo único!',
            em_breve: true,
        },
        {
            titulo: 'Histórias de sucesso',
            codigo: 'historias-de-sucesso',
            img_url: '/home-clean/clean-backgrounds/be-a-ba-automacao.png',
            link: '/master/sugestoes-leitura',
            url_tracker: '/sugestoes-leitura',
            description: 'Conheça a história de lojas que conseguiram alcançar resultados surpreendentes seja em marketplaces e/ou em seus próprios ecommerces.',
            em_breve: true,
        },
        {
            titulo: 'IA da Simbium',
            codigo: 'ia-da-simbium',
            img_url: '/home-clean/clean-backgrounds/ia.png',
            link: '/ia',
            url_tracker: '/ia',
            description: 'Todo poder da IA 100% acionável para você criar automações e descobrir novas formas de levar seu negócio a um outro nível, ganhando ainda mais vantagem competitiva sob a concorrência.',
            em_breve: true,
        },
        {
            titulo: 'Clube de Benefícios',
            codigo: 'clube-de-beneficios',
            img_url: '/home-clean/clean-backgrounds/clube-beneficios.png',
            link: '/em-breve',
            url_tracker: '/clube-beneficios',
            description: 'Junte-se ao Clube de Benefícios e abra as portas para um mundo de vantagens. Faça parte deste seleto grupo e aproveite descontos especiais, ofertas únicas e experiências premium direto de parceiros exclusivos.',
            em_breve: true,
        },
    ];

    const handleContentClick = (pagina) => {
        if (!desativarConteudos) {
            if (pagina.link === '') {
                setAgendamentoPopupVisible(true);
            } else if (pagina.link.includes('http')) {
                window.open(pagina.link, '_blank');
            } else if (!pagina.em_breve) {
                navigate(pagina.link);
            } else {
                setEmBrevePath(pagina);
                // Altera a URL sem redirecionar
                navigate({}, '', `/em-breve${pagina.url_tracker}`);
                /**/
                const element = document.querySelector(`#info-conteudo`);
                if (element) {
                    console.log('OH YEAH: ', element)
                    window.scrollTo({
                        top: element.offsetTop - 100,
                        behavior: 'smooth',
                    });
                }
            }
        } else {
            console.log("Indisponível no plano atual");
        }
    };

    const handleCloseInfo = () => {
        setEmBrevePath(null);
        // Altera a URL sem redirecionar
        navigate({}, '', '/');
        /*
        const element = document.querySelector(`#lista-conteudos`);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 100,
                behavior: 'smooth',
            });
        }
        */
    };

    return (
        <div className='w-full lg:w-11/12 mx-auto space-y-5'>
            <h2 className='font-bold text-lg text-white text-center sm:text-left'>Conteúdos Exclusivos
                {desativarConteudos && <span className='text-xs text-orange-500'> Acesso apenas para <strong>assinantes do PLANO FOGUETE</strong>!</span>}
            </h2>
            {emBrevePath ? (
                <section id="info-conteudo" className='rounded-lg bg-neutral-950 text-white grid grid-cols-1 sm:grid-cols-2 overflow-hidden shadow-lg shadow-neutral-950'>
                    <div className='relative w-full h-[30vw] sm:h-auto lg:min-h-[20vw] bg-cover bg-center' style={{ backgroundImage: `url(${emBrevePath.img_url})` }}>
                        <div className='absolute w-full h-full bg-neutral-950 bg-opacity-50 flex items-center align-middle justify-center'>
                            <p className='text-xl md:text-4xl text-white font-semibold'>{emBrevePath.titulo}</p>
                        </div>
                    </div>
                    <div className='w-full h-fit space-y-3 p-5'>
                        <span className='flex justify-between text-xs text-neutral-800 select-none'>
                            <p className=''>Descrição</p>
                            <p className='text-lg hover:text-xl text-neutral-400 cursor-pointer' onClick={props.usuarioAtual.tipo !== 'guest' ? handleCloseInfo : null}>&times;</p>
                        </span>
                        <p className='text-md md:text-lg font-bold whitespace-pre-wrap font-sans'>{emBrevePath.description}</p>
                        <button className='text-xs sm:text-md select-none rounded-lg text-orange-500 hover:text-white active:text-neutral-500' onClick={props.usuarioAtual.tipo !== 'guest' ? handleCloseInfo : null}>Fechar info</button >
                    </div>
                </section>
            ) :
                <section id="info-conteudo" className='rounded-lg bg-neutral-950 text-white grid grid-cols-1 sm:grid-cols-2 overflow-hidden shadow-lg shadow-neutral-950'></section>
            }

            <section id="lista-conteudos" className="flex flex-col bg-neutral-950 rounded-lg pb-5">
                <div className='w-11/12 mx-auto select-none'>
                    <div className='grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-3 2xl:grid-cols-4 2xl:gap-4 mt-7'>
                        {menu.map((pagina, index_pagina) => (
                            <div className={`p-2 h-[60vw] text-center sm:h-[45vw] md:h-[30vw] 2xl:h-[25vw] relative bg-neutral-800 rounded-lg overflow-hidden transform transition-transform duration-100 ease-in-out ${pagina.em_breve || desativarConteudos ? 'cursor-pointer' : 'cursor-pointer'} hover:scale-105 active:hover:scale-95 hover:border-orange-500 border-2 border-gray-800 bg-cover bg-no-repeat bg-center`}
                                style={{ backgroundImage: `url('${pagina.img_url}')` }}
                                key={index_pagina}
                                title={pagina.titulo}
                                onClick={() => props.usuarioAtual.tipo !== 'guest' ? handleContentClick(pagina) : null}
                            >
                                <div className={`absolute inset-0 bg-black opacity-70 z-0`}></div>
                                <div className='h-full m-auto p-2 relative text-gray-200 text-center font-extrabold flex items-center justify-center text-md md:text-sm lg:text-3xl'>{pagina.titulo}</div>
                                <span className={`w-full ${pagina.em_breve ? 'p-2' : ''} text-center bg-neutral-800 absolute bottom-0 inset-x-0 text-white text-xs sm:text-sm md:text-md lg:text-lg`}>{pagina.em_breve ? 'Em breve' : ''}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ConteudosHome;
