import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Footer from '../../../components/footer'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../components/header';
import Menu from '../../../components/menu';

import VideoPlayer from './components/video-player';
import CourseMenu from './components/course-menu';
import SectionContent from './components/section-content';
import InfoSection from './components/info-section';

const AulaMaster = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const params = useParams();

    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const token = localStorage.getItem("token");

    const header_geral = { Authorization: `Bearer ${token}` }
    const [pagina, setPagina] = useState({});
    const [secao, setSecao] = useState({});
    const [secoes, setSecoes] = useState([]);
    const [curso, setCurso] = useState({});
    const [cursos, setCursos] = useState([]);
    const [aula, setAula] = useState({});
    const [aulas, setAulas] = useState([]);

    const [tipoVideoAula, setTipoVideoAula] = useState('cloud');
    const [cursoSelecionado, setCursoSelecionado] = useState('');

    const [showMenu, setShowMenu] = useState(false);
    const [listarAulas, setListarAulas] = useState(true);
    const [listarCursos, setListarCursos] = useState(true);

    const [paginaCodigo, setPaginaCodigo] = useState('');
    const [secaoCodigo, setSecaoCodigo] = useState('');
    const [cursoCodigo, setCursoCodigo] = useState('');
    const [aulaCodigo, setAulaCodigo] = useState('');


    const setAllData = (data) => {
        const { pagina, secoes, cursos, secaoAtual, cursoAtual, aulas } = data;

        setPaginaCodigo(params.paginaCodigo);
        setSecaoCodigo(params.secaoCodigo);
        setCursoCodigo(params.cursoCodigo);
        setAulaCodigo(params.aulaCodigo);

        setPagina(pagina || null);
        setSecoes(secoes || []);
        setCursos(cursos || []);
        setSecao(secaoAtual || null);
        setCurso(cursoAtual || null);
        setCursoSelecionado(cursoAtual?._id || '');
        setAulas(aulas || []);

        const aulaAtiva = params.aulaCodigo
            ? aulas?.find(aula => aula.codigo === params.aulaCodigo)
            : aulas?.[0] || null;

        if (aulaAtiva) {
            setAula(aulaAtiva);
            setAulaCodigo(aulaAtiva.codigo);
        }

        if (aulas?.length > 0) {
            const videoType = aulas[0].video.includes('youtube.com') ? 'youtube' : 'cloud';
            setTipoVideoAula(videoType);
        }

        if (cursoAtual) {
            mixPanelAcesso();
        }
    };

    const updateData = () => {
        let data = null;

        if (secaoCodigo !== params.secaoCodigo) {

            const novaSecao = secoes?.find(secao => secao.codigo === params.secaoCodigo);
            const novoCurso = novaSecao?.cursos?.[0] || {};
            data = {
                pagina,
                secoes,
                secaoAtual: novaSecao || {},
                cursos: novaSecao?.cursos || [],
                cursoAtual: novoCurso,
                aulas: novoCurso?.aulas || [],
            };
        } else if (cursoCodigo !== params.cursoCodigo) {

            let novoCurso = cursos?.find(curso => curso.codigo === params.cursoCodigo);
            let novaSecao = secao;

            if (!novoCurso) {
                novaSecao = secoes?.find(secao => secao.cursos?.some(curso => curso.codigo === params.cursoCodigo));
                novoCurso = novaSecao?.cursos?.find(curso => curso.codigo === params.cursoCodigo);
            }

            data = {
                pagina,
                secoes,
                secaoAtual: novaSecao || secao,
                cursos: cursos,
                cursoAtual: novoCurso || {},
                aulas: novoCurso?.aulas || [],
            };
        } else {
            const novaAula = aulas?.find(aula => aula.codigo === params.aulaCodigo) || {};
            if (!novaAula) {
                let novoCurso = cursos?.find(curso => curso.codigo === params.cursoCodigo);
                let novaSecao = secao;

                if (!novoCurso) {
                    novaSecao = secoes?.find(secao => secao.cursos?.some(curso => curso.codigo === params.cursoCodigo));
                    novoCurso = novaSecao?.cursos?.find(curso => curso.codigo === params.cursoCodigo);
                }
            }
            data = {
                pagina,
                secoes,
                secaoAtual: secao,
                cursos,
                cursoAtual: curso,
                aulas: aulas,
            };
        }
        console.log('OI 4')
        setAllData(data);
    };



    const fetchData = async () => {
        if (!params || (!params.aulaCodigo && !params.cursoCodigo && !params.paginaCodigo)) {
            toast.error("Nenhuma Disciplina, Módulo ou Aula informados");
            return;
        }

        if (paginaCodigo === params.paginaCodigo) {
            updateData();
            return;
        }

        const url_busca = params.aulaCodigo ? `aula/${params.aulaCodigo}` : (params.cursoCodigo ? `curso/${params.cursoCodigo}` : `pagina/${params.paginaCodigo}`);

        try {
            const fetchUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/master/learn/${url_busca}`;
            const { status, data } = await axios.get(fetchUrl, { headers: header_geral });

            if (status !== 200) return;
            setAllData(data)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [params]);


    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track(`Aula | Acessada`, {
            distinct_id: usuarioAtual._id,
            aula: aula.titulo,
            aulaCodigo: aula.codigo,
            aula_id: aula._id,
            curso: curso.titulo,
            cursoCodigo: curso.codigo,
            curso_id: curso._id,
            secao: secao.titulo,
            secao_codigo: secao.codigo,
            secao_id: secao._id,
        });
    }

    const parseDescricao = (descricao) => {
        if (!descricao) return '';

        // Regex para detectar menções (@username) e links (http:// ou https://)
        const regex = /(@[a-zA-Z0-9._]+|https?:\/\/[^\s]+)/g;

        // Substituir as menções e links pela versão com <a>
        return descricao.split(regex).map((part, index) => {
            if (part.startsWith('@')) {
                // É uma menção de Instagram, cria um link para instagram.com
                return (
                    <a
                        key={index}
                        href={`https://www.instagram.com/${part.substring(1)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-500 underline"
                    >
                        {part}
                    </a>
                );
            } else if (part.startsWith('http')) {
                // É um link, abre em uma nova janela
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-500 underline"
                    >
                        {part}
                    </a>
                );
            }
            // Se não for um link ou uma menção, apenas retorna a parte normal do texto
            return part;
        });
    };

    return (
        <>
            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            <div className='bg-neutral-900 -mt-4 flex flex-col font-poppins'>
                <div className='w-full flex flex-row gap-10'>
                    {showMenu &&
                        <>
                            <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                            <div className='hidden w-0 lg:block lg:w-3/12'></div>
                        </>
                    }
                    <div className={`${showMenu ? 'lg:w-9/12 mx-auto' : ''} w-full  text-neutral-50`}>
                        <div className="w-full shadow shadow-neutral-950 overflow-hidden bg-neutral-900 flex flex-col lg:flex-row">
                            <div className={`w-full h-auto max-h-[80vh] ${listarCursos ? 'lg:w-3/4' : ''}`}>
                                <div className='relative w-full h-full bg-neutral-950'>
                                    <VideoPlayer
                                        paginaCodigo={paginaCodigo}
                                        cursos={cursos}
                                        curso={curso}
                                        aulas={aulas}
                                        aula={aula}
                                        tipoVideoAula={tipoVideoAula}
                                        listarCursos={listarCursos}
                                        setListarCursos={setListarCursos}
                                    />
                                </div>
                            </div>
                        </div>
                        {listarCursos &&
                            <CourseMenu
                                paginaCodigo={paginaCodigo}
                                cursoSelecionado={cursoSelecionado}
                                cursos={cursos}
                                setCursoSelecionado={setCursoSelecionado}
                                aulas={aulas}
                                aulaCodigo={aulaCodigo}
                                listarAulas={listarAulas}
                                aula={aula}
                                showMenu={showMenu}
                                setListarAulas={setListarAulas}
                                setListarCursos={setListarCursos}
                            />
                        }
                        <InfoSection
                            pagina={pagina}
                            secao={secao}
                            curso={curso}
                            aula={aula}
                            listarCursos={listarCursos}
                            parseDescricao={parseDescricao}
                        />
                        <SectionContent
                            paginaCodigo={paginaCodigo}
                            secaoCodigo={secao.codigo}
                            secoes={secoes}
                            listarCursos={listarCursos}
                            parseDescricao={parseDescricao}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AulaMaster;