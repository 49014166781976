import React from 'react';
import CourseListSectionContent from './course-list-section-content';
import { FireIcon } from '@heroicons/react/24/solid';

const SectionContent = ({ paginaCodigo, secaoCodigo, secoes, listarCursos, parseDescricao }) => (
    <div className={`w-full bg-neutral-900 ${listarCursos ? 'lg:w-3/4' : ''}`}>
        <div className='m-5 p-5 bg-neutral-950 rounded-lg shadow-sm shadow-neutral-950 divide-y divide-neutral-900'>
            <p className='py-5 text-yellow-500 font-poppins font-extrabold text-center text-lg flex gap-3 items-center align-middle justify-center'>
                <span><FireIcon className='w-4 h-5' /></span>
                Outros cursos
                <span><FireIcon className='w-4 h-5' /></span>
            </p>
            <div className='space-y-10 p-5'>
                {secoes?.filter(secao => secao.codigo !== secaoCodigo).map((secao, index) => (
                    <div key={index} className='space-y-5'>
                        <p className='text-lg text-neutral-200'>{secao.titulo}</p>
                        <p className='text-xs text-neutral-400'>{parseDescricao(secao.descricao)}</p>

                        <CourseListSectionContent
                            paginaCodigo={paginaCodigo}
                            cursos={secao.cursos}
                        />
                    </div>
                ))}
            </div>
        </div>
    </div >
);

export default SectionContent;
