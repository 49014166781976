import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { BanknotesIcon, TruckIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';

const EmprestimoSimbium = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(false);
    const [sendContact, setSendContact] = useState(false);

    const sendEmail = () => {
        setSendContact(true);
        console.log(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`);

        axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`,
            {
                recipientName: usuarioAtual.name,
                recipientEmail: usuarioAtual.email,
                subject: 'TENHO INTERESE NO EMPRESTIMO SIMBIUM',
                htmlContent: `Nome: ${usuarioAtual.nome}<br>Email: ${usuarioAtual.email}<br>Telefone: ${usuarioAtual.telefone || "Não possui"}`
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        )
            .then(response => {
                console.log("RESPOSTA DO SERVIDOR: ", response);
                toast.success('E-mail enviado com sucesso!');
            })
            .catch(error => {
                console.log("RESPOSTA DO SERVIDOR: ", error);
                toast.error('Erro ao enviar e-mail. Por favor, tente novamente mais tarde');
                setSendContact(false);
            });
    }

    return (
        <div className='w-full'>
            <Helmet>
                <title>Simbium</title>
                <meta property="og:title" content="Simbium" />
            </Helmet>
            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            <div className='bg-neutral-900 flex flex-col font-poppins'>
                <div className='w-full flex flex-row md:px-5 gap-10'>
                    {showMenu &&
                        <>
                            <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                            <div className='hidden w-0 lg:block lg:w-3/12'></div>
                        </>
                    }
                    <div className={`${showMenu ? 'w-full lg:w-9/12' : 'sm:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
                        <div className="flex flex-col w-full bg-orange-700 rounded-lg text-white">
                            {/* Hero Section */}
                            <div className="text-center py-10 text-neutral-50 w-10/12 mx-auto">
                                <h1 className="text-xl md:text-3xl lg:text-5xl font-extrabold mb-4 text-white leading-relaxed">
                                    Mais produtos para vender em seu Estoque com Empréstimo direto na plataforma
                                </h1>
                            </div>

                            {/* Video Section
                            <div className="flex justify-center md:mb-5 lg:mb-10">
                                <div className="w-full md:w-3/4 ">
                                    <div className="aspect-w-16 aspect-h-9 bg-gray-800 flex items-center justify-center overflow-hidden shadow-xl shadow-orange-950">
                                        <video controls className="w-full h-full object-cover">
                                            <source src="caminho-do-video.mp4" type="video/mp4" />
                                            Seu navegador não suporta o elemento de vídeo.
                                        </video>
                                    </div>
                                </div>
                            </div>
                            */}

                            {/* Description Section */}
                            <div className="text-center bg-neutral-950 p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-32 flex flex-col gap-12 md:gap-24">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold">
                                    Acesso a crédito rápido e descomplicado para impulsionar seu negócio. E o melhor: tudo isso sem burocracia e com taxas competitivas.
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 xl:gap-20 select-none">
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z" /></svg>
                                        <p className="text-lg">Aumente seu estoque para atender à demanda dos clientes</p>
                                    </div>
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="M360-320q33 0 56.5-23.5T440-400q0-33-23.5-56.5T360-480q-33 0-56.5 23.5T280-400q0 33 23.5 56.5T360-320Zm240 0q33 0 56.5-23.5T680-400q0-33-23.5-56.5T600-480q-33 0-56.5 23.5T520-400q0 33 23.5 56.5T600-320ZM480-520q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520Zm0 440q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                        <p className="text-lg">Importe produtos de forma rápida e fácil com nosso conteiner compartilhado</p>
                                    </div>
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="m320-240 160-122 160 122-60-198 160-114H544l-64-208-64 208H220l160 114-60 198ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                        <p className="text-lg">Compre com os nossos fornecedores homologados com condições especiais</p>
                                    </div>
                                </div>
                                <p className="text-lg md:text-xl lg:text-2xl mt-5 font-bold">
                                    Você pode importar produtos pela plataforma ou adquiri-los de nossos fornecedores homologados.
                                </p>
                            </div>

                            {/* Benefits Section */}
                            <div className="flex flex-col items-center gap-12 md:gap-24 bg-neutral-800 p-5 py-10 lg:p-20 lg:py-24 shadow-md text-neutral-50">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-center">
                                    Vantagens do empréstimo direto na plataforma:
                                </h2>
                                <ul className="flex flex-col gap-5 text-xs md:text-lg lg:text-xl divide-y divide-neutral-700 text-neutral-300 w-11/12 mx-auto">
                                    <li className="flex flex-col items-center md:flex-row md:items-start gap-5 pb-5">
                                        <span><CheckBadgeIcon className='w-10 h-10 md:w-7 md:h-7 text-yellow-500' /></span>
                                        <strong className='whitespace-nowrap'>Empréstimo rápido e fácil:</strong> Sem burocracia e específico para ampliar seu estoque
                                    </li>
                                    <li className="flex flex-col items-center md:flex-row md:items-start gap-5 pt-10 pb-5">
                                        <span><CheckBadgeIcon className='w-10 h-10 md:w-7 md:h-7 text-yellow-500' /></span>
                                        <strong className='whitespace-nowrap'>Taxas competitivas:</strong> As melhores taxas do mercado para você começar a lucrar
                                    </li>
                                    <li className="flex flex-col items-center md:flex-row md:items-start gap-5 pt-10 pb-5">
                                        <span><CheckBadgeIcon className='w-10 h-10 md:w-7 md:h-7 text-yellow-500' /></span>
                                        <strong className='whitespace-nowrap'>Fornecedores homologados:</strong> Segurança na compra, seja na importação compartilhada ou na compra com nossos fornecedores
                                    </li>
                                </ul>

                            </div>

                            {/* Instructions Section */}
                            <div className="flex flex-col items-center gap-12 md:gap-24 bg-neutral-950 p-5 py-10 lg:p-20 lg:py-24 shadow-md text-neutral-50">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-center">
                                    Como solicitar seu empréstimo:
                                </h2>
                                <ul className="mt-4 space-y-2 text-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-center">

                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" /></svg>
                                        <p>1. Clique em "Entrar em contato por email"</p>
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M320-240h60v-80h80v-60h-80v-80h-60v80h-80v60h80v80Zm200-30h200v-60H520v60Zm0-100h200v-60H520v60Zm44-152 56-56 56 56 42-42-56-58 56-56-42-42-56 56-56-56-42 42 56 56-56 58 42 42Zm-314-70h200v-60H250v60Zm-50 472q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" /></svg>
                                        <p>2. Realize a Análise de Crédito</p>
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z" /></svg>
                                        <p>3. Defina se será importação ou compra via fornecedor nacional</p>
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z" /></svg>
                                        <p>4. Receba o produto, seja via importação ou fornecedor </p>
                                    </li>
                                </ul>
                            </div>

                            {/* Form Section */}
                            <div className="text-center p-5 lg:p-20 py-10 lg:py-20 shadow-md bg-orange-700 rounded-b-md">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-neutral-50 mb-10">

                                    Aproveite essa oportunidade para aumentar seu estoque e impulsionar seu negócio!

                                </h2>
                                <form className="flex flex-col gap-5 text-neutral-900">
                                    {!sendContact ?
                                        <button className='p-5 w-full bg-green-500 hover:animate-none hover:bg-green-400 active:bg-green-600 animate-pulse text-orange-50 text-lg md:text-xl lg:text-2xl rounded-lg' onClick={() => sendEmail()}>Entrar em contato por e-mail</button>
                                        :
                                        <p className='text-orange-100'>Contato enviado. Entraremos em contato assim que possível {";)"}</p>
                                    }
                                    <p className="text-xs text-neutral-50">
                                        *Ao clicar em enviar, você concorda com os termos e política de análise de crédito e compartilhamento de dados.
                                    </p>
                                    <p className="text-xs text-neutral-50">
                                        *Se você está buscando taxas ainda mais baixas, considere a possibilidade de fazer um empréstimo com garantia, utilizando um imóvel, um carro ou até mesmo seu próprio estoque.
                                    </p>
                                    <p className="text-xs text-neutral-50">
                                        *Oferecemos a opção de empréstimo peer-to-peer (P2P) (beta)
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default EmprestimoSimbium