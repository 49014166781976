import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../features/usuario/usuarioAtual';
import axios from 'axios'
import { toast } from 'react-toastify';

import PopupAgendamento from '../components/popups/add_consultoria'

import './footer.css'

import Aside from './aside';


const Footer = (props) => {

  const navigate = useNavigate();


  return (
    <footer id="footer-container">
      <div className='footer-content'>
        <div className="footer-links">
          <div className="footer-link" onClick={() => {
            navigate('/termos-de-uso');
            /*if (props.toggleAside) props.toggleAside()*/
          }}>
            Termos de Uso
          </div>
          <span className="separator"> • </span>
          <div className="footer-link" onClick={() => {
            navigate('/politicas-de-privacidade');
            /*if (props.toggleAside) props.toggleAside()*/
          }}>
            Políticas de Privacidade
          </div>
        </div>
        <div className="aside-text7">
          © {(new Date()).getFullYear()} Simbium - Todos os direitos reservados
        </div>
      </div>
    </footer>
  )


}

export default Footer
