import React from 'react';
import { HomeIcon, Square3Stack3DIcon, PlayCircleIcon, ChartBarSquareIcon, ChatBubbleLeftEllipsisIcon, InboxStackIcon, BuildingOffice2Icon, BanknotesIcon, UserPlusIcon, UserCircleIcon, UserGroupIcon, Squares2X2Icon } from '@heroicons/react/24/solid';

const MenuHorizontal = () => {
    const menuItems = [
        // { label: 'Home', icon: <HomeIcon className="w-6 h-6" />, link: '/' },
        // { label: 'Conteúdos', icon: <Square3Stack3DIcon className="w-6 h-6" />, link: '/conteudos' },
        { label: 'Shorts', icon: <PlayCircleIcon className="w-6 h-6" />, link: '/shorts' },
        { label: 'Consultorias', icon: <ChatBubbleLeftEllipsisIcon className="w-6 h-6" />, link: '/consultorias' },
        { label: 'Comunidade', icon: <UserGroupIcon className='w-6 h-6' />, link: process.env.REACT_APP_COMUNIDADE_URL },
        { label: 'Catálogo para Revenda', icon: <Squares2X2Icon className='w-6 h-6' />, link: 'https://produtos.simbium.com' },
        { label: 'Marketplace', icon: <ChartBarSquareIcon className="w-6 h-6" />, link: '/marketplace' },
        { label: 'Importe com a Simbium', icon: <InboxStackIcon className="w-6 h-6" />, link: '/importacao-compartilhada' },
        { label: 'Seja um Franqueado', icon: <BuildingOffice2Icon className="w-6 h-6" />, link: '/franquia-simbium' },
        { label: 'Empréstimo Simbium', icon: <BanknotesIcon className="w-6 h-6" />, link: '/emprestimo-simbium' },
        { label: 'Seja um Parceiro', icon: <UserPlusIcon className="w-6 h-6" />, link: '/fornecedores-e-parceiros' },
        { label: 'Seja um Consultor', icon: <UserCircleIcon className="w-6 h-6" />, link: '/seja-consultor' },
    ];

    return (
        <div className="hidden lg:flex w-11/12 mx-auto space-y-5 text-white my-7">
            <div className='w-fit mx-auto rounded-lg overflow-hidden grid grid-cols-10 gap-2'>
                {menuItems.map((item, index) => (
                    <a
                        key={index}
                        href={item.link}
                        className="flex flex-col w-full items-center align-middle group"
                    >
                        <div
                            className="w-12 h-12 rounded-full flex justify-center items-center bg-neutral-700 text-neutral-200 group-hover:bg-orange-500 transition-all duration-300"
                        >
                            <span className="group-hover:scale-125 transition-transform">
                                {item.icon}
                            </span>
                        </div>
                        <span className="mt-1 w-fit text-xs text-neutral-400 group-hover:text-orange-100 text-center">
                            {item.label}
                        </span>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default MenuHorizontal;
