import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { setUsuario } from '../../features/usuario/usuarioAtual';
import { useDispatch, useSelector } from 'react-redux';

const ModalCadastro = ({ closeModal }) => {
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const [processando, setProcessando] = useState(false);
    const [paginaSelecionarOpcoes, setPaginaSelecionarOpcoes] = useState(0);
    const [fimSelecionarOpcoes, setFimSelecionarOpcoes] = useState(false);

    const [novoUsuario, setNovoUsuario] = useState({
        nome: '',
        email: '',
        telefone: '',
        opcoes: [],
    });
    const [erroNovoUsuario, setErroNovoUsuario] = useState({});

    const listaOpcoes = [
        {
            titulo: "Ponto de Decolagem",
            opcoes: [
                { titulo: 'Sou loja física', valor: 'sou-loja-fisica', tipo: 'next' },
                { titulo: 'Já vendo online', valor: 'ja-vendo-online', tipo: 'next' },
                { titulo: 'Estou começando do zero', valor: 'estou-comecando-do-zero', tipo: 'barren' },
            ],
        },
        {
            titulo: "Nível de experiência",
            opcoes: [
                { titulo: 'Estou começando do absoluto zero', valor: 'estou-comecando-do-absoluto-zero', tipo: 'barren' },
                { titulo: 'Entre 10K e 20K por mês em vendas', valor: 'entre-10k-e-20k-por-mes-em-vendas', tipo: 'barren' },
                { titulo: 'Mais de 20K por mês em vendas, querendo escalar', valor: 'mais-de-20k-por-mes-em-vendas-querendo-escalar', tipo: 'barren' },
                { titulo: 'Lançar E-Commerce do Zero (já vendo online)', valor: 'lancar-e-comerce-do-zero-ja-vendo-online', tipo: 'barren' },
            ],
        },
    ];

    const setOpcao = useCallback((opcao) => {
        setNovoUsuario(prev => {
            const opcoesAtualizadas = [...prev.opcoes];
            opcoesAtualizadas[paginaSelecionarOpcoes] = opcao.valor;
            return { ...prev, opcoes: opcoesAtualizadas };
        });

        if (opcao.tipo === 'barren' || paginaSelecionarOpcoes >= listaOpcoes.length - 1) {
            setFimSelecionarOpcoes(true);
        }
        setPaginaSelecionarOpcoes(prev => prev + 1);

    }, [paginaSelecionarOpcoes, listaOpcoes.length]);

    const voltarListaOpcao = useCallback(() => {
        setNovoUsuario(prev => {
            const opcoesAtualizadas = [...prev.opcoes];
            opcoesAtualizadas.pop();
            return { ...prev, opcoes: opcoesAtualizadas };
        });
        setPaginaSelecionarOpcoes(prev => Math.max(prev - 1, 0));
        setFimSelecionarOpcoes(false);
    }, []);

    const filtrarDigitos = (stringValue) => stringValue.replace(/\D/g, '');

    const formatarTelefone = useCallback((value) => {
        let telefoneFormatado = filtrarDigitos(value);
        if (telefoneFormatado.length > 11) telefoneFormatado = telefoneFormatado.slice(0, 11);

        if (telefoneFormatado.length > 7) {
            telefoneFormatado = telefoneFormatado.replace(/^(\d{2})(\d{1})(\d{4})(\d{1,4})$/, '($1) $2 $3-$4');
        } else if (telefoneFormatado.length > 3) {
            telefoneFormatado = telefoneFormatado.replace(/^(\d{2})(\d{1})(\d{1,4})$/, '($1) $2 $3');
        } else if (telefoneFormatado.length > 2) {
            telefoneFormatado = telefoneFormatado.replace(/^(\d{2})(\d{1,3})$/, '($1) $2');
        } else if (telefoneFormatado.length > 0) {
            telefoneFormatado = telefoneFormatado.replace(/^(\d{1,2})$/, '($1');
        }
        return telefoneFormatado;
    }, []);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setNovoUsuario(prevState => ({
            ...prevState,
            [name]: name === 'telefone' ? filtrarDigitos(value) : value
        }));
    }, []);

    const validarDados = useCallback(() => {
        const { nome, email, telefone } = novoUsuario;
        const erros = {};

        if (!nome.trim()) erros.nome = "O nome não pode ser vazio";
        else if (nome.split(' ').length < 2) erros.nome = "Insira o nome completo";

        if (!email.trim()) erros.email = "O email não pode ser vazio";
        else if (!/\S+@\S+\.\S+/.test(email)) erros.email = "Insira um e-mail válido";

        if (!telefone.trim()) erros.telefone = "O número de contato não pode ser vazio";
        else if (filtrarDigitos(telefone).length !== 11 || telefone[2] !== '9') erros.telefone = "Insira um número válido no formato (XX) 9 XXXX-XXXX";

        setErroNovoUsuario(erros);
        return Object.keys(erros).length === 0;
    }, [novoUsuario]);

    const cadastrarUsuario = useCallback(async () => {
        if (!validarDados()) return;
        setProcessando(true);

        try {
            const indicadorId = Cookies.get('indicadorId');
            const dataBody = {
                email: novoUsuario.email.trim(),
                nome: novoUsuario.nome.trim(),
                telefone: novoUsuario.telefone.replace(/\D/g, ""),
                opcoes: novoUsuario.opcoes,
                ...(indicadorId && { indicador: indicadorId })
            };

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/cadastro`,
                dataBody,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
                }
            );

            const { status, data } = response;

            if ([200, 201].includes(status)) {
                toast.success(data.message || 'Cadastro realizado com sucesso! Verifique seu e-mail.');
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', data.usuario._id);

                dispatch(setUsuario(data.usuario));

                window.location.replace('/');
            } else {
                console.error("Erro no cadastro: ", data);
                toast.error(`Erro no cadastro: ${data?.error || 'Ocorreu um erro desconhecido.'}`);
            }
        } catch (error) {
            const mensagemErro = error.response?.data?.error || 'Erro desconhecido. Tente novamente.';
            console.error("Erro no cadastro: ", error);
            toast.error(`Erro no cadastro: ${mensagemErro}`);
        } finally {
            setProcessando(false);
        }
    }, [novoUsuario, validarDados, closeModal]);



    return (
        <div className="fixed z-50 top-0 left-0 w-screen min-h-screen flex justify-center items-center">
            <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={closeModal}></div>
            <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                <div className="border-b border-neutral-700 py-5 flex justify-between items-center">
                    <img src='/main_logo.png' className='h-7' alt="Logo" />
                    <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={closeModal}>&times;</button>
                </div>
                <div className="py-5 flex flex-col gap-5 w-full lg:w-2/3 m-auto">
                    {!fimSelecionarOpcoes &&
                        <div>
                            <p className='text-lg text-center font-sans font-bold'>Informe o seu momento e receba sua trilha personalizada</p>
                            {listaOpcoes.map((lista, index) => (
                                index === paginaSelecionarOpcoes &&
                                <div key={index} className=' p-3 rounded-lg my-3 space-y-3'>
                                    <p className='py-2 border-b border-neutral-700 text-neutral-400 text-xs font-poppins'>{lista.titulo}</p>
                                    <ul className='space-y-3'>
                                        {lista.opcoes.map((opcao, index_opcao) => (
                                            <li key={index_opcao}>
                                                <label className="flex items-center text-white">
                                                    <button className='w-full rounded-sm p-2 border bg-neutral-700 border-neutral-400 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:text-white' disabled={processando} onClick={() => setOpcao(opcao)}>{opcao.titulo}</button>
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    }
                    {fimSelecionarOpcoes && ['nome', 'email', 'telefone'].map((field) => (
                        <div className='flex flex-col w-full gap-2' key={field}>
                            <label htmlFor={field} className='text-xs md:text-sm capitalize'>{field}</label>
                            <input
                                type={field === 'email' ? 'email' : 'text'}
                                id={field}
                                name={field}
                                value={field === 'telefone' ? formatarTelefone(novoUsuario[field]) : novoUsuario[field]}
                                onChange={handleInputChange}
                                required
                                placeholder={`${field === 'telefone' ? '(XX) 9 XXXX-XXXX | Whatsapp' : 'Digite seu ' + field}`}
                                className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-none'
                            />
                            {erroNovoUsuario[field] && <p className='text-red-500 text-xs pt-1'>{erroNovoUsuario[field]}</p>}
                        </div>
                    ))}
                </div>
                <div className="border-t border-neutral-700 py-5 flex justify-between gap-x-3">
                    {paginaSelecionarOpcoes > 0 &&
                        <button
                            className='py-2 text-sm rounded-lg text-orange-500 transition-all p-3 m-auto'
                            onClick={voltarListaOpcao}
                        >
                            Voltar
                        </button>
                    }
                    <div className='w-full flex justify-end gap-x-3'>
                        <button className="py-2 px-3 rounded-md text-xs" onClick={() => window.location.href = '/login'}>Já tenho cadastro</button>
                        <button className="py-1 px-2 md:py-2 md:px-3 rounded-md text-xs bg-neutral-600 hover:bg-neutral-700" onClick={closeModal}>Cancelar</button>
                        {fimSelecionarOpcoes &&
                            <button
                                className="py-1 px-2 md:py-2 md:px-3 rounded-md text-xs font-semibold bg-orange-600 hover:bg-orange-700 disabled:bg-neutral-800"
                                disabled={processando}
                                onClick={cadastrarUsuario}
                            >
                                {processando ? 'Processando...' : 'Cadastrar'}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCadastro;
