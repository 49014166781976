import React from 'react'

import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom' // Importe o Route e o Switch
import { useSelector, useDispatch } from 'react-redux';

import Header from '../components/header'
import Menu from '../components/menu';
import Footer from '../components/footer'
import MenuConsultor from '../components/menu-consultor'
import DiagnosticosConsultorias from '../components/consultor/lista_consultoria_diagnosticos'
import DiagnosticoConsultoria from '../components/consultor/diagnostico_consultor'

import './consultor.css'

const Consultor = (props) => {
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
  return (
    <div>
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="consultor-container">
        <Helmet>
          <title>Consultor - Simbium</title>
          <meta
            property="og:title"
            content="Consultor - Simbium"
          />
        </Helmet>
        <div className="consultor-container001">
          <MenuConsultor rootClassName="menu-consultor-root-class-name"></MenuConsultor>
          <div className="consultor-conteudo l-cont">
            <Routes>
              <Route path="/consultor/*" element={
                <>
                  <Route path="agendamentos-consultorias" component={DiagnosticosConsultorias} />
                  <Route path="diagnostico-consultoria" component={DiagnosticoConsultoria} />
                </>
              } />
            </Routes>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Consultor
