import React, { useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import CourseListCourseMenu from './course-list-course-menu';

const CourseMenu = ({ paginaCodigo, cursoSelecionado, cursos, setCursoSelecionado, aulas, aulaCodigo, listarAulas, aula, showMenu, setListarAulas, setListarCursos }) => {
    // Adicione o ref para cada curso
    // const cursoRefs = useRef([]);

    // Função de manipulação para rolar até o curso
    const handleListClasses = (cursoId) => {
        if (cursoSelecionado === cursoId) {
            setListarAulas(!listarAulas);
        } else {
            setCursoSelecionado(cursoId);
            setListarAulas(true);

            // Encontra o índice do curso selecionado
            /*const cursoIndex = cursos.findIndex((curso) => curso._id === cursoId);

            // Rola até o curso selecionado
            if (cursoIndex >= 0 && cursoRefs.current[cursoIndex]) {
                cursoRefs.current[cursoIndex].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest', // Centraliza o curso no contêiner lateral
                    inline: 'start'
                });
            }*/
        }
    };

    return (
        <div className={`${showMenu ? 'lg:w-[18vw]' : 'lg:w-1/4'} w-full lg:fixed top-14 right-0`}>
            <div className="group w-full border-b bg-neutral-950 border-neutral-950 flex justify-between items-center p-3 text-xl md:text-2xl">
                <div className='w-full block lg:flex lg:justify-between lg:items-center'>
                    <h2 className="text-sm font-bold text-white font-sans">Conteúdo do Curso</h2>
                    <p className="group-hover:hidden text-xs lg:text-sm text-neutral-400 lg:float-right leading-5 lg:text-right">{cursos.length} Módulos</p>
                </div>
                <button type='button'
                    className='lg:hidden lg:group-hover:block'
                    onClick={() => setListarCursos(false)}>
                    <XMarkIcon className='w-5 h-5' />
                </button>
            </div>
            <div className="w-full flex-grow max-h-[85vh] overflow-y-auto">
                <CourseListCourseMenu
                    paginaCodigo={paginaCodigo}
                    cursoSelecionado={cursoSelecionado}
                    cursos={cursos}
                    aulas={aulas}
                    aulaCodigo={aulaCodigo}
                    listarAulas={listarAulas}
                    aula={aula}
                    handleListClasses={handleListClasses}
                />
            </div>
        </div>
    )
};

export default CourseMenu;
