import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Header from '../../components/header';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid'
import { toast } from 'react-toastify';
import { RectangleGroupIcon } from '@heroicons/react/24/solid'
import mixpanel from 'mixpanel-browser';

const ProdutosCatalogados = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('create')
    const [processing, setProcessing] = useState(false)
    const [uploadFile, setUploadFile] = useState(null);
    const [contadorMudancas, setContadorMudancas] = useState(0);

    const [produto, setProduto] = useState({
        nome: '',
        capa: '',
        descricao: '',
        categoria: {
            main: '', // Definindo `main` e `others` corretamente
            others: [],
        },
        link: '',
    });


    const [produtoErros, setProdutoErros] = useState({
        nome: '',
        capa: '',
        descricao: '',
        categoria_main: '',
        categoria_others: '',
        link: '',
    });

    const [disableModal, setDisableModal] = useState(true);

    useEffect(() => {
        const erros = {};

        // Verificação de campo: capa
        if (!produto.capa) {
            erros.capa = 'Uma imagem de capa deve ser selecionada';
        }

        // Verificação de campo: nome
        if (!produto.nome) {
            erros.nome = 'O nome deve ser um texto não vazio';
        }

        // Verificação de campo: descricao
        if (!produto.descricao) {
            erros.descricao = 'A descrição deve ser um texto não vazio';
        }

        // Verificação de campo: categoria.main
        if (!produto.categoria.main) {
            erros.categoria_main = 'A categoria principal deve ser um texto não vazio';
        }

        // Verificação de campo: categoria.others
        if (!produto.categoria.others.length) {
            erros.categoria_others = 'Você deve incluir pelo menos 1 categoria extra';
        }

        // Verificação de campo: link
        if (!produto.link) {
            erros.link = 'O link deve ser um texto não vazio';
        }

        // Atualiza o estado de erros
        setProdutoErros(erros);
        setDisableModal(Object.keys(erros).length > 0);

    }, [produto, produto.categoria, produto.categoria.others]);



    useEffect(() => {
        // Função para buscar a lista de produtos
        const fetchProdutos = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/produtos-catalogados`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });

                if (response.data) {
                    setProdutos(response.data);
                } else {
                    toast.error("Erro ao capturar os produtos. Recarregue a página.");
                }
            } catch (error) {
                console.error('Erro ao buscar produtos:', error);
                toast.error("Erro ao capturar os produtos. Recarregue a página.");
            }
        };

        fetchProdutos();
    }, []);

    const closeModal = () => {
        setProduto({
            nome: '',
            capa: '',
            descricao: '',
            categoria: {
                main: '',
                others: [],
            },
            link: '',
        })
        setModalMode('create')
        setShowModal(false)
        setContadorMudancas(contadorMudancas + 1);
    }

    const [newOtherCategory, setNewOtherCategory] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduto(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCategoryChange = (e) => {
        const { value } = e.target;
        setProduto(prev => ({
            ...prev,
            categoria: {
                ...prev.categoria, // preserva a propriedade others
                main: value // atualiza a categoria principal
            }
        }));
    };

    const addOtherCategory = () => {
        if (newOtherCategory.trim()) {
            setProduto(prev => ({
                ...prev,
                categoria: {
                    ...prev.categoria, // preserva a categoria principal
                    others: prev.categoria.others
                        ? [...prev.categoria.others, newOtherCategory.trim()] // adiciona a nova categoria
                        : [newOtherCategory.trim()] // cria o array caso não exista
                }
            }));
            setNewOtherCategory(''); // limpa o input de nova categoria
        }
    };

    const removeOtherCategory = (index) => {
        setProduto(prev => ({
            ...prev,
            categoria: {
                ...prev.categoria,
                others: prev.categoria.others.filter((_, i) => i !== index) // remove a categoria pelo índice
            }
        }));
    };

    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        const maxSizeMB = 500; // Limite de 500MB
        const maxSizeBytes = maxSizeMB * 1024 * 1024;

        // Verificar se o arquivo excede o limite de tamanho
        if (selectedFile.size > maxSizeBytes) {
            toast.error(`Arquivo maior que ${maxSizeMB}MB, por favor escolha um arquivo menor.`);
            return;
        }

        // Revogar a URL do objeto anterior para evitar vazamento de memória
        if (produto.capa) {
            URL.revokeObjectURL(produto.capa);
        }

        // Atualizar o estado do arquivo e do produto com a URL do objeto
        setUploadFile(selectedFile);
        setProduto(prev => ({
            ...prev,
            capa: URL.createObjectURL(selectedFile), // Atribuir a URL temporária da imagem
        }));
    };


    const getPostUrl = async (produtoId) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/produtos-catalogados/upload-url`,
                {
                    fileType: uploadFile.type,
                    produtoId: produtoId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                throw new Error("Erro ao tentar gerar url de upload");
            }
        } catch (error) {
            console.error("Erro ao tentar gerar url de upload: ", error);
            toast.error(`Erro ao tentar gerar url de upload`);
            return null;
        }
    };

    const uploadFileOnStorage = async (uploadUrl) => {
        try {
            const response = await axios.put(uploadUrl, uploadFile, {
                headers: {
                    'Content-Type': uploadFile.type,
                }
            });
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            console.log("Erro ao fazer upload do arquivo: ", error);
            toast.error("Erro ao fazer upload do arquivo")
            return false
        }
    }

    const updateProdutoCapaUrl = async (produto_id, public_url) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/produtos-catalogados/file-url/${produto_id}`, { public_url }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.data) {
                const updatedProduto = response.data;

                // Atualize a lista de produtos
                setProdutos(prevProdutos =>
                    prevProdutos.map(f =>
                        f.id === updatedProduto.id ? updatedProduto : f
                    )
                );
                return true;
            } else {
                console.log(response)
                toast.error("Erro ao atualizar a url da imagem de capa");
                return false;
            }
        } catch (error) {
            console.error('Erro ao atualizar a url da imagem de capa: ', error);
            toast.error("Erro ao atualizar a url da imagem de capa");
            return false;
        }
    }

    const createProduto = async () => {
        setProcessing(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/produtos-catalogados`, produto, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.data) {
                let publicUrl = response.data.capa; // Inicializa a variável publicUrl
                let uploadSuccess = true; // Assume sucesso, mudamos se necessário
                let updateSuccess = true; // Assume sucesso, mudamos se necessário

                // Verificar se o arquivo precisa ser enviado
                if (uploadFile) {
                    // Obter a URL de upload para a imagem de capa
                    const { fileName, uploadUrl } = await getPostUrl(response.data._id);
                    publicUrl = `https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_BUCKET_NAME}/${fileName}`;

                    // Fazer upload da nova imagem de capa
                    uploadSuccess = await uploadFileOnStorage(uploadUrl);
                }

                // Se o upload foi bem-sucedido, atualiza a URL da capa
                if (uploadSuccess) {
                    if (uploadFile) {
                        updateSuccess = await updateProdutoCapaUrl(response.data._id, publicUrl);
                    }

                    // Atualiza o produto com a nova URL da capa
                    setProduto(prev => ({
                        ...prev,
                        capa: publicUrl,
                    }));

                    const novoProduto = { ...response.data, capa: publicUrl }
                    setProdutos([...produtos, novoProduto].sort((a, b) => a.nome.localeCompare(b.nome)));

                    toast.success("Produto cadastrado com sucesso!");
                    closeModal();
                } else {
                    toast.error("Erro ao fazer upload da nova imagem de capa!");
                }

            } else {
                console.log(response)
                toast.error("Erro ao cadastrar produto. Tente novamente.");
            }
        } catch (error) {
            console.error('Erro ao cadastrar produto:', error);
            toast.error("Erro ao cadastrar produto. Tente novamente.");
        } finally {
            setProcessing(false);
        }
    }

    const updateProduto = async (produto_id) => {
        setProcessing(true);
        try {
            // Enviar a requisição para atualizar o produto
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/produtos-catalogados/${produto_id}`,
                produto, // Certifique-se de que 'produto' contém todos os dados necessários
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );

            if (response.data) {
                let publicUrl = response.data.capa; // Inicializa a variável publicUrl
                let uploadSuccess = true; // Assume sucesso, mudamos se necessário
                let updateSuccess = true; // Assume sucesso, mudamos se necessário

                // Verificar se o arquivo precisa ser enviado
                if (uploadFile) {
                    // Obter a URL de upload para a imagem de capa
                    const { fileName, uploadUrl } = await getPostUrl(response.data._id);
                    publicUrl = `https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_BUCKET_NAME}/${fileName}`;

                    // Fazer upload da nova imagem de capa
                    uploadSuccess = await uploadFileOnStorage(uploadUrl);
                }

                // Se o upload foi bem-sucedido, atualiza a URL da capa
                if (uploadSuccess) {
                    if (uploadFile) {
                        updateSuccess = await updateProdutoCapaUrl(response.data._id, publicUrl);
                    }

                    // Atualiza o estado do produto com o novo objeto
                    setProduto(prev => ({
                        ...prev,
                        capa: publicUrl,
                    }));

                    // Atualiza o estado da lista de produtos
                    setProdutos(
                        produtos.map(prod =>
                            prod._id === response.data._id ? response.data : prod
                        )
                    );

                    toast.success("Produto atualizado com sucesso!");
                    closeModal();
                } else {
                    toast.error("Erro ao fazer upload da nova imagem de capa!");
                }
            } else {
                console.log(response);
                toast.error("Erro ao atualizar produto. Tente novamente.");
            }

        } catch (error) {
            console.error('Erro ao atualizar produto:', error);
            toast.error("Erro ao atualizar produto. Tente novamente.");
        } finally {
            setProcessing(false);
        }
    };


    const handleSave = async (produto_id = null) => {
        if (modalMode === 'create') createProduto();
        else if (modalMode === 'update') updateProduto(produto_id);
        else toast.error('Modal está em modo desconhecido!')
    };

    const filteredProdutos = produtos.filter(produto =>
        produto.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        produto.descricao.toLowerCase().includes(searchTerm.toLowerCase()) ||
        produto.categoria.main.toLowerCase().includes(searchTerm.toLowerCase()) ||
        produto.categoria.others.some(el => el.toLowerCase().includes(searchTerm.toLowerCase())) ||
        produto.link.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const deletarProduto = async (produto_id, produto_nome) => {
        if (!confirm(`Você tem certeja que deseja excluir o produto ${produto_nome}?`)) return;
        setProcessing(true)
        console.log("ID: ", produto_id);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/produtos-catalogados/${produto_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.data) {
                setProdutos(produtos.filter(el => el._id !== produto_id));
                toast.success("Produto deletado com sucesso!");
                setProcessing(false)
            } else {
                console.log(response)
                toast.error("Erro ao deletar produto. Tente novamente.");
                setProcessing(false)
            }
        } catch (error) {
            console.error('Erro ao deletar produto:', error);
            toast.error("Erro ao deletar produto. Tente novamente.");
            setProcessing(false)
        }
    }

    const editarProduto = (produto_id) => {
        setProduto(produtos.find(el => el._id === produto_id));
        setModalMode('update')
        setShowModal(true);
    }

    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Produtos | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    }

    useEffect(() => {
        if (mixpanel)
            mixPanelAcesso();
    }, [mixpanel]);

    return (
        <div className='w-full'>
            {showModal &&
                <div className="fixed top-0 left-0 w-screen min-h-screen flex justify-center align-middle items-center z-40">
                    <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={() => closeModal()}></div>
                    <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                        <div className="border-b border-neutral-700 py-5 space-y-2 flex justify-between items-center">
                            <img src='/main_logo.png' className='h-7' />
                            <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={() => closeModal()}>&times;</button>
                        </div>
                        <div className="py-5 flex flex-col gap-5 w-2/3 m-auto">
                            <div className="flex flex-col w-full gap-2">
                                <label
                                    htmlFor="capa"
                                    className="text-xs md:text-sm cursor-pointer p-2 bg-orange-500 text-white rounded-md text-center hover:bg-orange-600 transition"
                                >
                                    Clique para selecionar a Imagem de Capa
                                </label>
                                <input
                                    type="file"
                                    id="capa"
                                    name="capa"
                                    onChange={handleFileUpload}
                                    accept="image/png, image/jpeg"
                                    className="hidden"
                                />
                                {produtoErros.capa && (
                                    <p className="text-red-500 text-xs md:text-sm pt-1 pb-2">{produtoErros.capa}</p>
                                )}
                                {produto.capa && (
                                    <img
                                        src={`${produto.capa.includes('blob') ? produto.capa : produto.capa}`}
                                        alt="Capa do Produto"
                                        className="w-1/2 mx-auto rounded-lg aspect-auto"
                                    />
                                )}
                            </div>


                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="nome" className='text-xs md:text-sm'>Nome</label>
                                <input type="text" id="nome" name="nome" value={produto.nome} onChange={handleInputChange} required placeholder="Indique o nome do produto" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {produtoErros.nome && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{produtoErros.nome}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="descricao" className='text-xs md:text-sm'>Descrição</label>
                                <input type="text" id="descricao" name="descricao" value={produto.descricao} onChange={handleInputChange} required placeholder="Indique a descrição do produto" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {produtoErros.descricao && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{produtoErros.descricao}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="main" className='text-xs md:text-sm'>Categoria Principal</label>
                                <input type="text" id="main" name="main" value={produto.categoria.main} onChange={handleCategoryChange} required placeholder="Indique a categoria principal" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {produtoErros.categoria_main && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{produtoErros.categoria_main}</p>}
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="others" className='text-xs md:text-sm'>Categorias Secundárias</label>
                                <div className='flex gap-2'>
                                    <input type="text" id="others" name="others" value={newOtherCategory} onChange={(e) => setNewOtherCategory(e.target.value)} placeholder="Adicione uma categoria" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                    <button type="button" onClick={addOtherCategory} className='py-2 px-3 rounded-md text-xs bg-orange-600 hover:bg-orange-700 active:bg-orange-500'>Adicionar</button>
                                </div>
                                {produtoErros.categoria_others && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{produtoErros.categoria_others}</p>}
                                <ul>
                                    {produto.categoria && produto.categoria.others.map((cat, index) => (
                                        <li key={index} className='flex justify-between items-center'>
                                            {cat}
                                            <button type="button" onClick={() => removeOtherCategory(index)} className='text-red-500 hover:text-red-700 text-xs'>Remover</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='flex flex-col w-full gap-2'>
                                <label htmlFor="link" className='text-xs md:text-sm'>Link {'('}Endereço Url{')'}</label>
                                <input type="text" id="link" name="link" value={produto.link} onChange={handleInputChange} required placeholder="https://www.exemplo.com.br" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                                {produtoErros.link && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{produtoErros.localizacao}</p>}
                            </div>
                        </div>
                        <div className="border-t border-neutral-700 py-5 flex flex-row justify-end items-center align-middle gap-x-3 select-none">
                            <button className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-neutral-600 hover:bg-neutral-700 active:bg-neutral-800" onClick={() => closeModal()}>Cancelar</button>
                            <button className="py-2 px-3 md:py-3 md:px-4  rounded-md text-xs font-semibold md:text-sm bg-orange-600 hover:bg-orange-700 active:bg-orange-500" onClick={() => handleSave(produto._id)} disabled={disableModal || processing}>Salvar</button>
                        </div>
                    </div>
                </div>
            }

            <div className='w-full'>
                <Helmet>
                    <title>Simbium</title>
                    <meta property="og:title" content="Simbium" />
                </Helmet>
                <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                <div className='bg-neutral-900 flex flex-col font-poppins'>
                    <div className='w-full flex flex-row md:px-5 gap-10'>
                        {showMenu &&
                            <>
                                <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                                <div className='hidden w-0 lg:block lg:w-3/12'></div>
                            </>
                        }
                        <div className={`${showMenu ? 'w-full lg:w-9/12' : 'lg:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
                            <div className="flex flex-col w-full bg-neutral-700 rounded-lg text-white overflow-hidden">
                                <div className="bg-opacity-50 bg-center bg-no-repeat bg-cover relative" style={{ backgroundImage: "url('/malha.jpeg')" }}>
                                    <div className='w-full flex flex-col gap-7 p-5 sm:p-10 md:p-14 lg:p-20 items-center bg-orange-950 bg-opacity-50'>
                                        <h1 className="text-4xl md:text-7xl text-center font-poppins font-semibold">Próximos Passos</h1>
                                        <p className='text-xs md:text-lg text-center text-white'>Aqui você encontra uma seleção exclusiva e atualizada de itens para começar ou expandir seu negócio.  Explore nosso catálogo e descubra nossas "box para start" e demais produtos com preços exclusivos para revenda.</p>
                                        <div className='flex flex-row gap-2 w-full sm:w-2/3 mx-auto items-center align-middle justify-center'>
                                            <input
                                                type="text"
                                                placeholder="Pesquisar produtos..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                className="w-full p-2 md:p-5 rounded-md text-orange-700 focus:border-orange-400 focus:ring-orange-400"
                                            />
                                            {usuarioAtual.tipo && usuarioAtual.tipo === 'admin' &&
                                                <button className='w-14 h-14 bg-white text-orange-500 text-center hover:bg-orange-50 active:bg-orange-100 p-3 rounded-lg flex items-center justify-center' title={"Adicionar favorecido"} onClick={() => setShowModal(true)}>
                                                    <PlusIcon className='w-5 h-5 p-0 m-0' />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='w-11/12 mx-auto my-10'>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
                                        {filteredProdutos.length ? (
                                            filteredProdutos.map((produto, index) => (
                                                <div key={index} className="bg-neutral-800 rounded-lg overflow-hidden space-y-5">
                                                    <div className='h-60 flex flex-col justify-end bg-center bg-cover bg-no-repeat' style={{ backgroundImage: `url(${produto.capa}?contador-mudancas=${contadorMudancas}&time=${(new Date).getMinutes()}` }}>
                                                        <h2 className="text-md lg:text-xl font-medium p-4 bg-gradient-to-t from-neutral-800 to-transparent">{produto.nome}</h2>
                                                    </div>
                                                    <div className='w-full p-4 pt-0 flex flex-col gap-5'>
                                                        <p className='text-wrap text-sm text-neutral-300'>{produto.descricao}</p>
                                                        <div className='text-xs'>
                                                            <div className='w-fit float-left mr-2 my-1 text-white px-2 py-0.5 rounded-full bg-orange-500'>{produto.categoria?.main}</div>
                                                            {produto.categoria?.others?.length && produto.categoria.others.map((categoria, index) => (
                                                                <div key={index} className='w-fit float-left mr-2 my-1 text-white px-1 py-0.5 rounded-full bg-neutral-500'>{categoria}</div>
                                                            ))}
                                                        </div>
                                                        <div className='w-full mx-auto'>
                                                            <a href={produto.link || '/'} target='_blank' className="w-full text-white text-center text-sm inline-block bg-orange-600 hover:bg-white hover:text-orange-500 active:bg-neutral-50 active:text-orante-600 px-3 py-1 rounded-full">
                                                                Ver Detalhes
                                                            </a>
                                                        </div>
                                                        <div className='w-full flex justify-between'>
                                                            {usuarioAtual.tipo && usuarioAtual.tipo === 'admin' && (
                                                                <>
                                                                    <button className='w-full text-xs text-yellow-500 text-center' onClick={() => editarProduto(produto._id, produto.nome)}>Editar</button>
                                                                    <button className='w-full text-xs text-red-500 text-center' onClick={() => deletarProduto(produto._id, produto.nome)}>Excluir</button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className='w-full col-span-3 text-neutral-300 text-center col-start-1 col-end-5'>
                                                Não há produtos cadastrados no momento!
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default ProdutosCatalogados;
