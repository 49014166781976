import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
// import styles from './master.module.css'
import './master.css'


const MasterCursoAdmin = (props) => {
  const navigate = useNavigate();

  const header_geral = { Authorization: `Bearer ${localStorage.getItem("token")}` }

  const { secao_id } = useParams();
  const [secao, setSecao] = useState(null);
  const [cursos, setCursos] = useState([]);

  const [tituloSecao, setTituloSecao] = useState(secao ? secao.titulo : "");
  const [erroTituloSecao, setErroTituloSecao] = useState("");

  const [descricaoSecao, setDescricaoSecao] = useState(secao ? secao.descricao : "");
  const [erroDescricaoSecao, setErroDescricaoSecao] = useState("");

  const [novoTituloCurso, setNovoTituloCurso] = useState("");
  const [erroNovoTituloCurso, setErroNovoTituloCurso] = useState("");

  const [novaDescricaoCurso, setNovaDescricaoCurso] = useState("");
  const [erroNovaDescricaoCurso, setErroNovaDescricaoCurso] = useState("");

  const [novoModoCurso, setNovoModoCurso] = useState(false);
  const [erroNovoModoCurso, setErroNovoModoCurso] = useState("");

  const [alteracoesSalvas, setAlteracoesSalvas] = useState("")

  const [editRows, setEditRows] = useState(2);
  const [createRows, setCreateRows] = useState(1);

  const [processando, setProcessando] = useState(false);

  const stateSetters = {
    edit: { setDescription: setDescricaoSecao, setRows: setEditRows, minRows: 2 },
    create: { setDescription: setNovaDescricaoCurso, setRows: setCreateRows, minRows: 1 }
  };

  const handleTextChange = (e, mode) => {
    const { setDescription, setRows, minRows } = stateSetters[mode];
    setDescription(e.target.value);
    const numOfLines = e.target.value.split('\n').length;
    setRows(numOfLines > minRows ? numOfLines : minRows);
  };


  useEffect(() => {
    const fetchSecao = async () => {
      setProcessando(true)
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao/${secao_id}`, { headers: header_geral });
        setSecao(response.data);
        setTituloSecao(response.data.titulo)
        setDescricaoSecao(response.data.descricao)
      } catch (error) {
        console.error(error);
      } finally {
        setProcessando(false)
      }
    };

    const fetchCursos = async () => {
      setProcessando(true)
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/cursos/${secao_id}`, { headers: header_geral });
        setCursos(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessando(false)
      }
    }

    fetchSecao();
    fetchCursos();
  }, [secao_id]);

  function criarSlug(titulo) {
    const mapaAcentos = {
      'á': 'a', 'ã': 'a', 'à': 'a', 'â': 'a', 'é': 'e', 'ê': 'e', 'í': 'i', 'ó': 'o', 'õ': 'o', 'ô': 'o', 'ú': 'u', 'ç': 'c',
      'Á': 'a', 'Ã': 'a', 'À': 'a', 'Â': 'a', 'É': 'e', 'Ê': 'e', 'Í': 'i', 'Ó': 'o', 'Õ': 'o', 'Ô': 'o', 'Ú': 'u', 'Ç': 'c'
    };

    let slug = titulo.toLowerCase();
    slug = slug.replace(/[\s]/g, '-'); // substitui espaços por hífens
    slug = slug.replace(/[\W]/g, function (char) { // substitui caracteres especiais
      return mapaAcentos[char] || char;
    });
    slug = slug.replace(/[^a-z0-9-]/g, ''); // remove caracteres não alfanuméricos e não hífens
    return slug;
  }


  const atualizarSecao = async () => {
    setProcessando(true)
    setAlteracoesSalvas("Salvando alterações");
    if (!tituloSecao || !tituloSecao.length) {
      setErroTituloSecao("O título é obrigatório!")
      setProcessando(false)
      return
    }
    setErroTituloSecao("")

    /*if (!descricaoSecao || !descricaoSecao.length) {
      setErroDescricaoSecao("A descrição é obrigatória!")
      return
    }*/
    setErroDescricaoSecao("")

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao/${secao._id}`, {
        titulo: tituloSecao,
        codigo: criarSlug(tituloSecao),
        descricao: descricaoSecao,
      }, { headers: header_geral });

      if (response.status === 200) {
        console.log(response.data.message);

        setAlteracoesSalvas("Alterações salvas com sucesso!");
        setSecao(response.data);

        setTimeout(() => setAlteracoesSalvas(""), 3000)
      } else {
        console.log(response);
        setAlteracoesSalvas("")
      }
    } catch (err) {
      setAlteracoesSalvas("")
      if (err.response && err.response.data) {
        console.error(err.response.data.error);
        alert(err.response.data.error)
      } else {
        console.error(err);
      }
    } finally {
      setProcessando(false)
    }
  };

  const alterarOrdem = async (curso_id, direcao) => {
    setProcessando(true)
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/cursos/ordem/${curso_id}`, { direcao }, { headers: header_geral });
      if (response.status === 200) {
        // Após a alteração da ordem, faça uma nova requisição para buscar todos os cursos
        const responseCursos = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/cursos/${secao_id}`, { headers: header_geral });

        if (responseCursos.status === 200) {
          // Atualize o estado do seu componente com as novos cursos
          setCursos(responseCursos.data);
        } else {
          console.error('Erro ao buscar cursos:', responseCursos);
        }

        return response.data;
      } else {
        console.error('(TRY) Erro ao alterar a ordem do curso:', response);
      }
    } catch (err) {
      console.error('(CATCH) Erro ao alterar a ordem do curso:', err);
    } finally {
      setProcessando(false)
    }
  };

  const adicionarCurso = async () => {
    setProcessando(true)
    if (!novoTituloCurso) {
      setErroNovoTituloCurso("O título é obrigatório.");
      setProcessando(false)
      return;
    }
    setErroNovoTituloCurso("");

    /*if (!novaDescricaoCurso) {
      setErroNovaDescricaoCurso("A descrição é obrigatória.");
      return;
    }*/
    setErroNovaDescricaoCurso("");
    setErroNovoModoCurso("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/curso`, {
        secao_id: secao._id,
        titulo: novoTituloCurso,
        codigo: criarSlug(novoTituloCurso),
        descricao: novaDescricaoCurso,
        emBreve: novoModoCurso,
        ativo: novoModoCurso,
      }, { headers: header_geral });
      // Atualize a lista de cursos após a criação bem-sucedida de uma nova curso
      setCursos(prevCursos => [...prevCursos, response.data]);
      setNovoTituloCurso("");
      setNovaDescricaoCurso("");
      setNovoModoCurso(false);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        console.error(err.response.data);  // Log the server error message
        setErroNovoTituloCurso(err.response.data.titulo);
        setErroNovaDescricaoCurso(err.response.data.descricao);
        setErroNovaDescricaoCurso(err.response.data.emBreve);
      }
    } finally {
      setProcessando(false)
    }

  };

  const excluirCurso = async (curso_id, curso_titulo) => {
    setProcessando(true)
    if (!confirm("Você tem certeza que deseja deletar o curso '" + curso_titulo + "'? Esta ação não pode ser desfeita!")) {
      setProcessando(false)
      return
    }
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/curso/${curso_id}`, { headers: header_geral });
      if (response.status === 200) {
        console.log(response.data.message);
        // Remova a curso da lista após a exclusão bem-sucedida
        setCursos(prevCursos => prevCursos.filter(curso => curso._id !== curso_id));
        alert(response.data.message)
      } else {
        console.log(response)
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        console.error(err.response.data.error);
        alert(err.response.data.error)
      } else {
        console.log(err)
      }
    } finally {
      setProcessando(false)
    }
  };

  return (
    <div className="space-y-5 w-full flex relative flex-col pb-8 justify-start bg-[#212121] rounded-xl overflow-hidden">
      <div className="w-full">
        {secao &&
          <div>
            <div className='text-white bg-zinc-950 p-8'>
              <p className="text-md text-neutral-400 mb-3">Disciplina {'>'} Curso</p>
              <h1 className='text-5xl'>{secao.titulo}</h1>
              <p className="text-orange-500 hover:text-orange-600 active:text-orange-700 cursor-pointer mt-3 transition-all duration-100 ease-linear" onClick={() => navigate(`/admin/master-pagina/${secao.pagina}`)}>Voltar</p>
            </div>

            <div className='px-14'>
              <p className='secaoTitulo'>Editar informações da Curso</p>

              <div className='flex flex-col sm:flex-row items-center align-middle gap-5'>
                <div className='flex flex-col w-full mx-auto'>
                  <input type="text" value={tituloSecao} onChange={e => setTituloSecao(e.target.value)} className="input-master page-title-input disabled:opacity-50" disabled={processando} placeholder="Título" />
                  {erroTituloSecao && <span className="error-master">{erroTituloSecao}</span>}

                  <textarea
                    value={descricaoSecao}
                    onChange={e => handleTextChange(e, 'edit')}
                    className="input-master page-title-input disabled:opacity-50" disabled={processando}
                    placeholder="Descrição"
                    rows={editRows}
                  />
                  {erroDescricaoSecao && <span className="error-master">{erroDescricaoSecao}</span>}

                  <button className='salvarUpdate' onClick={atualizarSecao}>Salvar Alterações</button>
                  {alteracoesSalvas && <p className='text-green-500 my-5 text-center transition-all disabled:opacity-50' disabled={processando}>Alterações salvas com sucesso!</p>}
                </div>
              </div>


              <p className='secaoTitulo'>Lista de Módulos</p>

              <div className='overflow-x-auto'>
                <table className="w-full border-collapse mt-5 bg-neutral-900 rounded-lg">
                  <thead>
                    <tr>
                      {cursos.length > 1 &&
                        <th className='px-2 py-2 border border-neutral-300 text-left text-white w-fit'>Ordem</th>
                      }
                      <th className="px-2 py-2 border border-neutral-300 text-left text-white">Título</th>
                      <th className="px-2 py-2 border border-neutral-300 text-left text-white">Descrição</th>
                      <th className="px-2 py-2 border border-neutral-300 text-left text-white">Modo</th>
                      <th className="px-2 py-2 border border-neutral-300 text-left text-white">Deletar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cursos.map((curso, index) => (
                      <tr key={curso._id} onClick={() => {
                        localStorage.setItem('master-curso', curso.codigo)
                        navigate(`/admin/master-curso/${curso._id}`)
                      }} className="cursor-pointer even:bg-neutral-800 hover:bg-neutral-700 active:bg-neutral-700">
                        {cursos.length > 1 &&
                          <td className='px-2 py-2 border border-neutral-300 text-left text-white whitespace-pre-wrap w-fit'>
                            <span className='flex flex-col items-center'>
                              {index > 0 && <button className='text-center hover:text-orange-600 active:text-orange-700 disabled:opacity-50' disabled={processando} onClick={(event) => {
                                event.stopPropagation();  // Adicione esta linha
                                alterarOrdem(curso._id, -1);
                              }}>▲</button>}
                              {index < cursos.length - 1 && <button className='text-center hover:text-orange-600 active:text-orange-700 disabled:opacity-50' disabled={processando} onClick={(event) => {
                                event.stopPropagation();  // Adicione esta linha
                                alterarOrdem(curso._id, 1);
                              }}>▼</button>}
                            </span>
                          </td>
                        }
                        <td className="px-2 py-2 border border-neutral-300 text-left text-white whitespace-pre-wrap">{curso.titulo}</td>
                        <td className="px-2 py-2 border border-neutral-300 text-left text-white whitespace-pre-wrap">{curso.descricao}</td>
                        <td className="px-2 py-2 border border-neutral-300 text-left text-white">{curso.ativo ? "Ativo" : "Inativo"}</td>
                        <td className="px-2 py-2 border border-neutral-300 text-left text-white">
                          <button className="w-10 h-10 m-auto rounded-full border-none cursor-pointer text-center flex flex-row items-center align-middle justify-center hover:bg-neutral-900 active:bg-neutral-950 disabled:opacity-50" disabled={processando}
                            onClick={(event) => {
                              event.stopPropagation();
                              excluirCurso(curso._id, curso.titulo);
                            }}>
                            <svg className='w-7 h-7' viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#a1a1a1" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)">
                              <g strokeWidth="0">
                                <path transform="translate(-2.4, -2.4), scale(1.7999999999999998)" fill="#efefefaaaaaa" d="M9.166.33a2.25 2.25 0 00-2.332 0l-5.25 3.182A2.25 2.25 0 00.5 5.436v5.128a2.25 2.25 0 001.084 1.924l5.25 3.182a2.25 2.25 0 002.332 0l5.25-3.182a2.25 2.25 0 001.084-1.924V5.436a2.25 2.25 0 00-1.084-1.924L9.166.33z" strokeWidth="0" />
                              </g>
                              <g strokeLinecap="round" strokeLinejoin="round" />
                              <g>
                                <path d="M10 12V17" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M14 12V17" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M4 7H20" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#efefef" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </g>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr className="cursor-pointer even:bg-neutral-800 hover:bg-neutral-700 active:bg-neutral-700">
                      {cursos.length > 1 &&
                        <td className='px-2 py-2 border border-neutral-300 text-left text-white w-fit'></td>
                      }
                      <td className='px-2 py-2 border border-neutral-300 text-left text-white'>
                        <input type="text" value={novoTituloCurso} onChange={e => setNovoTituloCurso(e.target.value)} className="input-master disabled:opacity-50" disabled={processando} placeholder="Título" />
                        {erroNovoTituloCurso && <span className="error-master">{erroNovoTituloCurso}</span>}
                      </td>
                      <td className='px-2 py-2 border border-neutral-300 text-left text-white'>
                        <textarea
                          value={novaDescricaoCurso}
                          onChange={e => handleTextChange(e, 'create')}
                          className="input-master disabled:opacity-50" disabled={processando}
                          placeholder="Descrição"
                          rows={createRows}
                        />
                        {erroNovaDescricaoCurso && <span className="error-master">{erroNovaDescricaoCurso}</span>}
                      </td>
                      <td className='px-2 py-2 border border-neutral-300 text-left text-white'>
                        <input
                          type="checkbox"
                          checked={novoModoCurso}
                          onChange={e => setNovoModoCurso(e.target.checked)}
                          className="w-5 h-5 m-auto rounded-full border-none cursor-pointer text-center flex flex-row items-center align-middle justify-center bg-neutral-500 hover:bg-neutral-900 active:bg-orange-600 checked:bg-orange-600 focus:bg-orange-400 disabled:opacity-50" disabled={processando}
                        />
                      </td>
                      <td className='px-2 py-2 border border-neutral-300 text-left text-white'>
                        <button className="w-10 h-10 m-auto rounded-full border-none cursor-pointer text-center flex flex-row items-center align-middle justify-center hover:bg-neutral-900 active:bg-neutral-950 disabled:opacity-50" disabled={processando} onClick={adicionarCurso}>
                          <svg className='w-7 h-7' viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#efefef">
                            <g strokeWidth="0" />
                            <g strokeLinecap="round" strokeLinejoin="round" />
                            <g>
                              <path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="#efefef" /> <path fillRule="evenodd" clipRule="evenodd" d="M12.0574 1.25H11.9426C9.63424 1.24999 7.82519 1.24998 6.41371 1.43975C4.96897 1.63399 3.82895 2.03933 2.93414 2.93414C2.03933 3.82895 1.63399 4.96897 1.43975 6.41371C1.24998 7.82519 1.24999 9.63422 1.25 11.9426V12.0574C1.24999 14.3658 1.24998 16.1748 1.43975 17.5863C1.63399 19.031 2.03933 20.1711 2.93414 21.0659C3.82895 21.9607 4.96897 22.366 6.41371 22.5603C7.82519 22.75 9.63423 22.75 11.9426 22.75H12.0574C14.3658 22.75 16.1748 22.75 17.5863 22.5603C19.031 22.366 20.1711 21.9607 21.0659 21.0659C21.9607 20.1711 22.366 19.031 22.5603 17.5863C22.75 16.1748 22.75 14.3658 22.75 12.0574V11.9426C22.75 9.63423 22.75 7.82519 22.5603 6.41371C22.366 4.96897 21.9607 3.82895 21.0659 2.93414C20.1711 2.03933 19.031 1.63399 17.5863 1.43975C16.1748 1.24998 14.3658 1.24999 12.0574 1.25ZM3.9948 3.9948C4.56445 3.42514 5.33517 3.09825 6.61358 2.92637C7.91356 2.75159 9.62177 2.75 12 2.75C14.3782 2.75 16.0864 2.75159 17.3864 2.92637C18.6648 3.09825 19.4355 3.42514 20.0052 3.9948C20.5749 4.56445 20.9018 5.33517 21.0736 6.61358C21.2484 7.91356 21.25 9.62177 21.25 12C21.25 14.3782 21.2484 16.0864 21.0736 17.3864C20.9018 18.6648 20.5749 19.4355 20.0052 20.0052C19.4355 20.5749 18.6648 20.9018 17.3864 21.0736C16.0864 21.2484 14.3782 21.25 12 21.25C9.62177 21.25 7.91356 21.2484 6.61358 21.0736C5.33517 20.9018 4.56445 20.5749 3.9948 20.0052C3.42514 19.4355 3.09825 18.6648 2.92637 17.3864C2.75159 16.0864 2.75 14.3782 2.75 12C2.75 9.62177 2.75159 7.91356 2.92637 6.61358C3.09825 5.33517 3.42514 4.56445 3.9948 3.9948Z" fill="#efefef" />
                            </g>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default MasterCursoAdmin