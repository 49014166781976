import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon, TrashIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import AcaoTrilhaAdmin from './acao_trilha';

const DivisorTrilhaAdmin = ({ trilhaId, processando, setProcessando, divisores, trilhaSalva, setTrilhaSalva, fetchTrilha, showAcaoMenu, setShowAcaoMenu }) => {
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const token = localStorage.getItem("token");
    const axios_header = { headers: { Authorization: `Bearer ${token}` } }

    const [divisorTrilha, setDivisorTrilha] = useState({
        titulo: '',
    });

    const criarDivisorTrilha = async () => {
        setProcessando(true);
        try {
            const { status, data } = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/divisor-trilha`,
                {
                    titulo: `Semana ${divisores?.length + 1}`,
                    trilhaId: trilhaId,
                },
                axios_header
            );

            if (status === 201) {
                await fetchTrilha(); // Atualiza a lista de divisores
                toast.success("Divisor da trilha cadastrado com sucesso!");
            } else {
                console.error("Erro ao cadastrar divisor da trilha:", status);
                toast.error("Erro ao cadastrar um novo divisor da trilha.");
            }
        } catch (error) {
            console.error("Erro ao cadastrar divisor da trilha:", error.response?.data || error.message);
            toast.error("Erro ao cadastrar um novo divisor da trilha.");
        } finally {
            setProcessando(false);
        }
    };

    const handleInputUpdate = (e, divisor) => {
        const { name, value } = e.target;
        divisor.titulo = value;
        setDivisorTrilha(prev => ({ ...prev, _id: divisor._id || prev._id, [name]: value }));
        setTrilhaSalva(false);
    };


    const salvarAlteracoesDivisorTrilha = async () => {
        if (divisores?.length && !divisorTrilha._id) {
            console.error("Erro: ID do divisorTrilha está indefinido");
            toast.error("Erro: ID do divisorTrilha está indefinido.");
            return;
        }

        //setProcessando(true);
        try {
            const { status } = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/divisor-trilha/${divisorTrilha._id}`,
                { titulo: divisorTrilha.titulo },
                axios_header
            );

            if (status === 200) {
                setTrilhaSalva(true);
                // fetchTrilha();
            } else {
                console.error(`Erro ao salvar as alterações do divisor da trilha: Status ${status}`);
                toast.error("Erro ao salvar as alterações do divisor da trilha.");
            }
        } catch (error) {
            console.error("Erro ao salvar as alterações do divisor da trilha:", error);
            toast.error("Erro ao salvar as alterações do divisor da trilha.");
        } finally {
            //setProcessando(false);
        }
    };


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!trilhaSalva) {
                salvarAlteracoesDivisorTrilha();
            }
        }, 700);

        return () => clearTimeout(timeoutId);
    }, [divisorTrilha.titulo]);


    const alterarOrdem = async (divisor_id, sentido) => {
        setProcessando(true);
        try {
            const { status, data } = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/divisor-trilha/mover/${divisor_id}`,
                { sentido }, // sentido pode ser 1 (subir) ou -1 (descer)
                axios_header
            );

            if (status === 200) {
                await fetchTrilha(); // Atualiza a lista de divisores após mudança
                toast.success("Ordem do divisor alterada com sucesso!");
            } else {
                toast.error("Erro ao alterar a ordem do divisor.");
            }
        } catch (error) {
            console.error("Erro ao alterar a ordem do divisor:", error);
            toast.error("Erro ao alterar a ordem do divisor.");
        } finally {
            setProcessando(false);
        }
    };



    const excluirDivisorTrilha = async (divisor_id, divisor_titulo) => {
        if (!confirm(`Você tem certeza que deseja excluir o divisor '${divisor_titulo}'? Esta ação é irreversível!`)) return;

        setProcessando(true);
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/divisor-trilha/${divisor_id}`,
                axios_header
            );

            if (response.status === 200) {
                toast.success("Divisor excluído com sucesso!");
                fetchTrilha();
                setTrilhaSalva(true);
            } else {
                console.error(`Erro ao excluir divisor da trilha: ${response}`);
                toast.error("Erro ao excluir divisor da trilha.");
            }
        } catch (error) {
            console.error("Erro ao excluir divisor da trilha: ", error);
            toast.error("Erro ao excluir divisor da trilha.");
        } finally {
            setProcessando(false);
        }
    };

    return (
        <div>
            <ul className='space-y-10 p-3 rounded-lg bg-neutral-900 border-neutral-500 border'>
                {divisores?.length > 0 ? (
                    divisores.map((divisor, index) => (
                        <li key={index} className='text-neutral-100 gap-3 items-center'>
                            <div className='group w-full flex gap-3'>
                                <div className='flex flex-col items-center justify-center'>
                                    {index > 0 &&
                                        <button onClick={() => alterarOrdem(divisor._id, -1)}>
                                            <ChevronUpIcon className='w-5 h-4 text-white hover:text-orange-500 active:text-orange-600' />
                                        </button>
                                    }
                                    {index < divisores?.length - 1 &&
                                        <button onClick={() => alterarOrdem(divisor._id, 1)}>
                                            <ChevronDownIcon className='w-5 h-4 text-white hover:text-orange-500 active:text-orange-600' />
                                        </button>
                                    }
                                </div>
                                <input
                                    type="text"
                                    value={divisor.titulo}
                                    name="titulo"
                                    onChange={e => handleInputUpdate(e, divisor)}
                                    className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:ring-1 focus:ring-orange-500 disabled:opacity-50'
                                    disabled={processando}
                                    placeholder="Título do Divisor"
                                />
                                <button
                                    title={`Excluir divisor '${divisor.titulo}'`}
                                    disabled={processando}
                                    onClick={() => excluirDivisorTrilha(divisor._id, divisor.titulo)}
                                >
                                    <TrashIcon className='w-5 h-5 text-red-500 hidden group-hover:flex' />
                                </button>
                            </div>
                            <AcaoTrilhaAdmin
                                divisorId={divisor._id}
                                processando={processando}
                                setProcessando={setProcessando}
                                acoes={divisor.acoes}
                                trilhaSalva={trilhaSalva}
                                setTrilhaSalva={setTrilhaSalva}
                                fetchTrilha={fetchTrilha}
                                showAcaoMenu={showAcaoMenu}
                                setShowAcaoMenu={setShowAcaoMenu}
                            />
                        </li>
                    ))
                ) : (
                    <li className='text-neutral-300 text-center text-xs'>
                        Nenhum divisor cadastrado até o momento
                    </li>
                )}
            </ul>
            <button
                className='bg-orange-500 text-sm w-full mx-auto p-2 rounded-md my-5'
                onClick={criarDivisorTrilha}
                disabled={processando}
            >
                Adicionar um divisor
            </button>
        </div>
    );
}

export default DivisorTrilhaAdmin;
