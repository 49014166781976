const ProdutosCatalogadosHome = (props) => {

    return (
        <section className='flex flex-col space-y-10 my-10'>
            <section className='w-11/12 mx-auto'>
                <div className='flex justify-between items-center'>
                    <h2 className='text-md md:text-xl text-neutral-100 font-bold'>Catálogo para Revenda</h2>
                    <a href='https://produtos.simbium.com/' target="_blank" className='text-neutral-500 text-sm hover:text-orange-500 active:text-orange-800'>Ver Mais</a>
                </div>
                <div className="w-full">
                    <div className="w-full overflow-x-auto scroll-smooth">
                        <div className="flex flex-row w-fit py-5 lg:px-0 gap-3 snap-x snap-mandatory whitespace-nowrap">
                            <a
                                href={`https://produtos.simbium.com/product/umidificador/`}
                                target="_blank"
                            >
                                <div className="w-[45vw] sm:w-[35vw] md:w-[20vw] min-h-[25vw] aspect-auto active:opacity-90 overflow-hidden rounded-lg bg-neutral-700 border-2 border-neutral-900 transition-all hover:border-orange-500">
                                    <div>
                                        <img src={"https://produtos.simbium.com/wp-content/uploads/2024/10/D_NQ_NP_2X_712117-MLB45001070464_022021-Fpng.webp"} className="" />
                                    </div>
                                    <div className="p-2 md:p-3 w-full space-y-1">
                                        <p className="text-md md:text-lg text-neutral-100 font-medium truncate">Umidificador De Oleos Essenciais Multicor</p>
                                        <p className="text-xs md:text-md text-neutral-400 truncate">Com design moderno e elegante o difusor e umidificador de ambientes também é sinônimo de saúde, pois ajuda a melhorar a qualidade do ar, diminuindo sintomas de resfriados, alergias e aliviando a tosse.</p>
                                    </div>
                                </div>
                            </a>
                            <a
                                href={`https://produtos.simbium.com/product/kit-de-utensilios-para-cozinha-em-silicone-e-bambu-12-pecas/`}
                                target="_blank"
                            >
                                <div className="w-[45vw] sm:w-[35vw] md:w-[20vw] min-h-[25vw] aspect-auto active:opacity-90 overflow-hidden rounded-lg bg-neutral-700 border-2 border-neutral-900 transition-all hover:border-orange-500">
                                    <div>
                                        <img src={"https://produtos.simbium.com/wp-content/uploads/2024/10/KIT-de-Utensilios-Para-Cozinha-em-Silicone-e-Bambu-12-Pecas-novo.avif"} className="" />
                                    </div>
                                    <div className="p-2 md:p-3 w-full space-y-1">
                                        <p className="text-md md:text-lg text-neutral-100 font-medium truncate">KIT de Utensílios Para Cozinha em Silicone e Bambu 12 Peças</p>
                                        <p className="text-xs md:text-md text-neutral-400 truncate">Kit Completo de Utensílios para Cozinha em Silicone que não danificam suas panelas e trazem total beleza para sua cozinha, realmente a qualidade é incrível.</p>
                                    </div>
                                </div>
                            </a>
                            <a
                                href={`https://produtos.simbium.com/product/tapetes-tatames-eva-30x30/`}
                                target="_blank"
                            >
                                <div className="w-[45vw] sm:w-[35vw] md:w-[20vw] min-h-[25vw] aspect-auto active:opacity-90 overflow-hidden rounded-lg bg-neutral-700 border-2 border-neutral-900 transition-all hover:border-orange-500">
                                    <div>
                                        <img src={"https://produtos.simbium.com/wp-content/uploads/2024/10/Tapetes-Tatames-EVA-30x30-1.avif"} className="" />
                                    </div>
                                    <div className="p-2 md:p-3 w-full space-y-1">
                                        <p className="text-md md:text-lg text-neutral-100 font-medium truncate">Tapetes Tatames EVA – 30×30</p>
                                        <p className="text-xs md:text-md text-neutral-400 truncate">São ideais para decorações e proteção de áreas infantis, locais de pouco espaço físico, ambientes onde são praticados de esportes com baixo impacto, e muito mais!</p>
                                    </div>
                                </div>
                            </a>
                            <a
                                href={`https://produtos.simbium.com/product/tapete-bebe-180cm/`}
                                target="_blank"
                            >
                                <div className="w-[45vw] sm:w-[35vw] md:w-[20vw] min-h-[25vw] aspect-auto active:opacity-90 overflow-hidden rounded-lg bg-neutral-700 border-2 border-neutral-900 transition-all hover:border-orange-500">
                                    <div>
                                        <img src={"https://produtos.simbium.com/wp-content/uploads/2024/10/tapete-infantil.webp"} className="" />
                                    </div>
                                    <div className="p-2 md:p-3 w-full space-y-1">
                                        <p className="text-md md:text-lg text-neutral-100 font-medium truncate">Tapete Infantil Atividade Bebê Dupla Face Emborrachado 180*200*0.8cm</p>
                                        <p className="text-xs md:text-md text-neutral-400 truncate">Tapete Infantil Atividade Bebê Dupla Face Emborrachado 8 mm de Espessura Ideal para os bebês desde os primeiros meses de vida, os nossos tapetes interativo contam com estampas coloridas e divertidas que estimulam as crianças a brincarem e se desenvolvendo com segurança.</p>
                                    </div>
                                </div>
                            </a>
                            <a
                                href={`https://produtos.simbium.com/product/kit-8-pecas-talher/`}
                                target="_blank"
                            >
                                <div className="w-[45vw] sm:w-[35vw] md:w-[20vw] min-h-[25vw] aspect-auto active:opacity-90 overflow-hidden rounded-lg bg-neutral-700 border-2 border-neutral-900 transition-all hover:border-orange-500">
                                    <div>
                                        <img src={"https://produtos.simbium.com/wp-content/uploads/2024/10/H0f98414c73524c4eb38cac01cf92c676v.avif"} className="" />
                                    </div>
                                    <div className="p-2 md:p-3 w-full space-y-1">
                                        <p className="text-md md:text-lg text-neutral-100 font-medium truncate">Kit Talheres Infantil bebê silicone 8 Peças – Introdução alimentar</p>
                                        <p className="text-xs md:text-md text-neutral-400 truncate">Usando material de silicone de qualidade alimentar (importado) Todo o corpo não tem lacunas nem becos sem saída, fácil de limpar e não é fácil de reproduzir bactérias.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );


};

export default ProdutosCatalogadosHome;
