import React, { useState } from 'react';

const InfoSection = ({ pagina, secao, curso, aula, listarCursos, parseDescricao }) => {
    const [showInfo, setShowInfo] = useState('aula')
    const options = ['aula', 'modulo', 'curso', 'disciplina'];

    const infoMapping = {
        aula: {
            label: 'Informações da Aula',
            title: aula?.titulo || '',
            description: parseDescricao(aula?.descricao) || ''
        },
        modulo: {
            label: 'Informações do Módulo',
            title: curso?.titulo || '',
            description: parseDescricao(curso?.descricao) || '',
            additionalInfo: parseDescricao(curso?.descricaoProfessor) || ''
        },
        curso: {
            label: 'Informações do Curso',
            title: secao?.titulo || '',
            description: parseDescricao(secao?.descricao) || ''
        },
        disciplina: {
            label: 'Informações da Disciplina',
            title: pagina?.titulo || '',
            description: parseDescricao(pagina?.descricao) || ''
        }
    };

    const renderInfoContent = () => {
        const info = infoMapping[showInfo];

        if (!info) return null; // Retorna null se não houver informação para mostrar

        return (
            <div className='m-5 p-5 bg-neutral-950 rounded-lg shadow-sm shadow-neutral-950 divide-y divide-neutral-900'>
                <div>
                    <p className='text-xs text-neutral-600'>{info.label}</p>
                    <div className='py-5 space-y-2'>
                        <h1 className='text-sm'>{info.title}</h1>
                        <h2 className='text-xs text-neutral-400'>{info.description}</h2>
                    </div>
                </div>
                {info.additionalInfo && (
                    <div>
                        <p className='pt-5 text-xs text-neutral-600'>Informações do Professor</p>
                        <div className='my-3 space-y-1'>
                            <h2 className='text-xs text-neutral-200'>{info.additionalInfo}</h2>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={`w-full bg-neutral-900 ${listarCursos ? 'lg:w-3/4' : ''}`}>
            <div className='m-5 space-x-2 overflow-auto'>
                <div className='w-fit flex gap-3'>
                    {options.map((type) => (
                        <button
                            key={type}
                            className={`w-fit whitespace-nowrap px-3 py-2 hover:bg-orange-500 active:bg-orange-600 rounded-full text-xs ${showInfo === type ? 'bg-orange-500' : 'bg-neutral-800'}`}
                            onClick={() => setShowInfo(type)}
                        >
                            {infoMapping[type]?.label}
                        </button>
                    ))}
                </div>
            </div>

            {renderInfoContent()}
        </div>
    )
};

export default InfoSection;
