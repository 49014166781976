import { FilmIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const AulaMenu = ({ paginaCodigo, curso, aulas, aulaCodigo }) => {
    return (
        <ul className='w-full h-auto flex flex-col divide-y divide-neutral-800'>
            {aulas.map((aula, index) => (
                <li key={index}>
                    <Link to={`/master/${paginaCodigo}/${curso.codigo}/${aula.codigo}`} className={`w-full px-5 py-3 ${aulaCodigo === aula.codigo ? 'bg-orange-600' : 'bg-neutral-700 hover:bg-neutral-600'} flex items-center align-middle gap-3`}>
                        <FilmIcon className='w-4 h-4' />
                        <p className='text-left text-sm font-sans'>{aula.titulo}</p>
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default AulaMenu;