import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AutomacoesHome = () => {
    const navigate = useNavigate();
    const [secoesPaginas, setSecoesPaginas] = useState([]);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                const header_geral = { Authorization: `Bearer ${token}` };
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao-pagina-com-paginas-home`, { headers: header_geral });
                if (isMounted && response.status === 200) {
                    setSecoesPaginas(response.data.secoesComPaginas);
                } else {
                    console.error("Erro ao obter dados: ", response);
                }
            } catch (error) {
                console.error("Erro na requisição: ", error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <section className="flex flex-col space-y-10 mt-10">
            {secoesPaginas?.length > 0 ? (
                secoesPaginas.map((secaoPagina, secaoIndex) => (
                    <section key={secaoIndex} className="w-11/12 mx-auto">
                        <div className="flex justify-between items-center">
                            <h2 className="text-md md:text-xl text-neutral-50 font-bold">{secaoPagina.titulo}</h2>
                        </div>
                        <div className="w-full">
                            <div className="w-full overflow-x-auto scroll-smooth">
                                <div className="flex flex-row w-fit py-5 lg:px-0 gap-3 snap-x snap-mandatory whitespace-nowrap">
                                    {secaoPagina.paginas.map((pagina, index) => (
                                        <div
                                            key={index}
                                            className="overflow-hidden relative group min-w-[45vw] sm:min-w-[35vw] md:min-w-[15vw] min-h-[75vw] sm:min-h-[65vw] md:min-h-[25vw] aspect-auto rounded-lg bg-neutral-500 bg-center bg-cover bg-no-repeat cursor-pointer border-2 border-neutral-900 transition-all hover:min-w-[135vw] sm:hover:min-w-[105vw] md:hover:min-w-[45vw] snap-start"
                                            style={{
                                                backgroundImage: `url(${hoveredIndex === index ? (pagina.bannerImagem || '/malha.jpeg') : (pagina.capaImagem || '/malha.jpeg')})`
                                            }}
                                            onMouseEnter={() => setHoveredIndex(index)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                            onClick={() => navigate(`/master/${pagina.codigo}`)}
                                        >
                                            {/* Overlay escuro */}
                                            <div className="absolute inset-0 bg-gradient-to-r from-neutral-950 to-transparent bg-opacity-50 opacity-0 group-hover:opacity-90 transition-opacity duration-300"></div>

                                            {/* Texto sobreposto */}
                                            <div className="absolute h-full w-1/2 p-4 flex-col gap-3 justify-center align-middle text-white hidden group-hover:flex transition-opacity duration-300">
                                                <h3 className="text-3xl font-bold">{pagina.titulo}</h3>
                                                <p className="text-lg whitespace-pre-wrap">{pagina.descricao}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                ))
            ) : (
                <p className="w-11/12 mx-auto text-white italic text-xs">Nenhuma seção disponível no momento.</p>
            )}
        </section>
    );
};

export default AutomacoesHome;
