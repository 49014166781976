import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { BanknotesIcon, TruckIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';
import mixpanel from 'mixpanel-browser';

const ImportacaoCompartilhada = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(false);
    const [sendContact, setSendContact] = useState(false);

    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Importação | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    }

    const [diferencaTempo, setDiferencaTempo] = useState(0);

    useEffect(() => {
        if (mixpanel) {
            mixPanelAcesso();

            const interval = setInterval(() => {
                setDiferencaTempo(prevTempo => prevTempo + 1);
            }, 60000);

            return () => clearInterval(interval);
        }
    }, [mixpanel]);

    const mixPanelContato = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual ou usuarioAtual._id ausente');
            return;
        }
        mixpanel.track("Importação | Contato", {
            distinct_id: usuarioAtual._id,
            tempo_convencimento: diferencaTempo,
        });
    }

    const sendEmail = () => {
        setSendContact(true);
        console.log(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`);

        axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`,
            {
                recipientName: usuarioAtual.name,
                recipientEmail: usuarioAtual.email,
                subject: 'Estou interessado na IMPORTAÇÃO COMPARTILHADA da Simbium.',
                htmlContent: `Nome: ${usuarioAtual.nome}<br>Email: ${usuarioAtual.email}<br>Telefone: ${usuarioAtual.telefone || "Não possui"}`
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        )
            .then(response => {
                mixPanelContato();
                console.log("RESPOSTA DO SERVIDOR: ", response);
                toast.success('E-mail enviado com sucesso!');
            })
            .catch(error => {
                console.log("RESPOSTA DO SERVIDOR: ", error);
                toast.error('Erro ao enviar e-mail. Por favor, tente novamente mais tarde');
                setSendContact(false);
            });
    }


    return (
        <div className='w-full'>
            <Helmet>
                <title>Simbium</title>
                <meta property="og:title" content="Simbium" />
            </Helmet>
            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            <div className='bg-neutral-900 flex flex-col font-poppins'>
                <div className='w-full flex flex-row md:px-5 gap-10'>
                    {showMenu &&
                        <>
                            <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                            <div className='hidden w-0 lg:block lg:w-3/12'></div>
                        </>
                    }
                    <div className={`${showMenu ? 'w-full lg:w-9/12' : 'sm:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
                        <div className="flex flex-col w-full bg-orange-700 rounded-lg text-white">
                            {/* Hero Section */}
                            <div className="text-center py-10 text-neutral-50 w-10/12 mx-auto">
                                <h1 className="text-xl md:text-3xl lg:text-5xl font-extrabold leading-tight mb-4 text-white">
                                    Importe da China com nosso contêiner compartilhado e aumente sua margem em até 76%!
                                </h1>
                                <p className="text-xs md:text-sm lg:text-lg mt-2">
                                    Gerenciamos todo o processo da importação, desde a consolidação da carga na China, até a entrega da mercadoria em seu depósito. <strong className='text-yellow-400'>Escolha o produto. Calcule sua margem de lucro, e fature!</strong>
                                </p>
                            </div>

                            {/* Video Section
                            <div className="flex justify-center md:mb-5 lg:mb-10">
                                <div className="w-full md:w-3/4">
                                    <div className="aspect-w-16 aspect-h-9 bg-gray-800 flex items-center justify-center overflow-hidden shadow-xl shadow-orange-950">
                                        <video controls className="w-full h-full object-cover">
                                            <source src="caminho-do-video.mp4" type="video/mp4" />
                                            Seu navegador não suporta o elemento de vídeo.
                                        </video>
                                    </div>
                                </div>
                            </div>
                             */}

                            {/* Benefits Section */}
                            <div className="text-center bg-neutral-950 p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-24 flex flex-col gap-12 md:gap-24">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold">
                                    Cuidamos de tudo para você!
                                </h2>
                                <div className='flex gap-5 w-full md:w-2/3 mx-auto text-left'>
                                    <TruckIcon className='w-20 h-20' />
                                    <p className="text-xs md:text-lg lg:text-xl">
                                        Nosso contêiner compartilhado é uma solução logística inovadora que permite que você importe seus produtos da China de forma rápida, segura e econômica.
                                    </p>
                                </div>

                                <div className='flex gap-5 w-full md:w-2/3 mx-auto text-left'>
                                    <BanknotesIcon className='w-20 h-20' />
                                    <p className="text-xs md:text-lg lg:text-xl">
                                        Com ele, você não precisa se preocupar com a burocracia e os custos elevados de importação, apenas em escolher o que comprar, calcular sua margem de ganho e nada mais!
                                    </p>

                                </div>
                            </div>

                            {/* Why Shared Container Section */}
                            <div className="flex flex-col items-center gap-12 md:gap-24 bg-neutral-800 p-5 py-10 lg:p-20 lg:py-24 shadow-md text-neutral-50">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-center">
                                    Vantagens de importar com nosso contêiner compartilhado
                                </h2>
                                <ul className="flex flex-col gap-5 text-xs md:text-lg lg:text-xl divide-y divide-neutral-700 text-neutral-300 w-11/12 mx-auto">
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Aumento da margem de lucro em até 60%:</strong> Oferecemos um serviço completo para garantir que suas importações cheguem sem problemas.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Redução de custos logísticos:</strong> Com nosso contêiner compartilhado, você divide os custos com outros importadores, economizando muito mais.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Agilidade e segurança no transporte:</strong> Oferecemos um serviço completo para garantir que suas importações cheguem sem problemas.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className=''>Facilidade na importação de pequenas dsfljm quantidades:</strong> Nosso processo de importação é otimizado para ser rápido e eficiente.</p>
                                    </li>
                                </ul>
                            </div>

                            {/* Contact Section */}
                            <div className="text-center bg-neutral-950 p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-32 flex flex-col gap-12 md:gap-24">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold ">
                                    Como importar com nosso contêiner compartilhado:
                                </h2>
                                <ul className="mt-4 space-y-2 text-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" /></svg>
                                        <p>1. Entre em contato conosco no botão abaixo.</p>
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
                                        <p>2. Selecione os produtos que deseja importar</p>
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm100-200h46v-240h-36l-70 50 24 36 36-26v180Zm124 0h156v-40h-94l-2-2q21-20 34.5-34t21.5-22q18-18 27-36t9-38q0-29-22-48.5T458-600q-26 0-47 15t-29 39l40 16q5-13 14.5-20.5T458-558q15 0 24.5 8t9.5 20q0 11-4 20.5T470-486l-32 32-54 54v40Zm296 0q36 0 58-20t22-52q0-18-10-32t-28-22v-2q14-8 22-20.5t8-29.5q0-27-21-44.5T678-600q-25 0-46.5 14.5T604-550l40 16q4-12 13-19t21-7q13 0 21.5 7.5T708-534q0 14-10 22t-26 8h-18v40h20q20 0 31 8t11 22q0 13-11 22.5t-25 9.5q-17 0-26-7.5T638-436l-40 16q7 29 28.5 44.5T680-360ZM160-240h640v-480H160v480Zm0 0v-480 480Z" /></svg>
                                        <p>3. Escolha a quantidade de produtos que deseja importar</p>
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z" /></svg>
                                        <p>4. Pague o frete</p>
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z" /></svg>
                                        <p>5. Nós cuidamos do resto!</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-center bg-orange-700 p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-32 flex flex-col gap-12 md:gap-24 rounded-b-lg">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold ">
                                    Importe seus produtos com nosso contêiner compartilhado e aumente sua margem em até 76%!
                                </h2>
                                {!sendContact ?
                                    <button className='p-5 w-full bg-green-500 hover:animate-none hover:bg-green-400 active:bg-green-600 animate-pulse text-orange-50 text-lg md:text-xl lg:text-2xl rounded-lg' onClick={() => sendEmail()}>Entrar em contato por e-mail</button>
                                    :
                                    <p className='text-orange-100'>Contato enviado. Entraremos em contato assim que possível {";)"}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ImportacaoCompartilhada;
