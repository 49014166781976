import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../features/usuario/usuarioAtual';
import Cookies from 'js-cookie';


import './login.css'
import { EnvelopeIcon, InformationCircleIcon, LockClosedIcon, LockOpenIcon, PhoneIcon, UserIcon } from '@heroicons/react/24/solid';
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';


const Login = (props) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('')
  const [nome, setNome] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [modoCadastro, setModoCadastro] = useState(search === "?cadastro=true");
  const [whatsSuporte, setWhatsSuporte] = useState('')
  const [loading, setLoading] = useState(false);

  const formatarTelefone = (input) => {
    const numericValue = input.replace(/\D/g, '');

    const maxLength = 11;
    if (numericValue.length > maxLength) {
      return whatsapp;
    }

    let formattedValue = '';

    for (let i = 0; i < numericValue.length; i++) {
      if (i === 0) {
        formattedValue += `(${numericValue[i]}`;
      } else if (i === 2) {
        formattedValue += `) ${numericValue[i]}`;
      } else if (i === 6 && numericValue.length <= 10) {
        formattedValue += `-${numericValue[i]}`;
      } else if (i === 7 && numericValue.length === 11) {
        formattedValue += `-${numericValue[i]}`;
      } else {
        formattedValue += numericValue[i];
      }
    }

    return formattedValue;
  };


  const handleTelefoneChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatarTelefone(inputValue);
    setWhatsapp(formattedValue);
  };


  const emailRedefinirSenha = async () => {
    if (email === '') {
      toast.error('Insira seu e-mail de cadastro para recuperar a senha.')
    } else {

      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/redefinir-senha`, {
          email
        })

        toast.success('Foi enviado um e-mail com instruções para redefinir sua senha.')

      } catch (err) {
        toast.error('Algo deu errado. Verifique se digitou seu e-mail corretamente.')
      }

    }

  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const verificarEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleCadastro = async () => {

    if (nome.trim().split(" ").length === 1) {
      toast.error("Insira o nome completo")
      return;
    }

    if (!verificarEmail(email)) {
      toast.error("E-mail inválido")
      return;
    }

    if (whatsapp.length < 14) {
      toast.error("Digite um número de telefone válido")
      return;
    }

    if (nome === '' || email === '' || whatsapp === '') {
      toast.error('Todos campos são obrigatórios')
      return;
    }

    setLoading(true);

    try {

      const indicadorId = Cookies.get('indicadorId');
      let dataBody = {
        email,
        nome,
        telefone: whatsapp
      }

      if (indicadorId) {
        dataBody.indicador = indicadorId
      }

      const { status, data } = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/usuarios/cadastro', dataBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      toast.success('Cadastro realizado com sucesso. Em instantes você irá receber um e-mail com seus dados de acesso.')

      localStorage.setItem('token', data.token);
      localStorage.setItem('user', data.usuario._id);

      dispatch(setUsuario(data.usuario));

      window.location.replace('/');

    } catch (err) {
      console.log(err)
      if (err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error)
      } else {
        toast.error('Algo deu errado com seu cadastro.')
      }
    }


    setLoading(false)
  };

  const handleLogin = async () => {
    if (!search.includes("redefinir-senha")) {
      try {
        const { data: { token, usuario } } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/login`,
          { email, senha: password }
        );

        // Armazena o token e o ID do usuário
        localStorage.setItem('token', token);
        localStorage.setItem('user', usuario._id);

        dispatch(setUsuario(usuario));

        window.location.replace('/');
      } catch (error) {
        setEmail('');
        setPassword('');
        const mensagemErro = error?.response?.data?.mensagem || "Algo deu errado";
        toast.error(mensagemErro);
      }
    } else {
      if (!password) {
        toast.error('A senha não pode ficar vazia');
        return;
      }

      if (password !== confirmarSenha) {
        toast.error('As duas senhas não coincidem');
        return;
      }

      try {
        const regex = /redefinir-senha=([^&]+)/;
        const match = search.match(regex);

        if (!match || !match[1]) {
          toast.error('Token inválido');
          return;
        }

        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/alterar-senha-token`,
          { token: match[1], newPassword: confirmarSenha }
        );

        if (data?.token) {
          localStorage.setItem('token', data.token);
        }

        toast.success('Senha alterada com sucesso!');
        window.location.replace('/login');
      } catch (error) {
        const mensagemErro = error?.response?.data?.mensagem || 'Algo deu errado';
        toast.error(mensagemErro);
        setPassword('');
        setConfirmarSenha('');
        setEmail('');
        window.location.replace('/login');
      }
    }
  };


  useEffect(() => {

    setEmail('')
    setPassword('')
    setConfirmarSenha('')
    setNome('')
    setWhatsapp('')

    /*if(!modoCadastro) {
      navigate(window.location.pathname);
    }*/

  }, [modoCadastro])

  useEffect(() => {

    let isMounted = true

    const getWhatsApp = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + "/config")


        if (response.data && response.data.whatsapp) {
          setWhatsSuporte(response.data.whatsapp.replaceAll(/\D/g, ""));
        }

      } catch (err) {

      }
    }


    getWhatsApp()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Simbium</title>
        <meta
          property="og:title"
          content="Login - Simbium"
        />
      </Helmet>
      <div className="login-container1">
        <div className="login-logo">
          <img
            alt="image"
            src="/https___b3a6390b7a66b161ddd9c8f9e9c8f2de.cdn.bubble.io_f1689088670051x906951525380208500_logo_simbium-1500h.png"
            className="login-image"
          />
        </div>
        <div className="login-preencher">
          <img
            alt="image"
            src="/image%209-1500h.png"
            className="login-image1"
          />
          <div className="login-container2">
            <span className="login-text">{!search.includes("redefinir-senha") ? modoCadastro ? 'Crie sua conta' : 'Faça seu Login' : 'Redefinir Senha'}</span>
            <div className="w-4/5 md:w-3/4 space-y-4">
              {!search.includes("redefinir-senha") && modoCadastro && (
                <div className="flex items-center space-x-2 bg-neutral-700 p-2 rounded-md ring-0 border-none focus:outline-none focus:ring-0 focus:border-none">
                  <UserIcon className='w-6 h-6 text-white' />
                  <input
                    type="text"
                    placeholder="Nome"
                    className="w-full text-sm p-2 rounded-md bg-neutral-700 placeholder-gray-400 text-white ring-0 border-none focus:outline-none focus:ring-0 focus:border-none"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
              )}
              {!search.includes("redefinir-senha") && (
                <div className="flex items-center space-x-2 bg-neutral-700 p-2 rounded-md ring-0 border-none focus:outline-none focus:ring-0 focus:border-none">
                  <EnvelopeIcon className='w-6 h-6 text-white' />
                  <input
                    type="text"
                    placeholder="Email"
                    className="w-full text-sm p-2 rounded-md bg-neutral-700 placeholder-gray-400 text-white ring-0 border-none focus:outline-none focus:ring-0 focus:border-none"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              )}
              {!search.includes("redefinir-senha") && modoCadastro && (
                <div className="flex items-center space-x-2 bg-neutral-700 p-2 rounded-md ring-0 border-none focus:outline-none focus:ring-0 focus:border-none">
                  <PhoneIcon className='w-6 h-6 text-white' />
                  <input
                    type="text"
                    placeholder="Telefone/WhatsApp"
                    className="w-full text-sm p-2 rounded-md bg-neutral-700 placeholder-gray-400 text-white ring-0 border-none focus:outline-none focus:ring-0 focus:border-none"
                    value={whatsapp}
                    onChange={handleTelefoneChange}
                  />
                </div>
              )}
              {!modoCadastro && (
                <div className="flex items-center space-x-2 bg-neutral-700 p-2 rounded-md ring-0 border-none focus:outline-none focus:ring-0 focus:border-none">
                  <LockClosedIcon className='w-6 h-6 text-white' />
                  <input
                    type="password"
                    placeholder="Senha"
                    className="w-full text-sm p-2 rounded-md bg-neutral-700 placeholder-gray-400 text-white ring-0 border-none focus:outline-none focus:ring-0 focus:border-none"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              )}
              {search.includes("redefinir-senha") && (
                <div className="flex items-center space-x-2 bg-neutral-700 p-2 rounded-md ring-0 border-none focus:outline-none focus:ring-0 focus:border-none">
                  <LockOpenIcon className='w-6 h-6 text-white' />
                  <input
                    type="password"
                    placeholder="Nova senha"
                    className="w-full text-sm p-2 rounded-md bg-neutral-700 placeholder-gray-400 text-white ring-0 border-none focus:outline-none focus:ring-0 focus:border-none"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              )}
            </div>

            <div className="w-4/5 md:w-3/4 space-y-4">
              {!modoCadastro && (
                <div
                  className="flex items-center gap-2 w-full transition duration-300 pt-3 pl-3 pr-3 pb-3 rounded-lg justify-center bg-[#E86811] hover:bg-[#b7520e] cursor-pointer"
                  onClick={() => handleLogin()}
                >
                  {!search.includes("redefinir-senha") && (
                    <ArrowRightStartOnRectangleIcon className='w-5 h-5 text-white' />
                  )}
                  <span className="text-white">{search.includes("redefinir-senha") ? 'Alterar senha' : 'Entrar'}</span>
                </div>
              )}

              {!search.includes("redefinir-senha") && (
                <button
                  disabled={loading}
                  className={`flex gap-2 w-full transition duration-300 pt-3 pl-3 pr-3 pb-3 rounded-lg justify-center ${modoCadastro ? 'bg-[#E86811] hover:bg-[#b7520e]' : 'bg-gray-500 hover:bg-gray-500 hover:brightness-110'} cursor-pointer`}
                  onClick={() => !modoCadastro ? setModoCadastro(true) : handleCadastro()}
                >
                  <span className="text-white">{modoCadastro ? (loading ? 'Cadastrando...' : 'Cadastre-se') : 'Não tem conta? Crie aqui'}</span>
                </button>
              )}

              <div className="login-container6" onClick={() => {
                modoCadastro ?
                  setModoCadastro(false) :
                  window.open(`https://api.whatsapp.com/send?phone=${whatsSuporte}`)
              }}>
                {!modoCadastro && (
                  <InformationCircleIcon className='w-4 h-4 text-orange-500' />
                )}
                <span className="login-text3">{modoCadastro ? 'Já tenho uma conta' : 'Fale com o suporte'}</span>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
