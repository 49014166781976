import React, { useState, useEffect } from 'react';
import { PlusIcon, ChevronUpIcon, ChevronDownIcon, CheckBadgeIcon, PencilIcon, XMarkIcon, HomeIcon, MinusIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setUsuario } from '../../../features/usuario/usuarioAtual';
import { Provider, useSelector, useDispatch, useStore } from 'react-redux';
import AulaTrilhaAdmin from './acao_menu_opcoes/aula_trilha';
import OpcoesTrilhaAdmin from './opcoes';
import DivisorTrilhaAdmin from './divisor_trilha';
import AcaoMenu from './acao_menu';

const TrilhaAdmin = () => {
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const token = localStorage.getItem("token");
    const axios_header = { headers: { Authorization: `Bearer ${token}` } }

    const [opcao1, setOpcao1] = useState('sou-loja-fisica');
    const [opcao2, setOpcao2] = useState('');
    const [processando, setProcessando] = useState(false)
    const [trilhaSalva, setTrilhaSalva] = useState(true)

    const [showAcaoMenu, setShowAcaoMenu] = useState(null);

    const [trilha, setTrilha] = useState(null);

    const fetchTrilha = async () => {
        setProcessando(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/trilha/opcoes/${opcao1}/${opcao2}`,
                axios_header
            );

            if (response.status === 200) {
                setTrilha(response.data);
            } else {
                if (response.status !== 404) {
                    console.error("Erro ao carregar a trilha: ", response);
                    toast.error("Erro ao carregar a trilha");
                }
                setTrilha(null);
            }
        } catch (error) {
            if (error.status !== 404) {
                console.error("Erro ao carregar a trilha: ", error);
                toast.error("Erro ao carregar a trilha");
            }
            setTrilha(null);
        } finally {
            setProcessando(false);
        }
    };

    useEffect(() => {
        fetchTrilha();
    }, [opcao1, opcao2]);

    const setOpcao = (opcao, valor) => {
        if (opcao === 'opcao1') {
            if (opcao1 === valor) {
                setOpcao1('');
                setOpcao2('');
            } else {
                setOpcao1(valor);
            }
        } else if (opcao === 'opcao2') {
            if (!opcao1) {
                toast.error("Informe primeiro a opção 1");
            } else if (opcao2 === valor) {
                setOpcao2('');
            } else {
                setOpcao2(valor);
            }
        } else {
            console.error("Opção inexistente");
            toast.error('Opção inexistente');
        }
    };

    const criarTrilha = async () => {
        setProcessando(true);
        try {
            const { status, data } = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/trilha`,
                { opcao1, opcao2 }, // Simplificado
                axios_header
            );

            if (status === 201) {
                setTrilha(data);
                toast.success("Trilha cadastrada com sucesso!");
            } else {
                console.error("Erro ao cadastrar trilha:", status);
                toast.error("Erro ao cadastrar uma nova trilha");
            }
        } catch (error) {
            console.error("Erro ao cadastrar trilha:", error);
            toast.error("Erro ao cadastrar uma nova trilha");
        } finally {
            setProcessando(false);
        }
    };

    const handleInputUpdate = (e) => {
        const { name, value } = e.target;

        // Atualiza o estado da trilha com o novo valor do campo
        setTrilha(prevTrilha => ({
            ...prevTrilha,
            [name]: value
        }));

        // Define que há modificações a serem salvas
        setTrilhaSalva(false);
    };

    const salvarAlteracoesTrilha = async () => {
        // setProcessando(true);
        try {
            const { status, data } = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/trilha/${trilha._id}`,
                trilha, // Enviando o objeto diretamente
                axios_header
            );

            if (status === 200) {
                console.log("Alterações salvas com sucesso!");
                setTrilhaSalva(true);
            } else {
                console.error(`Erro ao salvar as alterações da trilha: Status ${status}`);
                toast.error("Erro ao salvar as alterações da trilha.");
            }
        } catch (error) {
            console.error("Erro ao salvar as alterações da trilha:", error);
            toast.error("Erro ao salvar as alterações da trilha.");
        } finally {
            //setProcessando(false);
        }
    };

    useEffect(() => {
        // Cria um debounce de 500ms
        const timeoutId = setTimeout(() => {
            // Verifica se as mudanças ainda não foram salvas
            if (!trilhaSalva) {
                // Salva as alterações se houver modificações
                salvarAlteracoesTrilha();
            }
        }, 700);

        // Limpa o timeout anterior se o usuário continuar digitando
        return () => clearTimeout(timeoutId);
    }, [trilha?.titulo, trilha?.descricao]); // Dispara o efeito ao alterar o título ou a descrição

    useEffect(() => {
        if (trilha?.acoes && !trilhaSalva) {
            salvarAlteracoesTrilha();
        }
    }, [trilha?.acoes]);

    const isAcaoInTrilha = (acao_name, dado = null) => {
        if (acao_name === 'assistir-aula-trilha') {
            return trilha?.acoes?.some(acao_trilha => acao_trilha?.dado === dado) || false;
        }
        return trilha?.acoes?.some(acao_trilha => acao_trilha.acao === acao_name) || false;
    }

    const setAcaoEmTrilha = (acao) => {
        setTrilhaSalva(false);

        // Retorna se a trilha ou a lista de ações da trilha não estiverem definidas
        if (!trilha || !trilha.acoes) return;

        let acoes = null;
        if (acao.acao === 'assistir-aula-trilha') {
            acoes = trilha.acoes.some(acao_trilha => acao_trilha.dado === acao.dado)
                ? trilha.acoes.filter(acao_trilha => (acao_trilha.dado !== acao.dado)) // Remove a ação existente
                : [...trilha.acoes, acao]; // Adiciona a nova ação
        } else {
            acoes = trilha.acoes.some(acao_trilha => acao_trilha.acao === acao.acao)
                ? trilha.acoes.filter(acao_trilha => (acao_trilha.acao !== acao.acao)) // Remove a ação existente
                : [...trilha.acoes, acao]; // Adiciona a nova ação
        }
        // Define a nova lista de ações


        // Atualiza a trilha com a nova lista de ações
        setTrilha(prev => ({
            ...prev,
            acoes,
        }));
    };


    const swapAcao = (sentido, acao) => {
        setTrilhaSalva(false)
        setTrilha(prevTrilha => {
            const acaoIndex = prevTrilha.acoes.findIndex(acaoItem => acaoItem.acao === acao);

            if (
                acaoIndex === -1 || // Ação não encontrada
                acaoIndex + sentido < 0 || // Previne troca acima do primeiro item
                acaoIndex + sentido >= prevTrilha.acoes.length // Previne troca abaixo do último item
            ) {
                return prevTrilha; // Retorna o estado anterior se a troca for inválida
            }

            const novasAcoes = [...prevTrilha.acoes]; // Faz uma cópia do array
            [novasAcoes[acaoIndex], novasAcoes[acaoIndex + sentido]] = [novasAcoes[acaoIndex + sentido], novasAcoes[acaoIndex]]; // Realiza a troca

            return {
                ...prevTrilha,
                acoes: novasAcoes
            };
        });
    };

    const excluirTrilha = async (trilha_id, trilha_titulo) => {
        if (!confirm(`Você tem certeza que deseja excluir a trilha '${trilha_titulo}'? Esta ação é irreversível!`)) {
            return;
        }
        setProcessando(true);
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/trilha/${trilha_id}`,
                axios_header
            );

            if (response.status === 200) {
                toast.success("Trilha excluída com sucesso!");
                setTrilha(null);
                setTrilhaSalva(true);
            } else {
                console.error(`Erro ao excluir trilha: ${response}`);
                toast.error("Erro ao excluir trilha.");
            }
        } catch (error) {
            console.error("Erro ao excluir trilha: ", error);
            toast.error("Erro ao excluir trilha.");
        } finally {
            setProcessando(false);
        }
    };


    return (
        <div className="gap-5 w-full flex p-8 relative flex-col pb-8 justify-start bg-neutral-800 rounded-xl">
            <div className="w-full mx-auto">
                <h1 className="text-white text-2xl">Trilha</h1>

                <OpcoesTrilhaAdmin
                    processando={processando}
                    opcao1={opcao1}
                    opcao2={opcao2}
                    setOpcao={setOpcao}
                />

                <h2 className='text-neutral-300 text-lg text-center my-10'>Monte a trilha da combinação acima:</h2>
                <div className=''>
                    <div className='h-full flex flex-col items-center align-middle justify-center'>
                        {!opcao1 &&
                            <div className='text-lg text-center'>Selecione ao menos uma opção da Lista 1</div>
                        }
                        {opcao1 && !trilha &&
                            <div className='text-lg text-center space-y-5'>
                                <p className='text-sm'>Não há trilha criada para a(s) opção(ões) selecionada(s)</p>
                                <button className='bg-neutral-600 hover:bg-neutral-700 active:bg-neutral-900 px-5 py-3 rounded-xl' disabled={processando} onClick={criarTrilha}>Criar nova trilha</button>
                            </div>
                        }
                        {trilha &&
                            <>
                                <div className='w-1/2 space-y-5 mb-10'>
                                    <button className='w-full text-end justify-end text-red-500 text-xs' onClick={() => excluirTrilha(trilha._id)}>Excluir trilha</button>

                                    <input type="text" value={trilha.titulo} name="titulo" onChange={e => handleInputUpdate(e)} className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50' disabled={processando} placeholder="Título" />

                                    <textarea
                                        value={trilha.descricao}
                                        name="descricao"
                                        onChange={e => handleInputUpdate(e)}
                                        className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500 disabled:opacity-50' disabled={processando}
                                        placeholder="Descrição (Opcional)"
                                        rows="2"
                                    />

                                    <div className='text-center'>
                                        {trilhaSalva ? <span className='text-xs text-center text-neutral-500'>
                                            Alterações Salvas
                                        </span> : <span className='flex items-center gap-3 text-xs text-center text-orange-100'>
                                            <ArrowPathIcon className='w-5 h-5 animate-spin' />
                                            Salvando alterações
                                        </span>}
                                    </div>
                                </div>

                                <div className='w-full flex gap-5'>
                                    <div className='w-1/2 mx-auto flex flex-col gap-5'>


                                        <DivisorTrilhaAdmin
                                            trilhaId={trilha._id}
                                            processando={processando}
                                            setProcessando={setProcessando}
                                            divisores={trilha.divisoes}
                                            trilhaSalva={trilhaSalva}
                                            setTrilhaSalva={setTrilhaSalva}
                                            fetchTrilha={fetchTrilha}
                                            showAcaoMenu={showAcaoMenu}
                                            setShowAcaoMenu={setShowAcaoMenu}
                                        />
                                    </div>
                                    {showAcaoMenu &&
                                        <AcaoMenu
                                            processando={processando}
                                            setProcessando={setProcessando}
                                            divisor={trilha.divisoes.find(div => div._id === showAcaoMenu)}
                                            trilhaSalva={trilhaSalva}
                                            setTrilhaSalva={setTrilhaSalva}
                                            fetchTrilha={fetchTrilha}
                                            setShowAcaoMenu={setShowAcaoMenu}
                                        />
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrilhaAdmin;
