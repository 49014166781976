import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const ModalAulaTrilhaAdmin = ({ aula, setAula, aulaErrors, setAulaErrors, createAula, updateAula, closeModal, processing, videoAula, setVideoAula, uploadingVideo }) => {
    const [disabled, setDisabled] = useState(false);
    const [videoPreview, setVideoPreview] = useState(aula?.video ?? '');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAula({
            ...aula,
            [name]: value,
        });
    };

    const handleVideoChange = (e) => {
        const file = e.target.files[0] ?? null;
        const file_type = file?.type ?? aula.video_tipo ?? "";
        const ext = file?.name.split('.').pop();

        if (file && ext && (file_type === "video/mp4" || file_type === "video/webm" || file_type === "video/ogg" || file_type === "video/x-matroska")) {
            setVideoAula(file);

            setAula(aula => ({
                ...aula,
                video_tipo: ext
            }));

            setVideoPreview(URL.createObjectURL(file)); // Gera uma URL para pré-visualização
        } else {
            toast('Por favor, selecione um vídeo no formato MP4, WebM, OGG ou MKV.');
        }
    };

    useEffect(() => {
        if (!aula.titulo || (!videoAula && !aula.video)) setDisabled(true)
        else setDisabled(false);
    }, [aula, videoAula])

    return (
        <div className="fixed top-0 left-0 w-screen min-h-screen flex justify-center align-middle items-center z-40">
            <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={() => closeModal()}></div>
            <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                <div className="border-b border-neutral-700 py-5 space-y-2 flex justify-between items-center">
                    <img src='/main_logo.png' className='h-7' />
                    <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={() => closeModal()}>&times;</button>
                </div>
                <div className="py-5 flex flex-col gap-5 w-2/3 m-auto">
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="titulo" className='text-xs md:text-sm'>Nome</label>
                        <input type="text" id="titulo" name="titulo" value={aula.titulo} onChange={handleInputChange} required placeholder="Indira um nome identificador do aula" autoFocus className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                        {aulaErrors.titulo && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{aulaErrors.name}</p>}
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <label htmlFor="descricao" className='text-xs md:text-sm'>Descrição:</label>
                        <input type="text" id="descricao" name="descricao" value={aula.descricao} onChange={handleInputChange} required placeholder="Insira uma descrição detalhada" className='w-full text-sm p-2 rounded-md bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:border-none focus:shadow-none focus:ring-1 focus:ring-orange-500' />
                        {aulaErrors.descricao && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{aulaErrors.descricao}</p>}
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <label
                            htmlFor="video"
                            className='cursor-pointer py-2 px-3 md:py-3 md:px-4 rounded-md text-xs font-semibold md:text-sm bg-orange-600 hover:bg-orange-700 text-white text-center'>
                            Selecione um vídeo (MP4 ou MKV)
                        </label>
                        <input
                            type="file"
                            id="video"
                            name="video"
                            accept=".mp4, .mkv, .webm, .ogg"
                            onChange={handleVideoChange}
                            className='hidden' // Esconde o campo de input original
                        />
                        {aulaErrors.video && <p className='text-red-500 text-xs md:text-sm pt-1 pb-2'>{aulaErrors.video}</p>}

                        {(videoPreview) && (
                            <div className='mt-4'>
                                <video src={videoPreview} controls className="w-full max-h-64 rounded-md shadow-md" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="border-t border-neutral-700 py-5 select-none flex justify-between items-center">
                    <div>
                        {uploadingVideo &&
                            <p
                                className="text-xs text-orange-500 animate-pulse"
                            >
                                Upload em andamento
                            </p>
                        }
                    </div>
                    <div className='flex flex-row justify-end items-center align-middle gap-x-3'>
                        <button
                            className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-neutral-600 hover:bg-neutral-700"
                            disabled={processing}
                            onClick={() => closeModal()}
                        >
                            Cancelar
                        </button>
                        <button
                            className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs font-semibold md:text-sm bg-orange-600 hover:bg-orange-700 disabled:bg-neutral-800"
                            disabled={processing || disabled}
                            onClick={aula._id ? updateAula : createAula}
                        >
                            {aula._id ? 'Editar Aula' : 'Criar Aula'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalAulaTrilhaAdmin