const OpcoesTrilhaAdmin = ({ processando, opcao1, opcao2, setOpcao }) => {

    return (
        <div className='w-full grid grid-cols-1 md:grid-cols-2 divide-y divide-neutral-500 md:divide-y-0 md:divide-x'>
            <div className='p-5'>
                <h2 className='text-orange-500'>Lista 1</h2>
                <label htmlFor="op1" className="flex items-center text-white my-5">
                    <input
                        id="op1"
                        type="checkbox"
                        checked={opcao1 === 'sou-loja-fisica'}
                        onChange={() => setOpcao('opcao1', 'sou-loja-fisica')}
                        className="mr-2 rounded disabled:opacity-50"
                        disabled={processando}
                    />
                    Sou loja física
                </label>
                <label htmlFor="op2" className="flex items-center text-white my-5">
                    <input
                        id="op2"
                        type="checkbox"
                        checked={opcao1 === 'ja-vendo-online'}
                        onChange={() => setOpcao('opcao1', 'ja-vendo-online')}
                        className="mr-2 rounded disabled:opacity-50"
                        disabled={processando}
                    />
                    Já vendo online
                </label>
                <label htmlFor="op3" className="flex items-center text-white my-5">
                    <input
                        id="op3"
                        type="checkbox"
                        checked={opcao1 === 'estou-comecando-do-zero'}
                        onChange={() => setOpcao('opcao1', 'estou-comecando-do-zero')}
                        className="mr-2 rounded disabled:opacity-50"
                        disabled={processando}
                    />
                    Estou começando do zero
                </label>
            </div>

            <div className='p-5'>
                <h2 className='text-orange-500'>Lista 2</h2>
                <label htmlFor="op4" className="flex items-center text-white my-5">
                    <input
                        id="op4"
                        type="checkbox"
                        checked={opcao2 === 'estou-comecando-do-absoluto-zero'}
                        onChange={() => setOpcao('opcao2', 'estou-comecando-do-absoluto-zero')}
                        className="mr-2 rounded disabled:opacity-50"
                        disabled={processando || !opcao1}
                    />
                    Estou começando do absoluto zero
                </label>
                <label htmlFor="op5" className="flex items-center text-white my-5">
                    <input
                        id="op5"
                        type="checkbox"
                        checked={opcao2 === 'entre-10k-e-20k-por-mes-em-vendas'}
                        onChange={() => setOpcao('opcao2', 'entre-10k-e-20k-por-mes-em-vendas')}
                        className="mr-2 rounded disabled:opacity-50"
                        disabled={processando || !opcao1}
                    />
                    Entre 10K e 20K por mês em vendas
                </label>
                <label htmlFor="op6" className="flex items-center text-white my-5">
                    <input
                        id="op6"
                        type="checkbox"
                        checked={opcao2 === 'mais-de-20k-por-mes-em-vendas-querendo-escalar'}
                        onChange={() => setOpcao('opcao2', 'mais-de-20k-por-mes-em-vendas-querendo-escalar')}
                        className="mr-2 rounded disabled:opacity-50"
                        disabled={processando || !opcao1}
                    />
                    Mais de 20K por mês em vendas, querendo escalar
                </label>
                <label htmlFor="op7" className="flex items-center text-white my-5">
                    <input
                        id="op7"
                        type="checkbox"
                        checked={opcao2 === 'lancar-e-comerce-do-zero-ja-vendo-online'}
                        onChange={() => setOpcao('opcao2', 'lancar-e-comerce-do-zero-ja-vendo-online')}
                        className="mr-2 rounded disabled:opacity-50"
                        disabled={processando || !opcao1}
                    />
                    Lançar E-Commerce do Zero (já vendo online)
                </label>
            </div>
        </div>
    )
}

export default OpcoesTrilhaAdmin;