import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';

const FranquiaSimbium = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const [showMenu, setShowMenu] = useState(false);
    const [sendContact, setSendContact] = useState(false);

    const mixPanelAcesso = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual or usuarioAtual._id is missing');
            return;
        }
        mixpanel.track("Franquia Simbium | Acesso à página", {
            distinct_id: usuarioAtual._id,
        });
    }

    const [diferencaTempo, setDiferencaTempo] = useState(0);

    useEffect(() => {
        mixPanelAcesso();

        const interval = setInterval(() => {
            setDiferencaTempo(prevTempo => prevTempo + 1);
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const mixPanelContato = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual ou usuarioAtual._id ausente');
            return;
        }
        mixpanel.track("Franquia Simbium | Contato", {
            distinct_id: usuarioAtual._id,
            tempo_convencimento: diferencaTempo,
        });
    }

    const sendEmail = () => {
        setSendContact(true);
        console.log(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`);

        axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/send-email`,
            {
                recipientName: usuarioAtual.name,
                recipientEmail: usuarioAtual.email,
                subject: 'QUERO SER UM FRANQUEADO SIMBIUM',
                htmlContent: `Nome: ${usuarioAtual.nome}<br>Email: ${usuarioAtual.email}<br>Telefone: ${usuarioAtual.telefone || "Não possui"}`
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        )
            .then(response => {
                mixPanelContato();
                console.log("RESPOSTA DO SERVIDOR: ", response);
                toast.success('E-mail enviado com sucesso!');
            })
            .catch(error => {
                console.log("RESPOSTA DO SERVIDOR: ", error);
                toast.error('Erro ao enviar e-mail. Por favor, tente novamente mais tarde');
                setSendContact(false);
            });
    }


    return (
        <div className='w-full'>
            <Helmet>
                <title>Simbium</title>
                <meta property="og:title" content="Simbium" />
            </Helmet>
            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
            <div className='bg-neutral-900 flex flex-col font-poppins'>
                <div className='w-full flex flex-row md:px-5 gap-10'>
                    {showMenu &&
                        <>
                            <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                            <div className='hidden w-0 lg:block lg:w-3/12'></div>
                        </>
                    }
                    <div className={`${showMenu ? 'w-full lg:w-9/12' : 'sm:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
                        <div className="flex flex-col w-full bg-orange-700 rounded-lg text-white">
                            {/* Hero Section */}
                            <div className="text-center py-10  text-neutral-50  w-10/12 mx-auto">
                                <h1 className="text-xl md:text-3xl lg:text-5xl font-extrabold leading-tight mb-4 text-white">
                                    Comece a vender online com agilidade e segurança com a franquia Simbium
                                </h1>
                                <p className="text-xs md:texto-sm lg:text-lg mt-2">
                                    Você quer começar a vender online, mas não sabe por onde começar? A franquia Simbium é a solução perfeita para você.
                                </p>
                            </div>

                            {/* Video Section
                            <div className="flex justify-center md:mb-5 lg:mb-10">
                                <div className="w-full md:w-3/4 ">
                                    <div className="aspect-w-16 aspect-h-9 bg-gray-800 flex items-center justify-center overflow-hidden shadow-xl shadow-orange-950">
                                        <video controls className="w-full h-full object-cover">
                                            <source src="caminho-do-video.mp4" type="video/mp4" />
                                            Seu navegador não suporta o elemento de vídeo.
                                        </video>
                                    </div>
                                </div>
                            </div>
                             */}

                            {/* Benefits Section */}
                            <div className="text-center bg-neutral-950 p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-32 flex flex-col gap-12 md:gap-24">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold">
                                    Com a franquia Simbium, você terá:
                                </h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 xl:gap-20 select-none">
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z" /></svg>
                                        <p className="text-lg">Um negócio pronto para começar a operar</p>
                                    </div>
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" /></svg>
                                        <p className="text-lg">Treinamento e suporte completos</p>
                                    </div>
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="m438-338 226-226-57-57-169 169-84-84-57 57 141 141Zm42 258q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-84q104-33 172-132t68-220v-189l-240-90-240 90v189q0 121 68 220t172 132Zm0-316Z" /></svg>
                                        <p className="text-lg">Uma marca reconhecida e confiável</p>
                                    </div>
                                    <div className="flex flex-col gap-5 w-full mx-auto items-center bg-neutral-900 hover:bg-neutral-800 rounded-lg p-5 border-b-4 border-orange-700 shadow-md shadow-neutral-900">
                                        <svg className='w-14 h-14 fill-neutral-600' viewBox="0 -960 960 960"><path d="M475-160q4 0 8-2t6-4l328-328q12-12 17.5-27t5.5-30q0-16-5.5-30.5T817-607L647-777q-11-12-25.5-17.5T591-800q-15 0-30 5.5T534-777l-11 11 74 75q15 14 22 32t7 38q0 42-28.5 70.5T527-522q-20 0-38.5-7T456-550l-75-74-175 175q-3 3-4.5 6.5T200-435q0 8 6 14.5t14 6.5q4 0 8-2t6-4l136-136 56 56-135 136q-3 3-4.5 6.5T285-350q0 8 6 14t14 6q4 0 8-2t6-4l136-135 56 56-135 136q-3 2-4.5 6t-1.5 8q0 8 6 14t14 6q4 0 7.5-1.5t6.5-4.5l136-135 56 56-136 136q-3 3-4.5 6.5T454-180q0 8 6.5 14t14.5 6Zm-1 80q-37 0-65.5-24.5T375-166q-34-5-57-28t-28-57q-34-5-56.5-28.5T206-336q-38-5-62-33t-24-66q0-20 7.5-38.5T149-506l232-231 131 131q2 3 6 4.5t8 1.5q9 0 15-5.5t6-14.5q0-4-1.5-8t-4.5-6L398-777q-11-12-25.5-17.5T342-800q-15 0-30 5.5T285-777L144-635q-9 9-15 21t-8 24q-2 12 0 24.5t8 23.5l-58 58q-17-23-25-50.5T40-590q2-28 14-54.5T87-692l141-141q24-23 53.5-35t60.5-12q31 0 60.5 12t52.5 35l11 11 11-11q24-23 53.5-35t60.5-12q31 0 60.5 12t52.5 35l169 169q23 23 35 53t12 61q0 31-12 60.5T873-437L545-110q-14 14-32.5 22T474-80Zm-99-560Z" /></svg>
                                        <p className="text-lg">Compra com venda certa</p>
                                    </div>
                                </div>
                            </div>


                            {/* Why Franchise Section */}
                            <div className="flex flex-col items-center gap-12 md:gap-24 bg-neutral-800 p-5 py-10 lg:p-20 lg:py-24 shadow-md text-neutral-50">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-center">
                                    Por que franquia?
                                </h2>
                                <ul className="flex flex-col text-xs md:text-lg lg:text-xl divide-y divide-neutral-700 text-neutral-300 w-11/12 mx-auto">
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className='whitespace-nowrap'>Know-how:</strong> O franqueado tem acesso ao conhecimento técnico de como gerir o negócio e seguir os métodos e sistemas estabelecidos pelo franqueador.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className='whitespace-nowrap'>Suporte:</strong> O franqueador oferece suporte ao franqueado durante o desenvolvimento do negócio, servindo como modelo de referência e rede de apoio.</p>
                                    </li>
                                    <li className="py-5 flex flex-row gap-5">
                                        <span><CheckBadgeIcon className='w-7 h-7 text-yellow-500' /></span>
                                        <p><strong className='whitespace-nowrap'>Velocidade:</strong> Começa na frente da concorrência, com maior margem e maior rentabilidade.</p>
                                    </li>
                                </ul>

                            </div>


                            {/* Contact Section */}
                            <div className="text-center bg-neutral-950 p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-32 flex flex-col gap-12 md:gap-24">
                                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold ">
                                    Seja seu próprio patrão e tenha a liberdade de trabalhar de onde quiser.
                                </h2>
                                <ul className="mt-4 space-y-2 text-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg>
                                        Escolher seus próprios horários de trabalho
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="m438-426 198-198-57-57-141 141-56-56-57 57 113 113Zm42 240q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" /></svg>
                                        Trabalhar de casa ou de qualquer outro lugar com acesso à internet
                                    </li>
                                    <li className="mx-auto p-5 hover:bg-neutral-900 rounded-lg">
                                        <svg className="w-14 h-14 mx-auto mb-5 fill-orange-500" viewBox="0 -960 960 960"><path d="M160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q20 0 40 1.5t40 4.5v81q-20-4-40-5.5t-40-1.5q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32h320v80H160Zm80-80h320-320Zm240-240q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80ZM720 0v-200h-80v-240h240l-80 160h80L720 0Z" /></svg>
                                        Ser seu próprio chefe e tomar suas próprias decisões
                                    </li>
                                </ul>
                            </div>

                            <div className="text-center bg-orange-700 rounded-b-lg p-5 lg:p-20 shadow-md text-neutral-50 py-10 lg:py-32 flex flex-col gap-10">
                                <h2 className="text-xl md:text-3    xl lg:text-5xl font-bold ">
                                    Modelo de Micro-Franquias a partir de R$10.000
                                </h2>
                                <p className="text-xs md:text-lg lg:text-xl">
                                    Não perca essa oportunidade única de começar seu próprio negócio online com a franquia Simbium. Entre em contato hoje mesmo e saiba mais.
                                </p>
                                {!sendContact ?
                                    <button className='p-5 w-full bg-green-500 hover:animate-none hover:bg-green-400 active:bg-green-600 animate-pulse text-orange-50 text-lg md:text-xl lg:text-2xl rounded-lg' onClick={() => sendEmail()}>Entrar em contato por e-mail</button>
                                    :
                                    <p className='text-orange-100'>Contato enviado. Entraremos em contato assim que possível {";)"}</p>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default FranquiaSimbium