import React, { useState } from 'react';

const MarketplaceAcaoMenuTrilha = ({ processando, marketplaces, cursos, setarAcao }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Unifica e filtra os marketplaces e cursos
    const filteredLista = [
        ...marketplaces.map(item => ({
            _id: item._id,
            fonte: 'marketplace',
            capa: item.capa,
            nome: item.nome,
            descricao: item.descricao,
            tipo: 'marketplace'
        })),
        ...cursos.map(item => ({
            _id: item._id,
            fonte: 'curso',
            capa: item.imagem,
            nome: item.titulo,
            descricao: item.descricao,
            tipo: 'curso'
        }))
    ].filter(option =>
        option.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.descricao.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className=''>
            {/* Campo de pesquisa */}
            <input
                type="text"
                placeholder="Filtrar lista..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={processando}
                className="w-full p-2 mb-4 text-xs rounded-md border border-neutral-400 bg-neutral-800 text-neutral-100"
            />

            {/* Grid para exibir os itens filtrados */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 select-none">
                {filteredLista.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => !processando && setarAcao(item._id, item.fonte)}
                        className={`rounded overflow-hidden shadow-lg bg-neutral-900 hover:border-2 cursor-pointer ${processando ? 'pointer-events-none opacity-50' : ''}`}
                    >
                        <div
                            className="h-36 bg-neutral-800 bg-cover bg-center aspect-auto"
                            style={{ backgroundImage: `url('${item.capa}')` }}
                        ></div>
                        <div className="p-4">
                            <p className="text-sm text-neutral-200 font-semibold">
                                {item.nome.length > 20
                                    ? `${item.nome.slice(0, 20)}...`
                                    : item.nome}
                            </p>
                            <p className="text-xs text-neutral-400 mt-1">
                                {item.descricao.length > 50
                                    ? `${item.descricao.slice(0, 50)}...`
                                    : item.descricao}
                            </p>
                            <p className="text-xs mt-1 italic text-orange-300">
                                {item.tipo === 'marketplace' ? 'Marketplace' : 'Master'}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MarketplaceAcaoMenuTrilha;
