import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios'
import styles from './configuracoes.module.css'
import { debounce } from 'lodash';
import { WrenchIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';


const TabelaConfiguracoes = (props) => {

  const [config, setConfig] = useState({
    whatsapp: '',
    boas_vindas: {
      url: '', // Apenas URL será usada agora
    },
    premiacoes: {
      indicacao: '',
      iniciar_curso: '',
      concluir_aula: '',
      escrever_nota: '',
      favoritar_aula: '',
      completou_onboarding: ''
    }
  });

  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');

  const getConfig = async () => {
    try {

      const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/config', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })

      if (response.data) {
        delete response.data._id;
        setConfig(response.data)
      }

    } catch (err) {

    }
  }

  useEffect(() => {
    getConfig()
  }, [])

  const salvarConfig = async (novo_url_video = null) => {
    console.log("NOVO URL VIDEO: ", novo_url_video)
    console.log("BOAS VINDAS URL: ", config.boas_vindas.url)
    const url_video = novo_url_video ?? config.boas_vindas.url
    if (url_video) {
      setConfig(prevConfig => ({
        ...prevConfig,
        boas_vindas: {
          ...prevConfig.boas_vindas,
          url: url_video // Atualiza a URL no estado
        }
      }));
    }
    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/config`,
        config,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Configurações salvas com sucesso!");
      } else {
        console.error("Erro ao salvar configurações:", response);
        toast.error(`Erro ao salvar configurações: ${response.statusText || "Erro desconhecido"}`);
      }
    } catch (err) {
      console.error("Erro ao salvar configurações:", err);
      toast.error(`Erro ao salvar configurações: ${err.response?.data?.message || err.message || "Erro desconhecido"}`);
    } finally {
      setLoading(false);
    }
  };

  const getSignedUrl = async (file) => {
    try {
      const ext = file.name.split('.').pop();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/config/upload/signed-url`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            ext: `.${ext}`, // Passar a extensão do arquivo
            contentType: file.type, // Passar o tipo de conteúdo
          },
        }
      );
      return response.data;
    } catch (err) {
      console.error('Erro ao obter URL assinada', err);
      toast.error("Erro ao gerar URL para upload.");
      return null;
    }
  };

  // Função para realizar o upload do vídeo
  const uploadVideo = async (file) => {
    setLoading(true); // Indicar que o upload está em progresso

    const { uploadUrl, fileName } = await getSignedUrl(file);

    try {
      // Faz o upload do arquivo para a URL assinada
      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type
        }
      });

      const publicUrl = `https://storage.googleapis.com/${process.env.REACT_APP_GOOGLE_CLOUD_BUCKET_NAME}/${fileName}`;
      // Armazena a URL pública do vídeo e atualiza o estado de configuração
      setVideoUrl(publicUrl);
      toast.success("Vídeo carregado com sucesso!");
      return publicUrl;
    } catch (err) {
      console.error('Erro ao fazer upload do vídeo', err);
      toast.error("Erro ao carregar o vídeo.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Função chamada ao selecionar um vídeo
  const handleVideoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideoFile(file);
      const novo_url_video = await uploadVideo(file); // Inicia o upload assim que o vídeo for selecionado
      if (novo_url_video)
        await salvarConfig(novo_url_video);
    }
  };

  /*const fetchVideosPesquisa = async (searchBunny) => {
    setVideo('')
    setVideosPesquisa(["loading"]);

    if (searchBunny !== '') {
      try {

        const response = await axios.get(`https://video.bunnycdn.com/library/${process.env.REACT_APP_BUNNY_STREAM_LIBRARY_ID}/videos`, {
          params: {
            page: 1,
            itemsPerPage: 4,
            orderBy: 'date',
            search: searchBunny
          },
          headers: {
            accept: 'application/json',
            AccessKey: `${process.env.REACT_APP_BUNNY_STREAM_TOKEN}`
          }
        })


        if (response.data && response.data.items && response.data.items.length > 0) {
          const items = response.data.items.map(item => {
            item.capa = `https://vz-01910c8b-ac9.b-cdn.net/${item?.guid}/thumbnail.jpg`
            item.frame = `<div style="position:relative;padding-top:56.25%;"><iframe src="https://iframe.mediadelivery.net/embed/${process.env.REACT_APP_BUNNY_STREAM_LIBRARY_ID}/${item?.guid}?autoplay=true&loop=false&muted=false&preload=true" loading="lazy" style="border:none;position:absolute;top:0;height:100%;width:100%;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe></div>`;
            return item;
          })
          setVideosPesquisa(items.slice(0, 2))
        } else {
          setVideosPesquisa([])
        }

      } catch (err) {
      }
    } else {
      setVideosPesquisa([])
    }
  }*/

  // const debouncedFetchVideosPesquisa = debounce(fetchVideosPesquisa, 300)

  const formatarTelefone = (input) => {
    const numericValue = input.replace(/\D/g, '');

    const maxLength = 11;
    if (numericValue.length > maxLength) {
      return config.whatsapp;
    }

    let formattedValue = '';

    for (let i = 0; i < numericValue.length; i++) {
      if (i === 0) {
        formattedValue += `(${numericValue[i]}`;
      } else if (i === 2) {
        formattedValue += `) ${numericValue[i]}`;
      } else if (i === 6 && numericValue.length <= 10) {
        formattedValue += `-${numericValue[i]}`;
      } else if (i === 7 && numericValue.length === 11) {
        formattedValue += `-${numericValue[i]}`;
      } else {
        formattedValue += numericValue[i];
      }
    }

    return formattedValue;
  };

  const handleTelefoneChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatarTelefone(inputValue);

    setConfig((prevState) => ({
      ...prevState,
      whatsapp: formattedValue
    }));
  };


  return (
    <div className="flex flex-col gap-10 w-full p-8 pb-8 justify-start bg-neutral-900">
      <div className="flex items-center justify-between">
        <span className="text-white text-2xl font-medium">Configurações</span>
        <button
          disabled={loading}
          className="flex gap-2 items-center justify-center px-5 py-6 h-11 rounded-lg bg-orange-500 text-white cursor-pointer"
          onClick={() => salvarConfig()}
        >
          <WrenchScrewdriverIcon className="w-4 h-4" />
          <span className="text-white text-base font-medium">{!loading ? 'Salvar Configurações' : 'Salvando...'}</span>
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="flex flex-col gap-4 p-8 rounded-lg bg-neutral-700 w-full">
          <span className="text-white text-2xl font-bold mb-5">Dados Simbium</span>
          <div className="flex flex-col gap-2">
            <span className="text-white">WhatsApp Suporte</span>
            <input
              type="text"
              value={config.whatsapp}
              onChange={handleTelefoneChange}
              className="w-full h-10 p-5 text-sm rounded-lg bg-neutral-600 text-neutral-300 outline-none"
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-white">Vídeo Boas Vindas</span>
            <input id="video-upload" type="file" accept="video/*" className="hidden" onChange={handleVideoChange} />
            <input
              className="w-full h-10 p-5 text-sm rounded-lg bg-neutral-600 text-neutral-300 outline-none cursor-pointer"
              value={config.boas_vindas.url}
              onClick={() => document.getElementById('video-upload').click()}
              readOnly
            />
            <video
              src={`${config?.boas_vindas?.url || videoUrl || ""}?timestamp=${new Date().getTime()}`}
              controls
              width="400"
              onContextMenu={() => false}
              className='mx-auto'
            ></video>
            {loading && <p className="text-orange-500 text-xs py-1 text-center animate-pulse">Salvando Alterações...</p>}
          </div>
        </div>

        <div className="space-y-4 p-8 rounded-lg bg-neutral-700 w-full">
          <span className="text-white text-2xl font-bold mb-5">Premiações</span>

          <div className='grid grid-cols-1 xl:grid-cols-2 gap-2'>
            <div className="flex flex-col gap-2">
              <span className="text-white">Indicação</span>
              <input
                type="number"
                value={config.premiacoes.indicacao}
                className="w-full h-10 p-5 text-sm rounded-lg bg-neutral-600 text-neutral-300 outline-none"
                onChange={(e) => setConfig(prevState => ({
                  ...prevState,
                  premiacoes: {
                    ...prevState.premiacoes,
                    indicacao: e.target.value,
                  },
                }))}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-white">Iniciar curso</span>
              <input
                type="number"
                value={config.premiacoes.iniciar_curso}
                className="w-full h-10 p-5 text-sm rounded-lg bg-neutral-600 text-neutral-300 outline-none"
                onChange={(e) => {
                  const newConfig = {
                    ...config,
                    premiacoes: {
                      ...config.premiacoes,
                      iniciar_curso: e.target.value,
                    },
                  };
                  setConfig(newConfig);
                }}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-white">Concluir aula</span>
              <input
                type="number"
                value={config.premiacoes.concluir_aula}
                className="w-full h-10 p-5 text-sm rounded-lg bg-neutral-600 text-neutral-300 outline-none"
                onChange={(e) => {
                  const newConfig = {
                    ...config,
                    premiacoes: {
                      ...config.premiacoes,
                      concluir_aula: e.target.value,
                    },
                  };
                  setConfig(newConfig);
                }}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-white">Escrever nota</span>
              <input
                type="number"
                value={config.premiacoes.escrever_nota}
                className="w-full h-10 p-5 text-sm rounded-lg bg-neutral-600 text-neutral-300 outline-none"
                onChange={(e) => {

                  const newConfig = {
                    ...config,
                    premiacoes: {
                      ...config.premiacoes,
                      escrever_nota: e.target.value
                    }
                  }

                  setConfig(newConfig)

                }}
              />
            </div>


            <div className="flex flex-col gap-2">
              <span className="text-white">Favoritar aula</span>
              <input
                type="number"
                value={config.premiacoes.favoritar_aula}
                className="w-full h-10 p-5 text-sm rounded-lg bg-neutral-600 text-neutral-300 outline-none"
                onChange={(e) => {

                  const newConfig = {
                    ...config,
                    premiacoes: {
                      ...config.premiacoes,
                      favoritar_aula: e.target.value
                    }
                  }

                  setConfig(newConfig)

                }}
              />
            </div>


            <div className="flex flex-col gap-2">
              <span className="text-white">Completou onboarding</span>
              <input
                type="number"
                value={config.premiacoes.completou_onboarding}
                className="w-full h-10 p-5 text-sm rounded-lg bg-neutral-600 text-neutral-300 outline-none"
                onChange={(e) => {

                  const newConfig = {
                    ...config,
                    premiacoes: {
                      ...config.premiacoes,
                      completou_onboarding: e.target.value
                    }
                  }

                  setConfig(newConfig)

                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default TabelaConfiguracoes
