import React from 'react';
import CourseListButton from './course-list-button';
import NavigationButtons from './navigation-buttons';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

const VideoPlayer = ({ paginaCodigo, cursos, curso, aulas, aula, tipoVideoAula, listarCursos, setListarCursos }) => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const token = localStorage.getItem("token");

    const dispatch = useDispatch();

    const handleAcao = async (tipo, dadoId) => {
        const acao = {
            tipo: tipo,
            dado: dadoId,
        }
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha/validar-acao/`, { acao }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (status === 200) {
                console.log("Ação enviada com sucesso:", data);
                mixPanelVideoConcluido();
                // dispatch(setUsuario(data.usuario))
                if (data.nova) toast.success("Ação da trilha concluída")
            } else {
                console.error("Erro ao enviar ação:", data);
            }
        } catch (error) {
            console.error("Erro ao enviar ação:", error);
        }
    };

    const mixPanelVideoConcluido = () => {
        if (!usuarioAtual || !usuarioAtual._id) {
            console.error('usuarioAtual ou usuarioAtual._id ausente');
            return;
        }
        mixpanel.track(`Aula | Fechada`, {
            distinct_id: usuarioAtual._id,
            aula: aula.titulo,
            aulaCodigo: aula.codigo,
            aula_id: aula._id,
            curso: curso.titulo,
            cursoCodigo: curso.codigo,
            curso_id: curso._id,
            paginaCodigo: paginaCodigo,
        });
    }

    return (
        <>
            {aula?.video && (
                tipoVideoAula === 'cloud' ?
                    <video
                        id="video-aula-banner"
                        src={`${aula.video}`}
                        preload="auto"
                        className="w-full h-full aspect-auto bg-black"
                        autoPlay={true}
                        controls
                        controlsList="nodownload"
                        onContextMenu={() => { return false }}
                        onEnded={() => handleAcao('aula-master', aula._id)}
                    >
                        Seu navegador não suporta a tag de vídeo.
                    </video>
                    :
                    <video
                        src={aula.video}
                        className="w-full h-full aspect-auto object-cover transition-opacity duration-300 ease-in-out"
                        autoPlay={true}
                        controls
                        controlsList="nodownload"
                        onContextMenu={() => { return false }}
                        onEnded={() => handleAcao('aula-master', aula._id)}
                    >
                        Seu navegador não suporta a tag de vídeo.
                    </video>
            )}

            {!listarCursos &&
                <CourseListButton onClick={() => setListarCursos(true)} />
            }
            <NavigationButtons
                paginaCodigo={paginaCodigo}
                cursos={cursos}
                curso={curso}
                aulas={aulas}
                aula={aula}
            />
        </>
    )
};

export default VideoPlayer;
