import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';

import styles from './onboarding.module.css'
import { ArrowUpIcon } from '@heroicons/react/24/outline';

const Onboarding = (props) => {
  const usuarioAtual = useSelector(state => state.usuarioAtual);

  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!location.pathname.includes('/master') && !location.pathname.includes('/marketplace/servico')) {
      if (props.opened) setIsVisible(props.opened)
    }

  }, [props])

  const [showRollUp, setShowRollUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const altura = window.scrollY;
      if (altura > 100) {
        setShowRollUp(true);
      } else {
        setShowRollUp(false);
      }
    };

    // Adiciona o listener de scroll
    window.addEventListener('scroll', handleScroll);

    // Limpa o listener quando o componente desmontar
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const rollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    !location.pathname.includes("/admin") && !location.pathname.includes("/ia") && (
      <div className={` ${styles['container']}`}>
        {isVisible && (
          <div className={styles['conteudo']}>
            <div className={styles['head']}>
              <span className={styles['text']}>Primeiros passos</span>
              <svg
                viewBox="0 0 1024 1024"
                onClick={() => setIsVisible(false)}
                className={styles['icon']}
              >
                <path d="M1024 749.714v109.714c0 50.286-41.143 91.429-91.429 91.429h-841.143c-50.286 0-91.429-41.143-91.429-91.429v-109.714c0-50.286 41.143-91.429 91.429-91.429h841.143c50.286 0 91.429 41.143 91.429 91.429z"></path>
              </svg>
            </div>
            <div className={styles['body']}>
              <div className={styles['passo']}>
                <div className={usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                  && usuarioAtual.onboarding.etapas.includes("video-boas-vindas") ? styles['check'] : styles['check1']}>
                  {usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                    && usuarioAtual.onboarding.etapas.includes("video-boas-vindas") && (
                      <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                        <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                      </svg>
                    )}
                </div>
                <span className={styles['text01']}>
                  Assista o vídeo de boas vindas para entender melhor a plataforma
                </span>
              </div>

              <div className={styles['passo']}>
                <div className={usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                  && usuarioAtual.onboarding.etapas.includes("diagnostico") ? styles['check'] : styles['check1']}>
                  {usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                    && usuarioAtual.onboarding.etapas.includes("diagnostico") && (
                      <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                        <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                      </svg>
                    )}
                </div>
                <span className={styles['text01']}>
                  Preencha o Diagnóstico
                </span>
              </div>

              <div className={styles['passo2']}>
                <div className={usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                  && usuarioAtual.onboarding.etapas.includes("agendar-consultoria") ? styles['check'] : styles['check1']}>
                  {usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                    && usuarioAtual.onboarding.etapas.includes("agendar-consultoria") && (
                      <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                        <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                      </svg>
                    )}
                </div>            <span className={styles['text03']}>
                  <span>Agende sua consultoria</span>
                  <br></br>
                </span>
              </div>
              <div className={styles['passo3']}>
                <div className={usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                  && usuarioAtual.onboarding.etapas.includes("adicionar-time") ? styles['check'] : styles['check1']}>
                  {usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                    && usuarioAtual.onboarding.etapas.includes("adicionar-time") && (
                      <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                        <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                      </svg>
                    )}
                </div>            <span className={styles['text06']}>
                  <span>
                    Adicione seu time e os incentive a ver a primeira aula
                  </span>
                  <br></br>
                </span>
              </div>
              {false && (
                <div className={styles['passo4']}>
                  <div className={usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                    && usuarioAtual.onboarding.etapas.includes("video-boas-vindas") ? styles['check'] : styles['check1']}>
                    {usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                      && usuarioAtual.onboarding.etapas.includes("video-boas-vindas") && (
                        <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                          <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                        </svg>
                      )}
                  </div>            <span className={styles['text09']}>
                    <span>Se apresente na plataforma</span>
                    <br></br>
                  </span>
                </div>
              )}
              <div className={styles['passo5']}>
                <div className={usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                  && usuarioAtual.onboarding.etapas.includes("indicar") ? styles['check'] : styles['check1']}>
                  {usuarioAtual.onboarding && usuarioAtual.onboarding.etapas
                    && usuarioAtual.onboarding.etapas.includes("indicar") && (
                      <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                        <path d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"></path>
                      </svg>
                    )}
                </div>            <span className={styles['text12']}>
                  Indique um amigo e ganhe prêmios
                </span>
              </div>
            </div>
          </div>
        )}
        {showRollUp && (
          <div
            className="w-11 h-11 flex items-center justify-center self-end cursor-pointer rounded-full bg-orange-500"
            title="Voltar para cima"
            onClick={rollUp}
          >
            <ArrowUpIcon className='w-5 h-5 text-white' />
          </div>
        )}
        {false && <div
          onClick={() => setIsVisible(!isVisible)}
          className="w-11 h-11 flex items-center justify-center self-end cursor-pointer rounded-full bg-orange-500"
        >
          {!isVisible && (
            <svg
              viewBox="0 0 365.71428571428567 1024"
              className={styles['icon4']}
            >
              <path d="M365.714 768v73.143c0 20-16.571 36.571-36.571 36.571h-292.571c-20 0-36.571-16.571-36.571-36.571v-73.143c0-20 16.571-36.571 36.571-36.571h36.571v-219.429h-36.571c-20 0-36.571-16.571-36.571-36.571v-73.143c0-20 16.571-36.571 36.571-36.571h219.429c20 0 36.571 16.571 36.571 36.571v329.143h36.571c20 0 36.571 16.571 36.571 36.571zM292.571 109.714v109.714c0 20-16.571 36.571-36.571 36.571h-146.286c-20 0-36.571-16.571-36.571-36.571v-109.714c0-20 16.571-36.571 36.571-36.571h146.286c20 0 36.571 16.571 36.571 36.571z"></path>
            </svg>
          )}
          {isVisible && (
            <svg viewBox="0 0 804.5714285714286 1024" className={styles['icon6']}>
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          )}
        </div>}
      </div>
    )
  )
}

export default Onboarding
