import { useNavigate } from 'react-router-dom';

const CourseListSectionContent = ({ paginaCodigo, cursos }) => {
    const navigate = useNavigate();

    return (
        <>
            {cursos?.length ? (
                <div className='overflow-x-auto'>
                    <div className='flex flex-row gap-3 w-fit'>
                        {cursos.map((curso, index) => (
                            <a
                                key={index}
                                href={`/master/${paginaCodigo}/${curso.codigo}`}
                                className='w-[50vw] md:w-[30vw] lg:w-[15vw] h-[75vw] md:h-[45vw] lg:h-[23vw] rounded-lg overflow-hidden bg-cover bg-no-repeat bg-center'
                                style={{ backgroundImage: `url(${curso.imagem})`, aspectRatio: 'auto' }}

                            >
                                {/* Se você precisar adicionar algum conteúdo dentro da div da imagem, pode colocá-lo aqui */}
                            </a>
                        ))}
                    </div>
                </div>)
                :
                (
                    <p className='text-xs text-neutral-400'>
                        Estamos preparando os módulos deste curso para você. Em breve eles serão listados aqui!
                    </p>
                )
            }
        </>
    )
}
export default CourseListSectionContent