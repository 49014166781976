import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import styles from './lista_consultoria_diagnosticos.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';

const ListaDiagnosticos = (props) => {
  const navigate = useNavigate();
  const [popupVisible, setPopupVisible] = useState(false);
  const [agendamentos, setAgendamentos] = useState([]);
  const usuarioAtual = useSelector((state) => state.usuarioAtual);
  const [mostrar_loading, setMostrarLoading] = useState(true);

  const [professores, setProfessores] = useState([])
  const [showModalReagendamento, setShowModalReagendamento] = useState(false);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState(null);
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [disableAll, setDisableAll] = useState(false);
  const [disabledSend, setDisableSend] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [horarioSelecionado, setHorarioSelecionado] = useState('');

  const getAgendamentos = async () => {
    setMostrarLoading(true)
    const consultor_id = usuarioAtual._id
    console.log("Consultor_id ", consultor_id)
    try {
      const users = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/usuarios-consultorias/${consultor_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Usuário: ", users);
      console.log(users.data);
      if (users.data) setAgendamentos(users.data);
    } catch (err) {
      // Adicione aqui o tratamento de erros, se necessário
      console.error(err);
    }
    setMostrarLoading(false)
  };

  useEffect(() => {
    getAgendamentos();
  }, []); // Adicione um array vazio como dependência para executar apenas uma vez no montar do componente


  useEffect(() => {
    const fetchProfessores = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/professores?consultores=true`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setProfessores(response.data);
      } catch (error) {
        console.error("Erro ao buscar consultores:", error);
      }
    };

    fetchProfessores();
  }, []);

  useEffect(() => {
    if (!selectedDate || !horarioSelecionado) setDisableSend(true)
    else setDisableSend(false)
  }, [selectedDate, horarioSelecionado])

  const openModalReagendamento = async (agendamento) => {
    setAgendamentoSelecionado(agendamento);
    const date = new Date(agendamento.data);
    setSelectedDate(date);

    const horariosDisponiveis = await getHorariosDisponiveis(date, agendamento.professor);  // Aguarda a conclusão da função assíncrona

    const formattedTime = date.toISOString().substring(11, 16); // Extrai "HH:MM" da string ISO
    console.log("ISO:", formattedTime);

    let horarios = [formattedTime, ...horariosDisponiveis].sort();
    setHorariosDisponiveis(horarios)
    setHorarioSelecionado(formattedTime);

    setShowModalReagendamento(true);
  };

  const professorTemHorarioDisponivel = (date) => {
    if (!selectedProfessor) return false;
    const professor = professores.find(p => p._id === selectedProfessor);
    if (!professor || !professor.horariosDisponiveis) return false;

    // Extrai a data sem a hora para comparação, verificando se `date` é válido
    const dataFormatada = date instanceof Date && !isNaN(date) ? date.toISOString().split('T')[0] : null;
    if (!dataFormatada) return false;

    // Filtra os agendamentos do professor para a data específica
    const agendamentosNaData = professor.agendamentos?.filter(agendamento => {
      const agendamentoData = new Date(agendamento.data);
      return agendamentoData instanceof Date && !isNaN(agendamentoData) &&
        agendamentoData.toISOString().split('T')[0] === dataFormatada;
    }) || [];

    // Define o dia da semana
    const dayOfWeek = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'][date.getDay()];
    const disponibilidade = professor.horariosDisponiveis.find(h => h.diaSemana === dayOfWeek);

    // Verifica se há algum horário disponível no dia que não esteja nos agendamentos
    return disponibilidade?.horarios?.some(horario => {
      const horarioDate = new Date(`${dataFormatada}T${horario}`);

      // Certifica-se de que `horarioDate` é válido
      if (!(horarioDate instanceof Date) || isNaN(horarioDate)) return false;

      // Confirma se o horário é no futuro
      if (horarioDate.getTime() <= Date.now() + 24 * 60 * 60 * 1000) return false;

      // Verifica se o horário específico já está agendado
      return !agendamentosNaData.some(agendamento => {
        const agendamentoHora = new Date(agendamento.data).toISOString().split('T')[1];
        return agendamentoHora === horarioDate.toISOString().split('T')[1];
      });
    });
  };

  const getHorariosDisponiveis = async (date, professorId) => {
    const dia = date.getDate();
    const mes = date.getMonth() + 1;
    const ano = date.getFullYear();

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/horarios-disponiveis?dia=${dia}&mes=${mes}&ano=${ano}&professorId=${professorId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const horarios = response.data.filter(horario => horario.length > 0);
      setHorariosDisponiveis(horarios);
      return horarios;
    } catch (err) {
      console.error("Erro ao buscar horários disponíveis:", err);
      return [];
    }
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setHorariosDisponiveis([]);
    setHorarioSelecionado('');
    if (agendamentoSelecionado) {
      getHorariosDisponiveis(date, agendamentoSelecionado.professor);
    }
  };

  const handleHorarioChange = (newHorario) => {
    if (newHorario !== '') {
      setHorarioSelecionado(newHorario);
    }
  };

  const reagendar = async () => {
    if (!agendamentoSelecionado) {
      toast.error("Nenhum agendamento selecionado!");
      return;
    }

    setDisableAll(true);

    const dataHoraSelecionada = new Date(selectedDate);
    const [hora, minutos] = horarioSelecionado.split(':');
    dataHoraSelecionada.setHours(hora);
    dataHoraSelecionada.setMinutes(minutos);

    const dados = {
      data: dataHoraSelecionada
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/agendamentos/reagendar-consultoria/${agendamentoSelecionado._id}`,
        dados,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );

      if (response.status === 200) {
        const agendamentoAtualizado = response.data.agendamento;
        console.log("PROFESSORES: ", professores)
        // Itera sobre os professores para encontrar o professor que possui o agendamento atualizado
        const professoresAtualizados = professores.map(professor => {
          // Verifica se o professor possui o agendamento atualizado
          const index = professor.agendamentos.findIndex(agendamento => agendamento._id === agendamentoAtualizado._id);
          if (index !== -1) {
            // Atualiza o agendamento dentro do professor
            const novosAgendamentos = [...professor.agendamentos];
            novosAgendamentos[index] = agendamentoAtualizado;
            // Retorna o professor atualizado com todas as propriedades intactas
            return { ...professor, agendamentos: novosAgendamentos };
          }
          return professor;
        });

        // Atualiza a lista de professores com o agendamento atualizado
        setProfessores(professoresAtualizados);
        const index = agendamentos.indexOf(agendamentoSelecionado);
        if (index > -1) {
          setAgendamentos(prev => {
            const newAgendamentos = [...prev];
            newAgendamentos[index] = {
              ...newAgendamentos[index],
              data: dataHoraSelecionada,
            };
            return newAgendamentos;
          });
        }
        toast.success("Consulta reagendada com sucesso!");
      } else {
        toast.error("Erro no reagendamento da consulta.");
      }
    } catch (err) {
      console.error(err.message);
      toast.error("Erro no reagendamento da consulta.");
    } finally {
      setDisableAll(false);
      console.log("PROFESSORES ATUALIZADOS: ", professores)
      closeModalReagendamento();
    }

  };


  const desmarcar = async (agendamento_id = null) => {
    if (!agendamento_id) {
      toast.error("Nenhum agendamento selecionado!");
      return;
    }

    if (confirm("Você tem certeza que deseja desmarcar esse agendamento?")) {
      setDisableAll(true);

      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/agendamentos/desmarcar-consultoria/${agendamento_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );

        if (response.status === 200) {
          // Atualiza a lista de professores removendo o agendamento desmarcado
          const professoresAtualizados = professores.map(professor => {
            const novosAgendamentos = professor.agendamentos.filter(agendamento => agendamento._id !== agendamento_id);
            return { ...professor, agendamentos: novosAgendamentos };
          });

          setProfessores(professoresAtualizados);
          setAgendamentos(agendamentos.filter(agendamento => agendamento._id !== agendamento_id))
          toast.success("Agendamento desmarcado com sucesso!");
        } else {
          toast.error("Erro ao desmarcar agendamento.");
        }
      } catch (error) {
        console.error("Error in desmarcar:", error);
        toast.error("Erro ao desmarcar agendamento.");
      } finally {
        setDisableAll(false);
      }
    }
  };

  const closeModalReagendamento = () => {
    setAgendamentoSelecionado(null);
    setHorarioSelecionado('')
    setHorariosDisponiveis([])
    setSelectedDate(null)
    setShowModalReagendamento(false);
  };

  const marcarAgenda = (date) => {
    date = new Date(date)
    const ano = date.getFullYear();
    const mes = date.getMonth() + 1;
    const dia = date.getDate();

    const url = `https://calendar.google.com/calendar/u/0/r/month/${ano}/${mes}/${dia}`;

    window.open(url, '_blank');
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pt-BR', options).format(date);
  };

  return (
    <div className="table-container">
      {showModalReagendamento &&
        <div className="fixed top-0 left-0 w-screen min-h-screen flex justify-center align-middle items-center z-40">
          <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={() => closeModalReagendamento()}></div>
          <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
            <div className="border-b border-neutral-700 py-5 space-y-2 flex justify-between items-center">
              <img src='/main_logo.png' className='h-7' />
              <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={() => closeModalReagendamento()}>&times;</button>
            </div>
            <div className="py-5 flex flex-col gap-5 w-2/3 m-auto">
              <Calendar
                value={selectedDate}
                onChange={handleDateChange}
                minDate={new Date()}
                maxDate={new Date(usuarioAtual.consultorias.expiracao)}
                tileClassName={({ date, view }) => {
                  if (view === 'month' && professorTemHorarioDisponivel(date)) {
                    return 'day-with-availability';
                  }
                }}
              />
              <div className="home-data">
                <div className="home-horrios">
                  {horariosDisponiveis.length ?
                    <div className='w-full'>
                      <h3 className='text-neutral-300'>Escolha um horário dentre os disponíveis</h3>
                      <div className='flex flex-row w-full gap-2 my-3'>
                        {horariosDisponiveis.map((h, index) => (
                          <span
                            key={index}
                            className={`border border-neutral-300 text-neutral-300 rounded-lg w-fit py-1 px-2 text-center cursor-pointer hover:bg-orange-600 active:bg-orange-700 ${horarioSelecionado === h ? 'bg-orange-800' : ''}`}
                            onClick={() => handleHorarioChange(h)}
                          > {h}
                          </span>
                        ))}
                      </div>
                    </div>
                    : (
                      agendamentoSelecionado && <div>
                        <span className="text-less">Nenhum horário disponível para a data selecionada</span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="border-t border-neutral-700 py-5 flex flex-row justify-end items-center align-middle gap-x-3 select-none">

              <button className="py-2 px-3 md:py-3 md:px-4 rounded-md text-xs md:text-sm bg-neutral-600 hover:bg-neutral-700 active:bg-neutral-800" disabled={disableAll} onClick={() => closeModalReagendamento()}>Cancelar</button>
              <button className="py-2 px-3 md:py-3 md:px-4  rounded-md text-xs font-semibold md:text-sm bg-orange-600 hover:bg-orange-700 active:bg-orange-500 disabled:bg-neutral-800" disabled={disableAll || disabledSend} onClick={() => reagendar()}>Reagendar</button>

            </div>
          </div>
        </div>
      }
      <div className="table-header">
        <span className="table-heading">Usuários com consulta</span>
      </div>
      <div className="table-content">
        {agendamentos.length > 0 ? (
          <table className="user-table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Detalhes</th>
                <th colSpan={'2'}>Controles</th>
              </tr>
            </thead>
            <tbody>
              {agendamentos.map((agendamento, index) => (
                <tr key={index} className='text-sm'>
                  <td>
                    <p className='text-neutral-50'>{agendamento.aluno.nome}</p>
                    <a href={`mailto:${agendamento.aluno.email}`} target='_blank' className='text-xs hover:text-orange-500 active:text-orange-600'>{agendamento.aluno.email}</a>
                  </td>
                  <td>
                    {agendamento.titulo && <p className='text-sm text-neutral-300'>{agendamento.titulo}</p>}
                    {agendamento.descricao && <p className='text-xs text-neutral-500'>{agendamento.descricao}</p>}
                    {!agendamento.titulo && !agendamento.descricao && <p className='text-sm text-neutral-600'>Não há titulo nem descrição</p>}
                  </td>
                  <td><p onClick={() => marcarAgenda(agendamento.data)} className='cursor-pointer text-neutral-50 hover:text-neutral-100 active:text-neutral-200'>{formatDate(agendamento.data)}</p>

                    <a href={`${agendamento.linkDaReuniao}`} target='_blank' className='text-sm hover:text-orange-500 active:text-orange-600'>{agendamento.linkDaReuniao}</a>

                  </td>
                  <td className='text-orange-500 text-center'>
                    <button className=' text-orange-50 hover:text-orange-300 active:text-orange-500 select-none' onClick={() => openModalReagendamento(agendamento)}>Reagendar</button>
                  </td>
                  <td className='text-red-500 text-center'>
                    <button className=' text-red-500 hover:text-red-600 active:text-red-700 select-none' onClick={() => desmarcar(agendamento._id)}>Desmarcar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="empty-table">
            <span>Não há consultorias agendadas no momento!</span>
          </div>
        )}

        {mostrar_loading &&
          <div className="loading-container">
            <div className="loading-bar" id="loading-bar"></div>
          </div>
        }
      </div>
    </div>

  );
};

export default ListaDiagnosticos;
