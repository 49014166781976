import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const CourseListButton = ({ onClick }) => (
    <button
        className='flex transition-all duration-300 ease-in-out absolute p-1 md:p-2 lg:p-3 group text-neutral-300 bg-neutral-950 border border-neutral-100 top-7 right-0 items-center align-middle gap-3'
        onClick={onClick}
    >
        <ArrowLeftIcon className='w-5 h-5 transition-all duration-300 ease-in-out' />
        <p className='hidden group-hover:block capitalize font-poppins transition-all duration-300 ease-in-out'>
            Mostrar módulos
        </p>
    </button>
);

export default CourseListButton;
