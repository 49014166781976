import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/header';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import { useSelector, useDispatch } from 'react-redux';
import { setUsuario } from '../../features/usuario/usuarioAtual';
import { ArrowRightIcon, CheckCircleIcon, ChevronRightIcon, MinusCircleIcon, StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const Trilha = () => {
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const token = localStorage.getItem("token");

    const [aulaTrilhaId, setAulaTrilhaId] = useState(useParams().aula_trilha_id ?? null);

    const [processando, setProcessando] = useState(false);
    const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);
    const [trilha, setTrilha] = useState(usuarioAtual?.trilha || {});
    const [ultimoVideoAulaTrilha, setUltimoVideoAulaTrilha] = useState(null);

    useEffect(() => {
        if (usuarioAtual.tipo !== 'guest' && usuarioAtual?.trilha) {
            setAcoesConcluidas(usuarioAtual.trilha)
            const aulasTrilhaAssitidas = usuarioAtual.acoesConcluidas?.filter(el => el.tipo === 'aula-trilha') || [];
            const aulasTrilha = mapearAulasTrilha(usuarioAtual.trilha)
            buscarAulaTrilha(aulasTrilha, aulasTrilhaAssitidas, aulaTrilhaId);
        }
    }, [usuarioAtual]);


    const mapearAcoes = (divisoes) => {
        // Pega uma lista de divisões com ações e retorna todas as ações em uma lista linear única
        return divisoes?.reduce((acc, divisao) => {
            // Pega as ações da divisão atual e adiciona ao acumulador
            return acc.concat(divisao.acoes);
        }, []);
    };

    const mapearAulasTrilha = (tr) => {
        return mapearAcoes(tr?.divisoes).filter(acao => acao.tipo === 'aula-trilha').map(acao => { return acao.dado }) || []
    };

    const setAcoesConcluidas = (tr) => {
        if (usuarioAtual.tipo === 'guest' || !tr) return;

        const acoesTrilha = mapearAcoes(tr?.divisoes);
        if (!acoesTrilha) return;

        let acoesConcluidasRestantes = []
        if (usuarioAtual?.acoesConcluidas) {
            acoesConcluidasRestantes = [...usuarioAtual?.acoesConcluidas];
        }

        // Cria um Set para armazenar as chaves das ações concluídas
        let idsConcluidos = acoesConcluidasRestantes.map(acao => `${acao.tipo}-${acao.dado || ''}`);

        // Cria uma nova trilha com status atualizado sem modificar diretamente o estado
        const novaTrilha = {
            ...tr,
            divisoes: tr.divisoes.map((divisao) => ({
                ...divisao,
                acoes: divisao.acoes.map(acao => {
                    const chave = `${acao.tipo}-${acao.dado?._id || ''}`; // Corrigido para lidar com _id ou null
                    const indexAcao = idsConcluidos.indexOf(chave);

                    // Se a ação da trilha foi concluída, remove a ação concluída da lista para não comparar novamente
                    if (indexAcao > -1) {
                        idsConcluidos.splice(indexAcao, 1) // Remove a chave do Set de IDs concluídos
                        return {
                            ...acao,
                            concluida: true,
                        };
                    }

                    return {
                        ...acao,
                        concluida: false,
                    };
                }),
            })),
        };

        // Atualiza a trilha com o novo estado
        setTrilha(novaTrilha);
    };

    const buscarAulaTrilha = (aulas, aulasAssistidas, idAula) => {
        // Se houver idAula, retorna a aula exata
        if (idAula) {
            const aulaEncontrada = aulas.find(aula => aula._id === idAula);
            if (aulaEncontrada) {
                setUltimoVideoAulaTrilha(aulaEncontrada);
                return true;
            }
        }

        // Busca a primeira aula não assistida ou retorna a primeira aula do array
        const aulaAssistir = aulas.find(aula =>
            !aulasAssistidas.some(el => el.dado === aula._id)
        ) || aulas[0];

        if (aulaAssistir) {
            setUltimoVideoAulaTrilha(aulaAssistir);
            return true;
        }

        return false;
    };


    const renderAcao = (acao, index) => {
        const hasCodigo = acao.dado && acao.dado.codigo;
        const links = {
            'boas-vindas': '/',
            'consultoria': `/consultorias/${acao.dado?._id}`,
            'pagina-master': hasCodigo ? `/master/${acao.dado.codigo}` : '#',
            'curso-master': hasCodigo ? `/master/curso/${acao.dado.codigo}` : '#',
            'aula-master': hasCodigo ? `/master/aula/${acao.dado.codigo}` : '#',
            'aula-trilha': `/trilha/${acao.dado?._id}`,
            'short': `/shorts/${acao.dado?._id}`,
            'comunidade': process.env.REACT_APP_COMUNIDADE_URL,
            'produto-catalogado': acao.dado?.link || '#',
            'marketplace': acao.dado?.nome ? acao.dado.link : `/marketplace/servico/marketplace/${acao.dado?.codigo || ''}`,
        };

        const link_target = (acao.tipo === 'comunidade' || (acao.tipo === 'produto-catalogado') || (acao.tipo === 'marketplace' && acao.dado?.nome)) ? '_blank' : '_self';

        return (
            <li key={`${acao._id}-${index}`} className="text-neutral-300 text-xs hover:bg-neutral-900">
                <a
                    href={links[acao.tipo]}
                    target={link_target}
                    className="w-full p-3 flex flex-row items-center align-middle gap-2"
                    onClick={() => {
                        if (acao.tipo === 'produto-catalogado' || acao.tipo === 'marketplace' || acao.tipo === 'comunidade') {
                            handleAcao(acao.tipo, acao.dado?._id);
                        }
                    }}
                >
                    {acao.concluida ?
                        <SolidStarIcon className="w-5 h-5 flex-shrink-0 text-yellow-500" />
                        :
                        <OutlineStarIcon className="w-5 h-5 flex-shrink-0" />
                    }
                    <span className="flex-shrink-0">{acao.titulo}</span>
                    <span className="text-yellow-500 truncate min-w-0 max-w-fit w-full">
                        {acao.dado?.titulo || acao.dado?.nome}
                    </span>
                    {acao.tipo in links && (
                        <span className="text-xs hover:text-orange-500 flex-shrink-0" aria-label={acao.titulo}>
                            <ArrowRightIcon className="w-4 h-4" />
                        </span>
                    )}
                </a>
            </li>
        );
    }

    const handleAcao = async (tipo, dadoId) => {
        const acao = {
            tipo: tipo,
            dado: dadoId,
        }
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha/validar-acao/`, { acao }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (status === 200) {
                console.log("Ação enviada com sucesso:", data);
                dispatch(setUsuario(data.usuario))
                if (data.nova) toast.success("Ação da trilha concluída")
            } else {
                console.error("Erro ao enviar ação:", data);
            }
        } catch (error) {
            console.error("Erro ao enviar ação:", error);
        }
    };


    return (
        <div className='w-full'>
            <Helmet>
                <title>Simbium | Trilha</title>
                <meta property="og:title" content="Simbium" />
            </Helmet>

            <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />

            <div className='bg-neutral-900 flex flex-col font-poppins'>
                <div className='w-full flex flex-row md:px-5 gap-10'>
                    {showMenu && (
                        <>
                            <Menu usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
                            <div className='hidden w-0 lg:block lg:w-3/12'></div>
                        </>
                    )}
                    <div className={`${showMenu ? 'lg:w-9/12' : 'sm:w-11/12'} w-[98vw] mb-5 mx-auto text-neutral-50`}>
                        {ultimoVideoAulaTrilha &&
                            <div className="w-full mx-auto space-y-5 text-white mb-5 md:mb-10">
                                <div className='bg-neutral-800 rounded-lg overflow-hidden grid grid-cols-1 lg:grid-cols-2 divide-y lg:divide-y-0 lg:divide-x divide-neutral-900 '>
                                    <div className='flex flex-col items-center justify-center align-middle overflow-hidden'>
                                        <video
                                            src={`${ultimoVideoAulaTrilha.video}`}
                                            className='w-full h-fit aspect-video'
                                            controls
                                            autoPlay
                                            muted
                                            controlsList="nodownload"
                                            onContextMenu={() => false}
                                            loading="lazy"
                                            onEnded={() => handleAcao('aula-trilha', ultimoVideoAulaTrilha._id)}
                                        />

                                    </div>
                                    <div className='p-10 bg-cover bg-center bg-no-repeat' style={{ backgroundImage: "url('/trilha-background-light.svg')" }}>
                                        <div className='w-full mx-auto h-full flex flex-col gap-5 items-center justify-center align-middle text-white text-center'>
                                            <h1 className='font-extrabold text-lg md:text-xl lg:text-2xl font-poppins'>{ultimoVideoAulaTrilha.titulo}</h1>
                                            <p className='text-xs md:text-md italic'>{ultimoVideoAulaTrilha.descricao}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }

                        <div className="bg-neutral-800 w-full p-5 rounded-lg overflow-hidde md:divide-y divide-neutral-900">
                            <div className='lg:max-w-[50vw] mx-auto space-y-10'>
                                <div className='flex flex-col gap-3'>
                                    <p className='text-lg md:text-2xl'>{trilha.titulo}</p>
                                    <p className='text-xs md:text-md text-neutral-400'>{trilha.descricao || ''}</p>
                                </div>
                                {trilha?.divisoes?.length ? (
                                    trilha.divisoes.map((divisao, indexDivisao) => (
                                        <div key={`divisao-${divisao._id}-${indexDivisao}`} className="flex flex-col gap-3">
                                            <p className='text-sm md:text-lg lg:text-xl'>{divisao.titulo}</p>
                                            <ul className='text-sm divide-y divide-neutral-700'>
                                                {divisao.acoes?.map((acao, indexAcao) => renderAcao(acao, indexAcao))}
                                            </ul>
                                        </div>
                                    ))
                                ) : (
                                    <div className='w-full flex flex-col text-center'>
                                        <p className='text-neutral-400 italic'>Nenhuma ação disponível na trilha.</p>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Trilha;
