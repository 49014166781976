import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setUsuario } from '../../features/usuario/usuarioAtual';
import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';


const ConfirmacaoCadastro = () => {
    const usuarioAtual = useSelector(state => state.usuarioAtual);
    const { usuarioId } = useParams();
    const [isConfirmed, setIsConfirmed] = useState(false);
    const token = localStorage.getItem("token");
    const header_geral = { Authorization: `Bearer ${token}` };
    const dispatch = useDispatch();

    const confirmarEmail = async () => {
        try {
            const { status, data } = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/confirmar-email`,
                { usuarioId },
                { headers: header_geral }
            );

            if (status === 200) {
                dispatch(setUsuario(data.usuario));
                setIsConfirmed(true); // Atualiza o estado de confirmação
                toast.success("E-mail confirmado com sucesso!");
                window.location.replace('/');
            }
        } catch (error) {
            console.error("Erro ao confirmar e-mail: ", error);
            toast.error("Erro ao confirmar e-mail. Tente novamente ou entre em contato com o suporte.");
        }
    };

    useEffect(() => {
        if (!usuarioAtual) return;

        if (usuarioAtual.emailConfirmado === true) {
            window.location.replace('/');
            return;
        }

        if (usuarioAtual._id === usuarioId) {
            confirmarEmail();
        }
    }, [usuarioAtual]);

    return (
        <div className="w-screen h-screen flex items-center justify-center align-middle">
            <div className="w-[90vw] h-[90vh] flex items-center justify-center align-middle bg-neutral-800 rounded-lg shadow-xl shadow-neutral-950 p-3 lg:p-5 text-neutral-50 text-center bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(/confirmacao-cadastro-neutral-900-background.png)` }}>
                <div className="w-full lg:w-1/2 h-fit space-y-7 text-sm lg:text-lg">
                    <img src="/main_logo.png" className="w-52 aspect-auto mx-auto" />
                    <h1 className="text-xl lg:text-4xl font-bold">Seja Bem-Vindo(a) ao Simbium</h1>
                    <p className=""><strong>Suas credenciais</strong> de login foram enviadas para <strong>o seu e-mail cadastrado</strong>.</p>
                    <p className=""><strong>Acesse o seu e-mail</strong> para ter acesso às credenciais <strong>e vá para a página de login</strong> para acessar sua conta Simbium</p>

                    <p className="w-full"><a href="/" className="w-1/2 h-fit px-5 py-3 rounded-lg shadow-xl shadow-neutral-900 bg-orange-500 hover:bg-orange-600 active:bg-orange-700">Já confirmei o meu e-mail</a></p>
                </div>
            </div>
        </div>
    )
}
export default ConfirmacaoCadastro;