import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon, TrashIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

const AcaoTrilhaAdmin = ({ divisorId, processando, setProcessando, acoes, trilhaSalva, setTrilhaSalva, fetchTrilha, showAcaoMenu, setShowAcaoMenu }) => {

    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const token = localStorage.getItem("token");
    const axios_header = { headers: { Authorization: `Bearer ${token}` } }


    const handleAcaoMenu = () => {
        if (showAcaoMenu)
            setShowAcaoMenu(null);
        else
            setShowAcaoMenu(divisorId);
    }

    const alterarOrdem = async (acao_id, sentido) => {
        setProcessando(true);
        try {
            const { status, data } = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha/mover/${acao_id}`,
                { sentido }, // sentido pode ser 1 (subir) ou -1 (descer)
                axios_header
            );

            if (status === 200) {
                await fetchTrilha(); // Atualiza a lista de acaoes após mudança
                toast.success("Ordem do acao alterada com sucesso!");
            } else {
                toast.error("Erro ao alterar a ordem do acao.");
            }
        } catch (error) {
            console.error("Erro ao alterar a ordem do acao:", error);
            toast.error("Erro ao alterar a ordem do acao.");
        } finally {
            setProcessando(false);
        }
    };

    const excluirAcaoTrilha = async (acao_id, acao_titulo) => {
        if (!confirm(`Você tem certeza que deseja excluir o acao '${acao_titulo}'? Esta ação é irreversível!`)) return;

        setProcessando(true);
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/acao-trilha/${acao_id}`,
                axios_header
            );

            if (response.status === 200) {
                toast.success("Ação excluído com sucesso!");
                fetchTrilha();
                setTrilhaSalva(true);
            } else {
                console.error(`Erro ao excluir acao da divisor: ${response}`);
                toast.error("Erro ao excluir acao da divisor.");
            }
        } catch (error) {
            console.error("Erro ao excluir acao da divisor: ", error);
            toast.error("Erro ao excluir acao da divisor.");
        } finally {
            setProcessando(false);
        }
    };

    return (
        <div className='my-3 bg-neutral-800 rounded-lg text-sm overflow-hidden'>
            <ul className='divide-y divide-neutral-700 m-3'>
                {acoes?.map((acao, index) => (
                    <li key={index} className='p-2 flex justify-between items-center group'>
                        <span className='flex  items-center gap-3'>
                            <span className='flex flex-col items-center justify-center'>
                                {index > 0 &&
                                    <button className='hover:text-orange-500 active:text-orange-600' disabled={processando} onClick={() => alterarOrdem(acao._id, -1)}>
                                        <ChevronUpIcon className='w-4 h-4' />
                                    </button>
                                }
                                {index < acoes.length - 1 &&
                                    <button className='hover:text-orange-500 active:text-orange-600' disabled={processando} onClick={() => alterarOrdem(acao._id, 1)}>
                                        <ChevronDownIcon className='w-4 h-4' />
                                    </button>
                                }
                            </span>
                            <span className='font-extrabold'>{acao.titulo}</span>
                        </span>
                        <span className='truncate group-hover:text-balance  max-w-72'>{acao.dado?.titulo || acao.dado?.nome || ''}</span>
                        <button className='hidden group-hover:inline-block'>
                            <TrashIcon className='w-4 h-4 text-red-500' disabled={processando} onClick={() => excluirAcaoTrilha(acao._id, acao.titulo)} />
                        </button>
                    </li>
                ))}
            </ul>
            {(!showAcaoMenu || showAcaoMenu === divisorId) &&
                <button
                    className="w-full text-xs text-center bg-neutral-950 p-3 hover:text-orange-500 active:text-orange-600"
                    onClick={handleAcaoMenu}
                >
                    {`${showAcaoMenu ? 'Fechar Menu de Ação' : 'Adicionar Ação'}`}
                </button>
            }
        </div>
    )
}

export default AcaoTrilhaAdmin