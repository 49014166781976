import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { setUsuario } from '../../../features/usuario/usuarioAtual';

const ModalOpcoes = ({ closeModal }) => {
    const dispatch = useDispatch();
    const usuarioAtual = useSelector(state => state.usuarioAtual);

    const [processando, setProcessando] = useState(false);
    const [paginaSelecionarOpcoes, setPaginaSelecionarOpcoes] = useState(0);
    const [fimSelecionarOpcoes, setFimSelecionarOpcoes] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const listaOpcoes = [
        {
            titulo: "Ponto de Decolagem",
            opcoes: [
                { titulo: 'Sou loja física', valor: 'sou-loja-fisica', tipo: 'next' },
                { titulo: 'Já vendo online', valor: 'ja-vendo-online', tipo: 'next' },
                { titulo: 'Estou começando do zero', valor: 'estou-comecando-do-zero', tipo: 'barren' },
            ],
        },
        {
            titulo: "Nível de experiência",
            opcoes: [
                { titulo: 'Estou começando do absoluto zero', valor: 'estou-comecando-do-absoluto-zero', tipo: 'barren' },
                { titulo: 'Entre 10K e 20K por mês em vendas', valor: 'entre-10k-e-20k-por-mes-em-vendas', tipo: 'barren' },
                { titulo: 'Mais de 20K por mês em vendas, querendo escalar', valor: 'mais-de-20k-por-mes-em-vendas-querendo-escalar', tipo: 'barren' },
                { titulo: 'Lançar E-Commerce do Zero (já vendo online)', valor: 'lancar-e-comerce-do-zero-ja-vendo-online', tipo: 'barren' },
            ],
        },
    ];

    const setOpcao = useCallback((opcao) => {
        setSelectedOptions(prev => [...prev, opcao.valor]);

        if (opcao.tipo === 'barren' || paginaSelecionarOpcoes >= listaOpcoes.length - 1) {
            setFimSelecionarOpcoes(true);
        }
        setPaginaSelecionarOpcoes(prev => prev + 1);

    }, [paginaSelecionarOpcoes]);

    const voltarListaOpcao = useCallback(() => {
        setSelectedOptions(prev => prev.slice(0, -1));
        setPaginaSelecionarOpcoes(prev => Math.max(prev - 1, 0));
        setFimSelecionarOpcoes(false);
    }, []);

    const enviarOpcoes = useCallback(async () => {
        setProcessando(true);

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/update-options`,
                { usuarioId: usuarioAtual._id, opcoes: selectedOptions },
                { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
            );

            if (response.status === 200) {
                console.log("RESPOSTA: ", response)
                dispatch(setUsuario(response.data))
                toast.success('Opções atualizadas');
                closeModal();
            } else {
                console.error(`Erro ao trocar opções: ${response}`);
                toast.error(`Erro ao trocar opções: ${response.data?.message || 'Erro desconhecido'}`);
            }
        } catch (error) {
            const mensagemErro = error.response?.data?.error || 'Erro desconhecido';
            console.error(`Erro ao trocar opções: ${error}`);
            toast.error(`Erro ao trocar opções: ${mensagemErro}`);
        } finally {
            setProcessando(false);
        }
    }, [selectedOptions, closeModal]);

    return (
        <div className="fixed z-50 top-0 left-0 w-screen min-h-screen flex justify-center items-center">
            <div className="w-full min-h-screen bg-neutral-950 opacity-95" onClick={closeModal}></div>
            <div className="absolute w-11/12 md:w-1/2 rounded-lg bg-neutral-800 p-5 text-neutral-300 overflow-y-auto max-h-screen">
                <div className="border-b border-neutral-700 py-5 flex justify-between items-center">
                    <img src='/main_logo.png' className='h-7' alt="Logo" />
                    <button className="text-4xl hover:text-neutral-200 active:text-neutral-50" onClick={closeModal}>&times;</button>
                </div>
                <div className="py-5 w-full md:w-2/3 m-auto space-y-5">
                    {!fimSelecionarOpcoes ? (
                        <>
                            <p className='text-lg text-center font-sans font-bold'>Informe o seu momento e receba sua trilha personalizada</p>
                            <div>
                                {listaOpcoes[paginaSelecionarOpcoes]?.opcoes.map((opcao, index) => (
                                    <div key={index} className='p-3 rounded-lg'>
                                        <button
                                            className='w-full rounded-sm p-2 border bg-neutral-700 border-neutral-400 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:text-white'
                                            disabled={processando}
                                            onClick={() => setOpcao(opcao)}
                                        >
                                            {opcao.titulo}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className='flex flex-col w-full gap-2'>
                            <p>Opção(ões) selecionada(s)</p>
                            <ul className='space-y-2'>
                                {selectedOptions.map((opcao, index) => (
                                    <li className='bg-neutral-900 px-3 py-2 rounded-lg' key={index}>
                                        {listaOpcoes
                                            .flatMap(lista => lista.opcoes)
                                            .find(el => el.valor === opcao)?.titulo}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="border-t border-neutral-700 py-5 flex justify-between gap-x-3">
                    {paginaSelecionarOpcoes > 0 && (
                        <button
                            className='py-2 text-sm rounded-lg text-orange-500 transition-all p-3 m-auto'
                            onClick={voltarListaOpcao}
                        >
                            Voltar
                        </button>
                    )}
                    <div className='w-full flex justify-end gap-x-3'>
                        <button className="py-2 px-3 rounded-md text-xs bg-neutral-600 hover:bg-neutral-700" onClick={closeModal}>Cancelar</button>
                        {fimSelecionarOpcoes &&
                            <button
                                className="py-2 px-3 rounded-md text-xs font-semibold bg-orange-600 hover:bg-orange-700 disabled:bg-neutral-800"
                                disabled={processando}
                                onClick={enviarOpcoes}
                            >
                                {processando ? 'Processando...' : 'Enviar'}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalOpcoes;
