import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { QueueListIcon } from '@heroicons/react/24/outline';
import {
  BellIcon,
  ClipboardIcon,
  PlayCircleIcon,
  Square3Stack3DIcon,
  TrophyIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/solid';

const routes = [
  { path: '/admin/master', label: 'Master', icon: <TrophyIcon className="w-5 h-5" /> },
  { path: '/admin/trilha', label: 'Trilha', icon: <QueueListIcon className="w-5 h-5" /> },
  { path: '/admin/planos', label: 'Planos', icon: <Square3Stack3DIcon className="w-5 h-5" /> },
  { path: '/admin/aulas', label: 'Shorts', icon: <PlayCircleIcon className="w-5 h-5" /> },
  { path: '/admin/notificacoes', label: 'Notificações', icon: <BellIcon className="w-5 h-5" /> },
  { path: '/admin/usuarios', label: 'Usuários', icon: <UsersIcon className="w-5 h-5" /> },
  { path: '/admin/agendamentos', label: 'Agendamentos', icon: <ClipboardIcon className="w-5 h-5" /> },
  { path: '/admin/comunidade', label: 'Comunidade', icon: <UserGroupIcon className="w-5 h-5" /> },
  { path: '/admin/configuracoes', label: 'Configurações', icon: <WrenchScrewdriverIcon className="w-5 h-5" /> },
  { path: '/admin/live', label: 'Live', icon: <VideoCameraIcon className="w-5 h-5" /> },
];

const MenuAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Função para verificar se a rota está ativa
  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <div className="flex flex-col items-center gap-8 bg-neutral-950 rounded-lg overflow-hidden h-full min-w-[320px] w-fit">
      <div className="flex flex-col w-full divide-y divide-neutral-800">
        {routes.map(({ path, label, icon }) => (
          <div
            key={path}
            onClick={() => navigate(path)}
            className={`flex items-center gap-4 px-5 py-4 cursor-pointer hover:bg-neutral-800 ${isActive(path) ? 'border-l-4 border-orange-500 text-orange-500' : 'text-white'
              }`}
          >
            {icon}
            <span className="text-sm">{label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuAdmin;
