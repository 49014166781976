import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import './menu-consultor.css'

const MenuConsultor = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const current = location.pathname.split('/');

  useEffect(() => {
    if (location.pathname === '/consultor/' || location.pathname === '/consultor') {
      navigate('/consultor/agendamentos-consultorias')
    }

  }, [location])

  return (
    <div className={`menu-consultor-container mt-7`}>
      <div className="menu-consultor-menu">
        <div
          onClick={() => {
            navigate('/consultor/agendamentos-consultorias')
          }}
          className={`menu-consultor-container1 ${current.includes("/agendamentos-consultorias") ? 'activeMenu' : ''}`}
        >
          <svg viewBox="0 0 1024 1024" className="menu-consultor-icon">
            <path
              d="M554 128h342v256h-342v-256zM554 896v-426h342v426h-342zM128 896v-256h342v256h-342zM128 554v-426h342v426h-342z"
              className=""
            ></path>
          </svg>
          <span className="menu-consultor-text">Agendamentos dos clientes</span>
        </div>
      </div>
    </div>
  )
}

export default MenuConsultor
