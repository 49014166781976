import React, { useState, useEffect } from 'react';
import SectionPageForm from './components/section-page-form';
import PageForm from './components/page-form';
import PageCard from './components/page-card';

import { ChevronUpIcon, ChevronDownIcon, CheckBadgeIcon, PencilIcon, XMarkIcon, HomeIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { toast } from 'react-toastify';

const MasterAdmin = () => {
  const [secoesPagina, setSecoesPagina] = useState([]);
  const [paginas, setPaginas] = useState([]);
  // const [bunnyUrlPublico] = useState(`${process.env.REACT_APP_BUNNY_FILEHOST}paginas-automaticas/paginas/banners/`);
  const [showFormNewSection, setShowFormNewSection] = useState(false);
  const [showFormNewPage, setShowFormNewPage] = useState(false);
  const [formSecaoMode, setFormSecaoMode] = useState('create');
  const [processando, setProcessando] = useState(false);
  const [secaoPagina, setSecaoPagina] = useState({ codigo: '', titulo: '', descricao: '', ativo: false, mostrarEmHome: false });
  const [pagina, setPagina] = useState({ codigo: '', titulo: '', descricao: '', ativo: false });

  const fetchData = async () => {
    const data = await fetchConteudo('secao-pagina-com-paginas');
    if (data) {
      setPaginas(data.paginasSemSecao);
      setSecoesPagina(data.secoesComPaginas);
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      setProcessando(true);
      await fetchData();
      setProcessando(false);
    };

    fetchDataAsync();
  }, []);

  const abrirFormularioSecao = () => setShowFormNewSection(true);
  const abrirFormularioPagina = () => setShowFormNewPage(true);

  const fetchConteudo = async (opcao) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/${opcao}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Erro ao tentar buscar as seções com disciplinas");
        console.log("Resposta do servidor: ", response);
        return null;
      }
    } catch (err) {
      toast.error("Erro ao tentar buscar as seções com disciplinas");
      console.error("Erro da requisição: ", err);
      return null;
    }
  };

  const fecharFormularioSecao = () => {
    setFormSecaoMode('create')
    setShowFormNewSection(false);
    setSecaoPagina({ codigo: '', titulo: '', descricao: '', ativo: false });
  };

  const handleCadastrarSecao = async () => {
    setProcessando(true);
    const novaSecao = await cadastrarSecao(secaoPagina);
    if (novaSecao) setSecoesPagina([...secoesPagina, novaSecao]);
    fecharFormularioSecao();
    setProcessando(false);
  };

  const cadastrarSecao = async (secaoPagina) => {
    setProcessando(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao-pagina`, secaoPagina, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data && response.status === 200) {
        toast.success("Seção cadastrada com sucesso!");
        return response.data;
      } else {
        toast.error("Erro ao cadastrar a Seção da Disciplina: " + response.message);
      }
    } catch (err) {
      toast.error("Erro ao cadastrar a Seção da Disciplina: " + err);
      console.error(err);
    } finally {
      fecharFormularioSecao();
      setProcessando(false)
    }
    return null;
  };

  const mostrarEmHome = async (secao_id) => {
    // Encontra a seção correspondente ao secao_id
    let secaoSelecionada = secoesPagina.find(el => el._id === secao_id);

    // Atualiza o campo mostrarEmHome para o oposto do valor atual
    const secaoAtualizada = { ...secaoSelecionada, mostrarEmHome: !secaoSelecionada.mostrarEmHome };

    // Atualiza a seção no backend
    await handleAtualizarSecao(secaoAtualizada);
  };

  const editarSecao = (secao_id) => {
    setFormSecaoMode('update');
    setSecaoPagina(secoesPagina.find(el => el._id === secao_id));
    abrirFormularioSecao();

    // Rolar a disciplina para o início
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAtualizarSecao = async (secao_pagina = null) => {
    setProcessando(true);

    const secaoAtualizada = await salvarAlteracoesSecao(secao_pagina ?? secaoPagina);
    if (secaoAtualizada) {
      setSecoesPagina((prevSecoes) =>
        prevSecoes.map((secao) =>
          secao._id === secaoAtualizada._id ? secaoAtualizada : secao
        )
      );
      fecharFormularioSecao();
    }
    setProcessando(false);
  };

  const salvarAlteracoesSecao = async (secaoPagina) => {
    setProcessando(true)
    console.log(secaoPagina);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao-pagina/${secaoPagina._id}`,
        secaoPagina,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data && response.status === 200) {
        toast.success("Alterações salvas com sucesso!");
        await fetchData();
      } else {
        toast.error(
          "Erro ao tentar alterar dados da Seção da Disciplina: " +
          (response.data?.message || response.statusText)
        );
      }
    } catch (err) {
      toast.error("Erro ao tentar alterar dados da Seção da Disciplina: " + err.message);
      console.error(err);
    } finally {
      fecharFormularioSecao();
      setProcessando(false)
    }
  };

  const alterarOrdemSecaoPagina = async (secao_pagina_id, direcao) => {
    setProcessando(true)
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/secoes-paginas/ordem/${secao_pagina_id}`, { direcao }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        console.log('Ordem da seção alterada com sucesso!');
        await fetchData();
      } else {
        toast.error("Erro ao tentar alterar a ordem da seção");
        console.error('Erro ao alterar a ordem da seção:', response);
      }
    } catch (err) {
      toast.error("Erro ao tentar alterar a ordem da seção");
      console.error('Erro ao alterar a ordem da seção:', err);
    } finally {
      setProcessando(false)
    }
  };

  const excluirSecao = async (secao_id, secao_titulo) => {
    const resposta = confirm(`Você tem certeza que deseja excluir a seção "${secao_titulo}"? As disciplinas dela ficaram sem seção, mas não serão excluídas. Esta ação não poderá ser desfeita!`)
    if (!resposta) return;

    setProcessando(true)
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/master/secao-pagina/${secao_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(`Seção '${secao_titulo}' deletada com sucesso!`);
        await fetchData();
      } else {
        toast.error(
          `Erro ao deletar a seção '${secao_titulo}': ${response.data.message || response.statusText}`
        );
      }
    } catch (err) {
      toast.error(`Erro ao deletar a seção '${secao_titulo}': ${err.message}`);
      console.error(err);
    } finally {
      setProcessando(false)
    }
  };




  const cadastrarPagina = async (pagina) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/pagina`, pagina, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data && response.status === 200) {
        toast.success("Disciplina cadastrada com sucesso!");
        return response.data;
      } else {
        toast.error("Erro ao cadastrar a Disciplina: " + response.message);
      }
    } catch (err) {
      toast.error("Erro ao cadastrar a Disciplina: " + err);
      console.error(err);
    }
    return null;
  };

  const fecharFormularioPagina = () => {
    setShowFormNewPage(false);
    setPagina({ codigo: '', titulo: '', descricao: '', ativo: false });
  };

  const handleCadastrarPagina = async () => {
    setProcessando(true);
    const novaPagina = await cadastrarPagina(pagina);
    if (novaPagina) setPaginas([...paginas, novaPagina]);
    fecharFormularioPagina();
    setProcessando(false);
  };

  const alterarOrdemPagina = async (pagina_id, direcao) => {
    setProcessando(true)
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/paginas/ordem/${pagina_id}`, { direcao }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        console.log('Ordem da disciplina alterada com sucesso!');
        await fetchData();
      } else {
        toast.error("Erro ao tentar alterar a ordem da disciplina");
        console.error('Erro ao alterar a ordem da disciplina:', response);
      }
    } catch (err) {
      toast.error("Erro ao tentar alterar a ordem da disciplina");
      console.error('Erro ao alterar a ordem da disciplina:', err);
    } finally {
      setProcessando(false)
    }
  };

  const trocarSecaoPagina = async (pagina_id, secao_id) => {
    setProcessando(true)
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/master/pagina/trocar-secao/${pagina_id}`, { secao_id }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        console.log('Seção da disciplina alterada com sucesso!');
        await fetchData();
      } else {
        toast.error("Erro ao tentar alterar a seção da disciplina");
        console.error('Erro ao alterar a seção da disciplina:', response);
      }
    } catch (err) {
      toast.error("Erro ao tentar alterar a seção da disciplina");
      console.error('Erro ao alterar a seção da disciplina:', err);
    } finally {
      setProcessando(false)
    }
  };

  const deletarPagina = async (pagina_id, pagina_titulo) => {
    const resposta = confirm(`Você tem certeza que deseja excluir a disciplina "${pagina_titulo}" e TUDO QUE ESTÁ DENTRO dela? Esta ação não poderá ser desfeita!`)
    if (!resposta) return;

    setProcessando(true)
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/master/pagina/${pagina_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(`Disciplina '${pagina_titulo}' deletada com sucesso!`);
        await fetchData();
      } else {
        toast.error(
          `Erro ao deletar a disciplina '${pagina_titulo}': ${response.data.message || response.statusText}`
        );
      }
    } catch (err) {
      toast.error(`Erro ao deletar a disciplina '${pagina_titulo}': ${err.message}`);
      console.error(err);
    } finally {
      setProcessando(false)
    }
  };

  return (
    <div className="gap-5 w-full flex p-8 relative flex-col pb-8 justify-start bg-neutral-800 rounded-xl">
      <div className="w-full mx-auto">
        <div className='flex justify-between'>
          <h1 className="text-white text-4xl">Master</h1>
          {formSecaoMode === 'create' && <button type='button' className='bg-orange-500 text-white text-md px-3 py-2 rounded-lg' onClick={abrirFormularioSecao}>Adicionar seção</button>}
        </div>
        {showFormNewSection && (
          <SectionPageForm
            secaoPagina={secaoPagina}
            setSecaoPagina={setSecaoPagina}
            onSubmit={formSecaoMode === 'create' ? handleCadastrarSecao : () => handleAtualizarSecao()}
            onCancel={fecharFormularioSecao}
            processando={processando}
            formSecaoMode={formSecaoMode}
          />
        )}

        <div>
          <div className='mt-10 flex justify-between items-center align-middle'>
            <h2 className='text-lg text-neutral-300'>Disciplinas sem seção</h2>
            <button type='button' className='text-orange-500 hover:text-white active:text-neutral-100 text-md' onClick={abrirFormularioPagina}>Cadastrar Nova Disciplina</button>
          </div>
          {showFormNewPage && (
            <PageForm
              pagina={pagina}
              setPagina={setPagina}
              onSubmit={handleCadastrarPagina}
              onCancel={fecharFormularioPagina}
              processando={processando}
            />
          )}
          {paginas.length === 0 ? (
            <p className='mt-5 text-neutral-600'>Não há disciplinas sem seção cadastradas no momento</p>
          ) : (
            <div className="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {paginas.map((pagina, pagina_index) => (
                <PageCard
                  key={pagina._id}
                  index={pagina_index}
                  paginasLength={paginas.length}
                  pagina={pagina}
                  secoesPagina={secoesPagina}
                  trocarSecaoPagina={trocarSecaoPagina}
                  processando={processando}
                  deletarPagina={deletarPagina}
                  alterarOrdemPagina={alterarOrdemPagina}
                />
              ))}
            </div>
          )}
        </div>


        {/* Renderizar as seções com as suas respectivas disciplinas */}
        {secoesPagina.length === 0 ? (
          <p className='mt-10 text-neutral-600'>Não há seções cadastradas no momento</p>
        ) : (
          secoesPagina.map((secao, index) => (
            <div key={secao._id} className="mt-10">
              <div className='mt-10 flex justify-between'>
                <div className='flex gap-3 items-center'>
                  <span className='flex flex-col select-none'>
                    {index > 0 &&
                      <button className='hover:scale-125 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed' title="Mover para cima" disabled={processando} onClick={() => alterarOrdemSecaoPagina(secao._id, -1)}>
                        <ChevronUpIcon className='w-4 h-4 text-neutral-200' />
                      </button>
                    }
                    {index < (secoesPagina.length - 1) &&
                      <button className='hover:scale-125 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed' title="Mover para baixo" disabled={processando} onClick={() => alterarOrdemSecaoPagina(secao._id, 1)}>
                        <ChevronDownIcon className='w-4 h-4 text-neutral-200' />
                      </button>
                    }
                  </span>
                  <span>
                    <p className='text-2xl text-neutral-300'>{secao.titulo}</p>
                    <p className='text-sm text-neutral-400'>{secao.descricao}</p>
                  </span>
                </div>
                <div className='flex space-x-5 items-center'>
                  <button className='text-neutral-300 hover:text-white active:text-neutral-300 flex gap-1 items-center disabled:opacity-50' title={`Alternar visualização de "${secao.titulo}" na disciplina de Home`} disabled={processando} onClick={() => mostrarEmHome(secao._id)}>
                    <span className={`${secao.mostrarEmHome ? 'text-green-500' : ''}`}>
                      <HomeIcon className='w-4 h-4' />
                    </span>
                  </button>
                  <button className='text-yellow-500 hover:text-white active:text-neutral-300 disabled:opacity-50' disabled={processando} title={`Editar "${secao.titulo}"`} onClick={() => editarSecao(secao._id)}>
                    <PencilIcon className='w-4 h-4' />
                  </button>
                  <button className='text-red-500 hover:text-white active:text-neutral-300 disabled:opacity-50' disabled={processando} title={`Deletar "${secao.titulo}"`} onClick={() => { excluirSecao(secao._id, secao.titulo); }}>
                    <XMarkIcon className='w-5 h-5' />
                  </button>
                </div>
              </div>
              {
                secao.paginas.length === 0 ? (
                  <p className='mt-5 text-neutral-600'>Nenhuma disciplina cadastrada para esta seção.</p>
                ) : (
                  <div className="">
                    {secao.paginas.length === 0 ?
                      (<p className='mt-5 text-neutral-600'>Não há disciplinas cadastradas no momento nesta seção</p>)
                      :
                      (<div className='grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                        {secao.paginas.map((pagina, pagina_index) => (
                          <PageCard
                            key={pagina._id}
                            index={pagina_index}
                            paginasLength={secao.paginas.length}
                            pagina={pagina}
                            secoesPagina={secoesPagina}
                            trocarSecaoPagina={trocarSecaoPagina}
                            processando={processando}
                            deletarPagina={deletarPagina}
                            alterarOrdemPagina={alterarOrdemPagina}
                          />
                        ))}
                      </div>)
                    }
                  </div>
                )
              }
            </div>
          ))
        )}

      </div>
    </div >
  );
};

export default MasterAdmin;
