import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import PopupShorts from '../../../components/popups/shorts';
const ShortsMain = (props) => {
    const [popupShortsVisible, setPopupShortsVisible] = useState([]);
    const [shorts, setShorts] = useState([]);
    const [shouldLoadMore, setShouldLoadMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const dropdownRef = useRef(null);

    const fetchData = async (params = {}) => {
        if (loading) return;
        setLoading(true);

        const defaultParams = {
            skip: 0,
            limit: 7,
            shorts: true,
            busca: null,
            tags: null
        };

        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND_BASE_URL + '/aulas', {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: { ...defaultParams, ...params }
            });
            setShorts(response.data.aulas);
            setCurrentPage(0);
            setShouldLoadMore(false);

        } catch (error) {
            setShouldLoadMore(false);
        }

        setLoading(false);
    };

    const handleClickShort = async short => {
        const currentIndex = shorts.findIndex(s => s._id === short._id);
        const prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
        const nextIndex = currentIndex < 5 ? currentIndex + 1 : null;

        const popupShorts = [
            prevIndex !== null ? shorts[prevIndex] : null,
            short,
            nextIndex !== null ? shorts[nextIndex] : null,
        ];

        setPopupShortsVisible(popupShorts);
    };

    const handleOutsideClick = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        fetchData();

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div>
            <section className='w-full space-y-5 my-5'>
                <div className='w-11/12 mx-auto space-y-3'>
                    <div className='flex justify-between'>
                        <div className='flex flex-row items-center align-middle space-x-2'>
                            <svg viewBox="0 0 1024 1024" className="h-5 fill-current text-white">
                                <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                            </svg>
                            <h2 className='font-bold text-lg text-white'>Shorts</h2>
                        </div>
                        <a href='/shorts' className='text-neutral-500 hover:text-orange-500 active:text-orange-700'>
                            Ver mais {'>'}
                        </a>
                    </div>
                    <p className='text-xs sm:text-sm md:text-lg text-neutral-500'>Aulas direto ao ponto em vídeos de até 90 segundos para te ajudar a destravar assuntos específicos!</p>
                </div>

                <div className='w-11/12 mx-auto  overflow-x-auto py-5 md:px-0 md:py-5'>
                    <div className="flex flex-row w-fit md:w-11/12 md:mx-auto gap-2">
                        {shorts && shorts.length > 0 && shorts.map((short) => (
                            <div className="w-[35vw] sm:w-[25vw] md:w-1/5 border-2 border-transparent hover:border-orange-500 hover:scale-105 transition-all rounded-md cursor-pointer overflow-hidden" key={short._id} onClick={() => props.usuarioAtual.tipo !== 'guest' ? handleClickShort(short) : null}>
                                <img className='object-cover object-center' loading="lazy" src={`${short.capa}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {popupShortsVisible && popupShortsVisible.length > 0 && (
                <PopupShorts
                    video={popupShortsVisible}
                    handleClickShort={(e) => {
                        handleClickShort(e)
                    }}
                    fecharPopup={() => setPopupShortsVisible([])}
                />
            )}
        </div>
    )
}

export default ShortsMain