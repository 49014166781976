import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const NavigationButtons = ({ paginaCodigo, cursos, curso, aulas, aula }) => {
    const navigate = useNavigate();

    const goToLesson = (course, lesson) => {
        if (course && lesson) {
            navigate(`/master/${paginaCodigo}/${course.codigo}/${lesson.codigo}`, { replace: true });
        } else {
            console.error("Erro! Aula ou curso não encontrado.");
        }
    };

    const goToPreviousLesson = () => {
        const currentLessonIndex = aulas.findIndex(el => el._id === aula._id);
        if (currentLessonIndex > 0) {
            goToLesson(curso, aulas[currentLessonIndex - 1]);
        } else {
            const currentCourseIndex = cursos.findIndex(el => el._id === curso._id);
            const previousCourse = cursos[currentCourseIndex - 1] || cursos[cursos.length - 1];
            const previousLesson = previousCourse.aulas?.[previousCourse.aulas.length - 1];
            goToLesson(previousCourse, previousLesson);
        }
    };

    const goToNextLesson = () => {
        const currentLessonIndex = aulas.findIndex(el => el._id === aula._id);
        if (currentLessonIndex < aulas.length - 1) {
            goToLesson(curso, aulas[currentLessonIndex + 1]);
        } else {
            const currentCourseIndex = cursos.findIndex(el => el._id === curso._id);
            const nextCourse = cursos[currentCourseIndex + 1] || cursos[0];
            const nextLesson = nextCourse.aulas?.[0];
            goToLesson(nextCourse, nextLesson);
        }
    };

    const previousClassTitle = () => {
        const currentLessonIndex = aulas.findIndex(el => el._id === aula._id);
        if (currentLessonIndex > 0) {
            return aulas[currentLessonIndex - 1]?.titulo || "Aula anterior";
        } else {
            const currentCourseIndex = cursos.findIndex(el => el._id === curso._id);
            const previousCourse = cursos[currentCourseIndex - 1] || cursos[cursos.length - 1];
            return previousCourse.aulas?.[previousCourse.aulas.length - 1]?.titulo || "Aula anterior";
        }
    };

    const nextClassTitle = () => {
        const currentLessonIndex = aulas.findIndex(el => el._id === aula._id);
        if (currentLessonIndex < aulas.length - 1) {
            return aulas[currentLessonIndex + 1]?.titulo || "Próxima aula";
        } else {
            const currentCourseIndex = cursos.findIndex(el => el._id === curso._id);
            const nextCourse = cursos[currentCourseIndex + 1] || cursos[0];
            return nextCourse.aulas?.[0]?.titulo || "Próxima aula";
        }
    };

    return (
        <>
            {curso?._id !== cursos[0]?._id || aula?._id !== aulas[0]?._id ? (
                <button
                    className="group flex absolute px-2 py-3 text-neutral-300 bg-neutral-950 hover:bg-neutral-900 border border-neutral-100 top-1/2 left-0 items-center gap-3"
                    onClick={goToPreviousLesson}
                >
                    <ChevronLeftIcon className="w-5 h-5 transition-all duration-300 ease-in-out" />
                    <p className="hidden group-hover:block capitalize font-poppins transition-all duration-300 ease-in-out text-xs">
                        {previousClassTitle()}
                    </p>
                </button>
            ) : null}
            {curso?._id !== cursos[cursos.length - 1]?._id || aula?._id !== aulas[aulas.length - 1]?._id ? (
                <button
                    className="group flex absolute px-2 py-3 text-neutral-300 bg-neutral-950 hover:bg-neutral-900 border border-neutral-100 top-1/2 right-0 items-center gap-3"
                    onClick={goToNextLesson}
                >
                    <ChevronRightIcon className="w-5 h-5 transition-all duration-300 ease-in-out" />
                    <p className="hidden group-hover:block capitalize font-poppins transition-all duration-300 ease-in-out text-xs">
                        {nextClassTitle()}
                    </p>
                </button>
            ) : null}
        </>
    );
};

export default NavigationButtons;
