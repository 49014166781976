import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from '../components/header';
import MenuAdmin from '../components/menu-admin';
import Footer from '../components/footer';

import CursoAdmin from '../components/admin/curso';
import AulaAdmin from '../components/admin/aula';
import MasterAdmin from '../components/admin/master/main';
import MasterPaginaAdmin from '../components/admin/master/master-pagina';
import MasterSecaoAdmin from '../components/admin/master/master-secao';
import MasterCursoAdmin from '../components/admin/master/master-curso';
import MasterAulaAdmin from '../components/admin/master/master-aula';
import TrilhaAdmin from '../components/admin/trilha/main';
import Planos from '../components/admin/planos';
import UsuarioAdmin from '../components/admin/usuario';
import Agendamentos from '../components/admin/agendamentos';
import NotificacoesAdmin from '../components/admin/notificacoes';
import LiveAdmin from '../components/admin/live';
import ConfigAdmin from '../components/admin/configuracoes';
import ComunidadeAdmin from '../components/admin/comunidade';
import './admin.css';

const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const usuarioAtual = useSelector((state) => state.usuarioAtual);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    // Oculta o menu apenas na rota "trilha"
    setShowMenu(!location.pathname.includes('trilha'));
  }, [location.pathname]);

  return (
    <div className="w-full">
      <Header usuarioAtual={usuarioAtual} showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="flex flex-col min-h-screen bg-black">
        <Helmet>
          <title>Admin - Simbium</title>
          <meta property="og:title" content="Admin - Simbium" />
        </Helmet>
        <div className="bg-neutral-900 flex flex-col font-poppins">
          <div className="flex flex-row gap-5 md:p-5">
            {/* Menu lateral */}
            {showMenu && (
              <MenuAdmin
                usuarioAtual={usuarioAtual}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
            )}
            {/* Conteúdo principal */}
            <div className={`${showMenu ? 'lg:w-9/12' : 'w-[98vw]'} mx-auto text-neutral-50`}>
              <Routes>

                {/* Rotas administrativas */}
                <Route path="planos" element={<Planos />} />
                <Route path="cursos" element={<CursoAdmin />} />
                <Route path="comunidade" element={<ComunidadeAdmin />} />
                <Route path="aulas" element={<AulaAdmin />} />
                <Route path="master" element={<MasterAdmin />} />
                <Route path="master-pagina/:pagina_id" element={<MasterPaginaAdmin />} />
                <Route path="master-secao/:secao_id" element={<MasterSecaoAdmin />} />
                <Route path="master-curso/:curso_id" element={<MasterCursoAdmin />} />
                <Route path="master-aula/:aula_id" element={<MasterAulaAdmin />} />
                <Route path="trilha" element={<TrilhaAdmin />} />
                <Route path="usuarios" element={<UsuarioAdmin />} />
                <Route path="agendamentos" element={<Agendamentos />} />
                <Route path="notificacoes" element={<NotificacoesAdmin />} />
                <Route path="live" element={<LiveAdmin />} />
                <Route path="configuracoes" element={<ConfigAdmin />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Admin;
