import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GridLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from './store';

import './style.css';

import Onboarding from './components/onboarding';
import MeuPerfil from './views/meu-perfil';
import ConfirmacaoCadastro from './views/confirmacao-cadastro/main';
import TermosDeUso from './views/termos-de-uso';
import MeusColaboradores from './views/meus-colaboradores';
import Admin from './views/admin';
import Consultor from './views/consultor';
import PolticasDePrivacidade from './views/polticas-de-privacidade';
import Login from './views/login';
import HomeOfficial from './views/home-official/main';
import TrialExpirado from './views/trial-expirado';
import Shorts from './views/shorts';
import MasterAula from './views/master/aula/main';
import LandingPageAutomacao from './views/landing-page-automacao';
import Trilha from './views/trilha/main';
import Consultorias from './views/consultorias/main';
import FranquiaSimbium from './views/franquia-simbium/main';
import ImportacaoCompartilhada from './views/importacao-compartilhada/main';
import FornecedoresEParceiros from './views/fornecedores-e-parceiros/main';
import ProdutosCatalogados from './views/produtos-catalogados/main';
import Marketplace from './views/marketplace/main';
import SejaConsultor from './views/seja-consultor/main';
import EmprestimoSimbium from './views/emprestimo-simbium/main';

import { setUsuario } from './features/usuario/usuarioAtual';
import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });

const App = () => (
  <Provider store={store}>
    <Content />
  </Provider>
);

const Content = () => {
  const dispatch = useDispatch();
  const usuarioAtual = useSelector(state => state.usuarioAtual);
  const [isLoading, setIsLoading] = useState(true);
  const [onboardingOpened, setOnboardingOpened] = useState(false);

  useEffect(() => {
    const themeColor = '#171717';
    let metaThemeColor = document.querySelector("meta[name='theme-color']");
    if (!metaThemeColor) {
      metaThemeColor = document.createElement("meta");
      metaThemeColor.name = "theme-color";
      document.head.appendChild(metaThemeColor);
    }
    metaThemeColor.content = themeColor;
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoading(false);
        console.error("Token não encontrado.");
        return;
      }
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/usuarios/usuario_atual`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        if (data) {
          dispatch(setUsuario(data));
        } else {
          console.error("Erro ao carregar dados do usuário:", data);
        }
      } catch (err) {
        console.error("Erro ao carregar dados do usuário:", err.response?.data || err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [dispatch]);

  useEffect(() => {
    if (usuarioAtual?._id && usuarioAtual.tipo !== 'guest') {
      mixpanel.identify(usuarioAtual._id);
      mixpanel.people.set({
        $name: usuarioAtual.nome,
        $email: usuarioAtual.email,
        $plan: usuarioAtual.ref.plano
      });
    }
  }, [usuarioAtual]);

  const PrivateRoute = ({ element, ...rest }) =>
    ['usuario', 'admin', 'consultor'].includes(usuarioAtual?.tipo) ? element : <Navigate to="/login" />;

  const AdminRoute = ({ element }) =>
    usuarioAtual?.tipo === 'admin' ? element : <Navigate to="/" />;

  const ConsultorRoute = ({ element, ...rest }) =>
    ['admin', 'consultor'].includes(usuarioAtual?.tipo) ? element : <Navigate to="/" />;

  return (
    <BrowserRouter>
      <div>
        {usuarioAtual?.onboarding && !usuarioAtual.onboarding.concluido && (
          <Onboarding opened={onboardingOpened} />
        )}
        <ToastContainer className="toast" theme="dark" />
        {isLoading ? (
          <div className="loading-overlay">
            <GridLoader color="#ff5b1c" size={18} />
          </div>
        ) : (
          <Routes>
            <Route path="/meu-perfil" element={<PrivateRoute element={<MeuPerfil />} />} />
            <Route path="/termos-de-uso" element={<TermosDeUso />} />
            <Route path="/meus-colaboradores" element={<PrivateRoute element={<MeusColaboradores />} />} />
            <Route path="/admin/*" element={<AdminRoute element={<Admin />} />} />
            <Route path="/master/:paginaCodigo/:cursoCodigo?/:aulaCodigo?" element={<PrivateRoute element={<MasterAula key={window.location.pathname} />} />} />
            <Route path="/consultor" element={<ConsultorRoute element={<Consultor />} />} />
            <Route path="/politicas-de-privacidade" element={<PolticasDePrivacidade />} />
            <Route path="/shorts/:short_id?" element={<PrivateRoute element={<Shorts />} />} />
            <Route path="/trilha/:aula_trilha_id?" element={<PrivateRoute element={<Trilha />} />} />
            <Route path="/consultorias/:consultor_id?" element={<PrivateRoute element={<Consultorias />} />} />
            <Route path="/franquia-simbium" element={<PrivateRoute element={<FranquiaSimbium />} />} />
            <Route path="/importacao-compartilhada" element={<PrivateRoute element={<ImportacaoCompartilhada />} />} />
            <Route path="/fornecedores-e-parceiros" element={<PrivateRoute element={<FornecedoresEParceiros />} />} />
            <Route path="/produtos-catalogados" element={<PrivateRoute element={<ProdutosCatalogados />} />} />
            <Route path="/marketplace" element={<PrivateRoute element={<Marketplace />} />} />
            <Route path="/seja-consultor" element={<PrivateRoute element={<SejaConsultor />} />} />
            <Route path="/emprestimo-simbium" element={<PrivateRoute element={<EmprestimoSimbium />} />} />
            <Route path="/marketplace/servico/:paginaCodigo/:cursoCodigo" element={<PrivateRoute element={<LandingPageAutomacao />} />} />
            <Route path="/" element={<HomeOfficial />} />
            <Route path="/login" element={<Login />} />
            <Route path="/confirmacao-cadastro/:usuarioId?" element={<PrivateRoute element={<ConfirmacaoCadastro />} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById('app');
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
export default App;
