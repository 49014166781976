import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../../features/usuario/usuarioAtual';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Chart } from 'chart.js/auto';

import Header from '../../components/header';
import Menu from '../../components/menu';
import Footer from '../../components/footer'
import PopupAgendamento from '../../components/popups/add_consultoria';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './diagnostico_consultor.css';


const usuario_diagnostico = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chartRadarRef = useRef(null);
  const chartBarRef = useRef(null);
  const chartLineRef = useRef(null);
  const [showMenu, setShowMenu] = useState(window.innerWidth >= 1024);

  const location = useLocation();
  const { usuario_nome, usuario_diagnostico } = location.state || { usuario_nome: '', usuario_diagnostico: [] };

  const [popupAgendamentoVisible, setPopupAgendamentoVisible] = useState(false);
  const usuarioAtual = useSelector((state) => state.usuarioAtual);


  const gerarDados = (qtd_diagnosticos = null) => {

    let dados
    if (qtd_diagnosticos == null) {
      dados = usuario_diagnostico.reverse();
      qtd_diagnosticos = dados.length
    } else {
      dados = usuario_diagnostico.slice((-1) * qtd_diagnosticos).reverse();
    }

    console.log(dados)

    const qtd_dados = dados.length
    const colors = ['#e86817', '#c8c8c8', '#646464'];
    const backColors = ['rgba(232, 104, 23, 0.5)', 'rgba(200, 200, 200, 0.5)', 'rgba(100, 100, 100, 0.5)'];

    return dados.map((usuario_diagnostico, indexDiagnostico) => {
      const pontos = Object.entries(usuario_diagnostico).reduce((acc, [secao_nome, secao]) => {
        if (secao_nome !== 'dados_basicos') {
          let multiplicador = 10 / Object.keys(secao).length;
          let pontosSecao = 0;
          Object.entries(secao).forEach(([nome, questao]) => {
            let ponto = 0;
            switch (questao.tipo) {
              case 0:
                ponto = questao.valor[1].length ? 100 : 0;
                break;
              case 1:
              case 2:
                ponto = questao.qtd_opcoes === 1 ? 100 : questao.valor[0] * (100 / (questao.qtd_opcoes - 1));
                break;
              case 3:
                ponto = questao.valor[0] * 25;
                break;
              case 4:
                multiplicador = 10 / (Object.keys(secao).length - 1)
                break
              default:
                ponto = 0;
            }
            pontosSecao += ponto;
          });

          acc.push(Math.round(pontosSecao * multiplicador));
        }

        return acc;
      }, []);
      return {
        label: `Diagnóstico ${dados.length - indexDiagnostico}`,
        data: [...pontos],
        fill: true,
        borderColor: colors[indexDiagnostico],
        backgroundColor: backColors[indexDiagnostico],
      };
    });
  };

  useEffect(() => {
    if (usuario_diagnostico.length === 0) {
      return
    }

    const ctx_radar = chartRadarRef.current.getContext('2d');
    const ctx_bar = chartBarRef.current.getContext('2d');
    const ctx_line = chartLineRef.current.getContext('2d');

    const dados_radar = gerarDados(3);

    const dados_bar_line = [
      {
        "label": "Diagnósticos em barra",
        "data": dados_radar.map(item => { return item.data.reduce((acc, valor) => acc + valor, 0) }),
        "fill": true,
        "borderColor": ['#e86817', '#c8c8c8', '#646464'],
        "backgroundColor": ['rgba(232, 104, 23, 0.5)', 'rgba(200, 200, 200, 0.5)', 'rgba(100, 100, 100, 0.5)'],
      },
    ];

    // console.log(dados_bar_line)

    const myRadarChart = new Chart(ctx_radar, {
      type: 'radar',
      data: {
        labels: ['Faturamento', 'Sobre o último mês...', 'Gestão', 'Marketing', 'Vendas', 'Documentação'],
        datasets: dados_radar,
      },
      options: {
        scales: {
          r: {
            angleLines: {
              color: 'rgb(120,120,120)',
            },
            grid: {
              color: 'rgb(120,120,120)',
            },
            suggestedMin: 0,
            suggestedMax: 1100,
          },
        },
      },
    });

    const myBarChart = new Chart(ctx_bar, {
      type: 'bar',
      data: {
        labels: dados_radar.map(item => item.label),
        datasets: dados_bar_line,
      },
      options: {
        scales: {
          y: {
            grid: {
              color: 'rgb(120,120,120)',
            },
            suggestedMin: 0,
            suggestedMax: 1100,
          },
          x: {
            grid: {
              color: 'rgb(120,120,120)',
            },
          },
        },
      },
    });

    const myLineChart = new Chart(ctx_line, {
      type: 'line',
      data: {
        labels: ['Fatur...', 'Ult. Mês', 'Gestão', 'Mark...', 'Vend...', 'Doc...'],
        datasets: dados_radar,
      },
      options: {
        scales: {
          y: {
            grid: {
              color: 'rgb(120,120,120)',
            },
            suggestedMin: 0,
            suggestedMax: 1100,
          },
          x: {
            grid: {
              color: 'rgb(120,120,120)',
            },
          },
        },
      },
    });

    return () => {
      //myRadarChart.destroy();
      //myBarChart.destroy();
      //myLineChart.destroy();
    };
  }, []);


  return (
    <div className='diagnostico-panel'>
      {usuario_diagnostico.length > 0 ?
        <div className='diagnostico-content'>
          <h1 className='page-title'>Diagnósticos de <span className='usuario-nome'>{usuario_nome}</span></h1>
          <div className='diagnostico-info-container'>
            <div className='diagnostico-info'>
              <div className='info-titulo'>Parâmetro 1</div>
              <div className='info-valor'>R$ 0,00</div>
            </div>
            <div className='diagnostico-info'>
              <div className='info-titulo'>Parâmetro 2</div>
              <div className='info-valor'>0,0%</div>
            </div>
            <div className='diagnostico-info'>
              <div className='info-titulo'>Parâmetro 3</div>
              <div className='info-valor'>0 und</div>
            </div>
            <div className='diagnostico-info'>
              <div className='info-titulo'>Parâmetro 4</div>
              <div className='info-valor'>0.0</div>
            </div>
          </div>
          <div className='diagnostico-grafico-container'>
            <div className='diagnostico-grafico'>
              <canvas ref={chartRadarRef} />
            </div>
            <div className='diagnostico-grafico'>
              <canvas ref={chartBarRef} />
            </div>
            <div className='diagnostico-grafico'>
              <canvas ref={chartLineRef} />
            </div>
          </div>
          <table className='tabela-diagnosticos'>
            <thead>
              <tr>
                <th>Diagnóstico de {usuario_nome}</th>
              </tr>
            </thead>
            <tbody>
              {usuario_diagnostico.map((diag, index) => (
                <tr key={index}>
                  <td>Diagnóstico #{index + 1}</td>
                </tr>
              ))}
            </tbody>
            <a href='./agendamentos-consultorias'>
              <p className='info-sem-diagnostico-voltar'>
                Voltar
              </p>
            </a>
          </table>
        </div>
        :
        <div className='diagnostico-content'>
          <p className='info-sem-diagnostico'>
            <span className='usuario-nome'>{usuario_nome}</span> não preencheu o Formulário Diagnóstico até o momento.
          </p>
          <a href='./agendamentos-consultorias'>
            <p className='info-sem-diagnostico-voltar'>
              Voltar
            </p>
          </a>
        </div>
      }
    </div>
  )
}

export default usuario_diagnostico
